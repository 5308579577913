import React, { useState, useRef } from 'react'
import { SearchRounded, NotificationImportantRounded } from '@material-ui/icons'
import { Badge } from '@material-ui/core';
import HeaderUser from '../../../../../Shared/HeaderUser';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPanelSelected, updateClient, getClients } from '../../../../../../redux/actions/clientsActions'
import { createPhase } from '../../../../../../redux/actions/phasesActions'
import { Breadcrumbs } from '@material-ui/core'
import { NavigateNextRounded, ListRounded, FilterListRounded, AddRounded, AssignmentIndRounded, FitnessCenterRounded, AssignmentRounded } from '@material-ui/icons'
import { panelsClients } from '../../../../../../utils/constans'

const Header = ({ breadcrums, panelSelected, clientSelected, actions }) => {

  const changePanel = (panel) => {
    actions.setPanelSelected(panel)
  }

  return(
    <div className="header-crm">

      <div className="box-crm pr header-clients">

        <div className="camino-de-migas">
          <Breadcrumbs separator={<NavigateNextRounded fontSize="big" arial-label="breadcrumb" className="breadcrumbs-customized" />}>
            {breadcrums && breadcrums.length > 0 && (
              breadcrums.map( (panel, key) => {
                return(
                  <div key={key}>
                    {panel.text}
                  </div>
                )
              })
            )}
          </Breadcrumbs>
        </div>
        
        <div className="filter-n-icons-header">
          <div className="list-filters">
              {/* <div>Activos</div> */}
          </div>
        </div>
        
        <div className="panels-selector">
          <div className={`tab-header-item ${panelSelected===panelsClients.LIST?'active-panel':''}`} >
            <div onClick={()=>changePanel(panelsClients.LIST)}>Lista</div>
            <div>{panelSelected===panelsClients.LIST && <ListRounded/>}</div>
          </div>
          <div className={`tab-header-item ${panelSelected===panelsClients.INFORMATION?'active-panel':''}`} >
            <div onClick={()=>changePanel(panelsClients.INFORMATION)}>Información</div>
            <div>{panelSelected===panelsClients.INFORMATION && <AssignmentIndRounded/>}</div>
          </div>
          <div className={`tab-header-item ${panelSelected===panelsClients.INITIAL_FORM?'active-panel':''}`} >
            <div onClick={()=>changePanel(panelsClients.INITIAL_FORM)}>Formulario inicial</div>
            <div>{panelSelected===panelsClients.INITIAL_FORM && <AssignmentRounded/>}</div>
          </div>
          <div className={`tab-header-item ${panelSelected===panelsClients.FASES?'active-panel':''}`} >
            <div onClick={()=>changePanel(panelsClients.FASES)}>Fases</div>
            <div>{panelSelected===panelsClients.FASES && <FitnessCenterRounded/>}</div>
          </div>
        </div>
      </div>


    </div>
  )
}

const mapStateToProps = ({clients}) => ({
  breadcrums: clients.breadcrums,
  panelSelected: clients.panelSelected,
  clientSelected: clients.clientSelected
});
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setPanelSelected,
      updateClient,
      getClients,
      createPhase
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(Header);