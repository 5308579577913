import React from 'react'
import { useSpring } from 'react-spring'
import { Spring } from 'react-spring/renderprops'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setBreadcrums, setPanelSelected } from '../../../../../redux/actions/dashboardActions'
import { panelsDashboard } from '../../../../../utils/constans'
const General = ({ indicatorsDashboard, breadcrums, actions }) => {

  
  const openTab = (keyParent, keyIndicator) => {
    console.log('👉', keyIndicator)
    actions.setBreadcrums({
      newPanel: indicatorsDashboard[keyParent].indicators[keyIndicator],
      keyPanel: 1
    })
    actions.setPanelSelected(panelsDashboard.CLIENTS)
  }
  
  return (
    <div className="content-clients-chat box-crm">
      <div className="content-dashboard">

        { Object.entries(indicatorsDashboard).map( ([keyParent, parent]) => {
          return(
            <div className="container-indicators" key={keyParent}>
              <div className="title-indicator">{parent.text}</div>
              <div className="indicators">
                {Object.entries(parent.indicators).map(([keyIndicator, indicator]) => {

                  return(
                    <div
                      className={`item-indicator ${breadcrums.length > 1 && breadcrums[1].keyIndicator === keyIndicator ? 'selected-item' : ''}`}
                      key={keyIndicator}
                      onClick={() => openTab(keyParent, keyIndicator)}
                    >
                      <Spring from={{ number: 0 }} to={{ number: indicator?.data?.length }} config={{ delay: 600, duration: 600 }}>
                        { ({ number }) => <div className="number-indicator" >{ (+number).toFixed(0) }</div> }
                      </Spring>
                      <div className="title-indicator">{indicator.text}</div>
                    </div>
                  )
                })
                }
              </div>
            </div>
          )
        })
        }
      </div>
    </div>
  )
}

const mapStateToProps = ({ dashboard }) => ({
  indicatorsDashboard: dashboard.indicatorsDashboard,
  breadcrums: dashboard.breadcrums
});

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setBreadcrums,
      setPanelSelected
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(General);