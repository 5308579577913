const ethosClient = require('doce-client').createClient()

export const templatesTypes = {
	SET_TEMPLATES: 'SET_TEMPLATES',
	MODIFY_TEMPLATE: 'MODIFY_TEMPLATE',
}
export function setTemplates(payload) {
	return { type: templatesTypes.SET_TEMPLATES, payload }
}

export const getTemplates = () => async (dispatch) => {
	try {
		const { body } = await ethosClient.getTemplates()
		return dispatch(setTemplates(body.templates))
	} catch (error) {}
}

export const updateTemplate = ({ id, data }) => async (dispatch) => {
	try {
		const { body } = await ethosClient.updateTemplate({ id, data })
		return body
	} catch (error) {}
}
export function modifyTemplate(payload) {
	return { type: templatesTypes.MODIFY_TEMPLATE, payload }
}

export const createTemplate = (data) => async (dispatch) => {
	try {
		const { body } = await ethosClient.createTemplate(data)
		return body
	} catch (error) {}
}

export const deleteTemplate = ({ id }) => async (dispatch) => {
	try {
		const { body } = await ethosClient.deleteTemplate({ id })
		return body
	} catch (error) {}
}
