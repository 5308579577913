import React from 'react'
import { Line } from 'react-chartjs-2'
import 'chartjs-plugin-lineheight-annotation'
import styled from 'styled-components';

const ChartData = ({ data }) => {

  return (
    <ContainerChartData>
      <Line options={{
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 40,
            bottom: 0
          }
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 6,
            fontSize: 16,
            fontFamily: 'sans-serif',
            usePointStyle: true
          }

        },
        maintainAspectRatio: false,
        responsive: true,
        lineHeightAnnotation: {
          shadow: true,
          color: "rgb(255,255,255,0.5)"
        },
        scales: {
          yAxes: [{
            gridLines: {
              display: false,
              color: "rgb(255,255,255,0.5)"
            },
            ticks: {
              display: true,
              beginAtZero: true,
              min: 40,
              maxTicksLimit: 10
            }
          }],
          xAxes: [{
            gridLines: {
              display: false,
            }
          }]
        },
        showAllTooltips: true,
      }} data={data}
      />
    </ContainerChartData>
  )
}

export default (ChartData);

const ContainerChartData = styled.div`
  position: relative;
  height: 250px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
`