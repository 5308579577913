import React, { useLayoutEffect } from 'react'
import HeaderGeneral from './General/Header'
import General from './General/General'
import { getIndicators } from '../../../../redux/actions/clientsActions'
import { setIndicators } from '../../../../redux/actions/dashboardActions'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Clients from './Clients/Clients'
import { panelsDashboard } from '../../../../utils/constans'
const Dashboard = ({breadcrums, panelSelected, actions}) => {

  useLayoutEffect(() => {
    actions.getIndicators()
    .then((payload)=>{
      actions.setIndicators(payload)
    })
    .catch(err=>{

    })
  }, [])

  return(
    <>
      <HeaderGeneral />
      {panelSelected === panelsDashboard.INDICATORS && <General />}
      {panelSelected === panelsDashboard.CLIENTS && <Clients />}
    </>
  )
}

const mapStateToProps = ({ dashboard }) => ({
  breadcrums: dashboard.breadcrums,
  panelSelected: dashboard.panelSelected
});
const matchDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    getIndicators,
    setIndicators
  }, dispatch)	
})

export default connect(mapStateToProps, matchDispatchToProps)(Dashboard);
