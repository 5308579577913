import React from 'react'
import { HomeRounded, PeopleRounded } from '@material-ui/icons'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPanelSelected } from '../../../redux/actions/crmActions'
import { panelsCrm } from '../../../utils/constans'

const SideBar = ({panelSelected, actions}) => (
  <div className="menu-crm">
    <div className="container-menu">
      <div>
        <div className={`icon-container ${panelSelected===panelsCrm.DASHBOARD?'active-panel':''}`} onClick={()=>actions.setPanelSelected(panelsCrm.DASHBOARD)}>
          <div className="icon">
            <HomeRounded />
          </div>
        </div>
        <div className={`icon-container ${panelSelected===panelsCrm.CLIENTS?'active-panel':''}`} onClick={()=>actions.setPanelSelected(panelsCrm.CLIENTS)}>
          <div className="icon">
            <PeopleRounded />
          </div>
        </div>
      </div>
    </div>
  </div>
)

const mapStateToProps = ({crm}) => ({
  panelSelected: crm.panelSelected
});
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setPanelSelected
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(SideBar);