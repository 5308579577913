import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setPanelSelected,
  createClient,
  getClients,
} from "../../../../../../redux/actions/clientsActions";
import { setNotification } from "../../../../../../redux/actions/globalActions";
import { Breadcrumbs } from "@material-ui/core";
import {
  NavigateNextRounded,
  ListRounded,
  AddRounded,
  AssignmentIndRounded,
  FitnessCenterRounded,
  AssignmentRounded,
} from "@material-ui/icons";
import { panelsClients } from "../../../../../../utils/constans";
import Popup from "../../../../../Shared/Popup";
import Input from "../../../../../Shared/Input";

const Header = ({
  clients,
  breadcrums,
  panelSelected,
  clientSelected,
  actions,
}) => {
  const refNewClient = useRef(null);
  const [openNewClient, setOpenNewClient] = useState(false);
  const [emailNewClient, setEmailNewClient] = useState("");

  const changePanel = (panel) => {
    if (
      !clientSelected &&
      [
        panelsClients.INFORMATION,
        panelsClients.INITIAL_FORM,
        panelsClients.FASES,
      ].includes(panel)
    ) {
      return actions.setNotification({
        visibility: true,
        text: "Selecciona un cliente",
        status: "close",
        moment: Date.now(),
      });
    }
    actions.setPanelSelected(panel);
  };

  const createClient = () => {
    const payload = {
      data: {
        body: {
          personalData: {
            email: emailNewClient,
          },
        },
      },
    };
    actions
      .createClient(payload)
      .then(() => {
        actions.setNotification({
          visibility: true,
          text: "Se ha creado correctamente",
          status: "done",
          moment: Date.now(),
        });
        setOpenNewClient(false);
        actions.getClients();
      })
      .catch((err) => {
        actions.setNotification({
          visibility: true,
          text: "Ha ocurrido un error",
          status: "close",
          moment: Date.now(),
        });
      });
  };

  return (
    <div className="header-crm">
      <div className="box-crm pr header-clients">
        <div className="camino-de-migas">
          <Breadcrumbs
            separator={
              <NavigateNextRounded
                fontSize="big"
                arial-label="breadcrumb"
                className="breadcrumbs-customized"
              />
            }
          >
            {breadcrums &&
              breadcrums.length > 0 &&
              breadcrums.map((panel, key) => {
                return <div key={key}>{panel.text}</div>;
              })}
          </Breadcrumbs>
        </div>

        <div>{clients ? Object.keys(clients).length : 0} clientes</div>

        <div className="filter-n-icons-header">
          <div className="list-filters">
            <div>Activos</div>
          </div>
          <div className="icons-header-container">
            <div
              className="btn-options pr"
              onClick={() => setOpenNewClient(true)}
              ref={refNewClient}
            >
              <AddRounded />
              <span>Nuevo</span>
            </div>
            <Popup
              open={openNewClient}
              anchor={refNewClient.current}
              onClose={() => setOpenNewClient(false)}
              title={"Nuevo cliente"}
            >
              <div className="container-simple-input width-380 padding-horizontal-pop-up">
                <Input
                  title="Email"
                  value={emailNewClient}
                  onChange={(value) => setEmailNewClient(value)}
                />
              </div>
              <div className="container-btns-popup">
                <div className="btn-pop-up primary-btn" onClick={createClient}>
                  Guardar
                </div>
              </div>
            </Popup>
          </div>
        </div>

        <div className="panels-selector">
          <div
            className={`tab-header-item ${
              panelSelected === panelsClients.LIST ? "active-panel" : ""
            }`}
          >
            <div onClick={() => changePanel(panelsClients.LIST)}>Lista</div>
            <div>{panelSelected === panelsClients.LIST && <ListRounded />}</div>
          </div>
          <div
            className={`tab-header-item ${
              panelSelected === panelsClients.INFORMATION ? "active-panel" : ""
            } ${!clientSelected ? "disable-tabs" : ""}`}
          >
            <div onClick={() => changePanel(panelsClients.INFORMATION)}>
              Información
            </div>
            <div>
              {panelSelected === panelsClients.INFORMATION && (
                <AssignmentIndRounded />
              )}
            </div>
          </div>
          <div
            className={`tab-header-item ${
              panelSelected === panelsClients.INITIAL_FORM ? "active-panel" : ""
            } ${!clientSelected ? "disable-tabs" : ""}`}
          >
            <div onClick={() => changePanel(panelsClients.INITIAL_FORM)}>
              Formulario inicial
            </div>
            <div>
              {panelSelected === panelsClients.INITIAL_FORM && (
                <AssignmentRounded />
              )}
            </div>
          </div>
          <div
            className={`tab-header-item ${
              panelSelected === panelsClients.FASES ? "active-panel" : ""
            } ${!clientSelected ? "disable-tabs" : ""}`}
          >
            <div onClick={() => changePanel(panelsClients.FASES)}>Fases</div>
            <div>
              {panelSelected === panelsClients.FASES && (
                <FitnessCenterRounded />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ clients }) => ({
  clients: clients.clients,
  clientSelected: clients.clientSelected,
  breadcrums: clients.clientSelected
    ? [
        ...clients.breadcrums,
        { text: clients.clientSelected.personalData.nombreCompleto },
      ]
    : clients.breadcrums,
  panelSelected: clients.panelSelected,
});
const matchDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        setPanelSelected,
        createClient,
        getClients,
        setNotification,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(Header);
