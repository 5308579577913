import React from 'react'
import { NotificationImportantRounded } from '@material-ui/icons'
import renderHTML from 'react-render-html';
const Alert = ({icon: Icon = NotificationImportantRounded, text = "", cancel, confirm, accept, children, actionSelected}) => {

  const handleAction = (e, action) => {
    e.stopPropagation()
    actionSelected(action)
  }


  return(
    <div className='panel-confirm-alert'>
      <div className={`confirm-alert`} >

        <div className='confirm-top-bar'>
          <div className='confirm-top-bar-container-image'>
            <Icon />
          </div>
          <div className='text-confirm-alert'>
            <div>
              <span>{renderHTML(text)}</span>
            </div>
            <div>
              {children}
            </div>
          </div>
        </div>
        <div className='container-bottom-btns-confirm'>
          {cancel && <div onClick={(e)=>handleAction(e,'cancel')} className='btn-cancelar-confirm'>{cancel}</div>}
          {confirm && <div onClick={(e)=>handleAction(e, 'confirm')} className={`${true?'':'btn-cancelar-confirm'}`}>{confirm}</div>}
          {accept && <div onClick={(e)=>handleAction(e, 'accept')} className='btn-aceptar-confirm'>{accept}</div>}
        </div>
      </div>
    </div>
  )
} 

export default Alert
