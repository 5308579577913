export const initialForm = {
  "id": "aiNPCc",
  "title": "DOCE Cuestionario Inicial",
  "theme": {
    "href": "https://api.typeform.com/themes/Bat25L"
  },
  "workspace": {
    "href": "https://api.typeform.com/workspaces/k7Yi9G"
  },
  "settings": {
    "is_public": true,
    "is_trial": false,
    "language": "es",
    "progress_bar": "percentage",
    "show_progress_bar": true,
    "show_typeform_branding": true,
    "meta": {
      "allow_indexing": false,
      "description": "¿Estás listo para lo que viene? ¡Déjanos tus respuestas y empecemos a perseguir esos objetivos! Bienvenido a DOCE.",
      "image": {
        "href": "https://images.typeform.com/images/r3HnrLqEFsdQ"
      }
    },
    "notifications": {
      "self": {
        "recipients": [
          "alberto@themacrowizard.com",
          "webmaster@ethosglobalfitness.com"
        ],
        "subject": "Nuevo typeform DOCE: {{form:title}}",
        "message": "Tu typeform {{form:title}} tiene una respuesta nueva:<br/>{{form:all_answers}}<br/>",
        "enabled": true
      },
      "respondent": {
        "recipient": "{{field:06e5e6fd-213e-476f-844a-68a05b9eeb30}}",
        "subject": "Gracias por completar {{form:title}}",
        "message": "Hola,<br/>Hemos recibido todas tus respuestas correctamente, ¡nos ponemos a ello!<br/>Mil gracias, pasa un día genial.<br/>El equipo de DOCE.",
        "reply_to": [
          "webmaster@ethosglobalfitness.com"
        ],
        "enabled": true
      }
    }
  },
  "welcome_screens": [
    {
      //"ref": "f7bde151937f7feb",
      "title": "<b>¡Enhorabuena por tomar el primer paso hacia tus objetivos!</b>",
      "properties": {
        "show_button": true,
        "description": "En este rápido formulario vamos a tratar varias cosas sobre tu estilo de vida, nutrición y objetivos para poder crear un plan que, no solo sea efectivo para nuestras doce semanas de aventura, sino que cree hábitos que perduren el resto de tu vida.<br/><br/><b>Dedícale un poco de tiempo a las respuestas, busca un lugar tranquilo y sé honesto contigo mismo.</b>",
        "button_text": "¡Vamos!"
      },
      "attachment": {
        "type": "image",
        "href": "https://images.typeform.com/images/TmswJ72UD2me"
      }
    }
  ],
  "thankyou_screens": [
    {
      //"ref": "3de9b9d9-25e2-4ad1-9767-8def3a1b0215",
      "title": "¡Gracias, {{personalData.nombre}}!<br/><br/>Nos pondremos manos a la obra y recibirás todo en un plazo de 7 días laborables.<br/><br/>Mientras tanto... ¿por qué no le echas un ojo a las páginas privadas que hemos creado para los miembros de DOCE como tú en exclusiva?<br/><br/><b>PREGUNTAS FRECUENTES:</b> <br/><a href='http://doce.edubarrecheguren.com/faq/'>http://doce.edubarrecheguren.com/faq/</a><br/><br/><b>TUTORIALES DE EJERCICIOS:</b> <a href='http://doce.edubarrecheguren.com/tutoriales-de-ejercicios/'>http://doce.edubarrecheguren.com/tutoriales-de-ejercicios/</a><br/><br/><b>MENTALIDAD Y HERRAMIENTAS:</b><br/><a href='http://doce.edubarrecheguren.com/videos-motivacionales-y-lifestyle/'>http://doce.edubarrecheguren.com/videos-motivacionales-y-lifestyle/</a><br/><br/><b>La contraseña de acceso para todas las páginas es 12.1.1</b><br/><br/>¡Un placer tenerte a bordo! <br/>",
      "properties": {
        "show_button": false,
        "share_icons": false,
        "button_mode": "reload",
        "button_text": "Continuar",
        "hide_marks": true,
      },
      
      "layout": {
        "pageContent": {
          "className": 'thankyou-screen-ends'
        },
        "topContainer": {
          "className": "image-top-thankyou-screen",
          "attachment": {
            "type": "image",
            //"src": require('../Images/InitialForm/logo-thankyou-screen.png')
          },
        }
      },
      "type": "statement"

    },
    {
      //"ref": "default_tys",
      "title": "¡Hecho! Tu información se ha enviado perfectamente.",
      "properties": {
        "show_button": false,
        "share_icons": false
      }
    }
  ],
  "fields": [
    {
      "id": "Df8wey8XJola",
      "title": "Antes de nada, ¿me confirmas tu nombre?",
      "ref": `personalData.nombre`,
      "validations": {
        "required": true
      },
      "attachment": {
        "type": "image",
        //"src": require('../Images/InitialForm/Df8wey8XJola.png')
      },
      "type": "input",
      "identidicator": "1"
    },
    {
      "id": "JteVM0TgOwZL",
      "title": "¿Y apellidos?",
      "ref": `personalData.apellidos`,
      "validations": {
        "required": true
      },
      "type": "input",
      "identidicator": "2"
    },
    {
      "id": "pBFjoOOwnLDK",
      "title": "Genial, {{personalData.nombre}}.<br/><br/>¿Cuál es tu fecha de nacimiento?",
      "ref": `personalData.fechaNacimiento`,
      "properties": {
        "structure": "DDMMYYYY",
        "separator": "/"
      },
      "validations": {
        "required": true
      },
      "type": "date",
      "identidicator": "3"
    },
    {
      "id": "YIseKniLSIJg",
      "title": "<b>¡Empezamos,</b> {{personalData.nombre}}!<br/><br/>Para completar este cuestionario necesitarás lo siguiente, asegúrate de tenerlo preparado antes de seguir:<br/><br/>· Tus medidas según te indicamos en el email inicial (o una cinta de medir para tomarlas sobre la marcha).<br/><br/>· Tu diario de nutrición de 3 días incluyendo uno de los días de fin de semana.<br/><br/>· Tus fotos de frente, de espaldas y de perfil.<br/><br/>Podrás enviar todo esto en la parte final del cuestionario de forma sencilla.<br/><br/>El email suele siempre ser el preferido porque nos da más flexibilidad y deja una historia a la que siempre poder referrirnos luego.<br/><br/><b>Un sistema de trabajo sencillo, claro y efectivo es la base de todo éxito.</b>",
      "properties": {
        "hide_marks": true,
        "button_text": "¡Entendido!"
      },
      "type": "statement"
    },
    {
      "id": "OsP3adUakyP7",
      "title": "¿Cuál es tu dirección de correo electrónico para estar en contacto, {{personalData.nombre}}?",
      "ref": `personalData.email`,
      "properties": {
        "description": "Te enviaremos la invitación al grupo privado de Facebook a este correo.<br/><br/>¿No estás seguro si tu cuenta de Facebook está conectada a ese correo?<br/><br/>Tranquilo, recibirás instrucciones para unirte al grupo por email junto a tu programa."
      },
      "validations": {
        "required": true
      },
      "type": "email",
      "identidicator": "4"
    },
    {
      "id": "ZsihWuXobwQA",
      "title": "Gracias, {{personalData.nombre}}.<br/><br/>¿Cúal es tu teléfono móvil?",
      "ref": `personalData.telefono`,
      "properties": {
        "description": "Tu email y teléfono móvil se utilizan para estar en contacto contigo durante tu aventura en DOCE únicamente.<br/><br/><b>Tranquilo, tus datos están seguros con nosotros,</b><br/><b>cero <i>spam</i>.</b>",
        "default_country_code": "ES"
      },
      "validations": {
        "required": true
      },
      "type": "phoneNumber",
      "identidicator": "5"
    },
    {
      "id": "g5IyfCuAYqpu",
      "title": "Gracias por dejarnos todos tus datos y preferencias de contacto. <br/><br/>¡Hora de meternos a fondo con tu estado actual y objetivos!<br/><br/>",
      "properties": {
        "hide_marks": true,
        "button_text": "Seguimos"
      },
      "attachment": {
        "type": "video",
        //"src": require('../Images/InitialForm/ScrollingThroughSmartphone.mp4'),
      },
      "layout": {
        "attachment": {
          "properties": {
            "className": "prefencias-video",
          }
        },
        "pageContent": {
          "className": 'video-statement'
        }
      },
      "type": "statement"
    },
    {
      "id": "UeWRML5xeKni",
      "title": "Empecemos por algunos datos básicos sobre tu estado actual.<br/><br/>¿Cuál es tu altura en cm?",
      "ref": `initialForm.questions`,
      "properties": {
        "description": "No necesitas incluir \"cm\" al final, tan solo el número."
      },
      "validations": {
        "required": true
      },
      "type": "number",
      "identidicator": "6"
    },
    {
      "id": "pgcEflgaqTna",
      "title": "¿Y tu peso en kg?",
      "ref": `initialForm.questions`,
      "properties": {
        "description": "No necesitas incluir \"kg\" al final, tan solo el número.<br/><br/>Recuerda pesarte siempre por la mañana, en ayunas, justo después de ir al baño."
      },
      "validations": {
        "required": true
      },
      "type": "number",
      "identidicator": "7"
    },
    {
      "id": "QTPnJtPGXWES",
      "title": "Por último, ¿cuál es tu medida de cintura en cm?",
      "ref": `initialForm.questions`,
      "properties": {
        "description": "Echa un ojo a este ejemplo para saber dónde tomarte la medida, la número \"6\" es la que necesitamos por ahora.<br/><br/>Tan solo el número esta vez también, <i>please</i>."
      },
      "validations": {
        "required": true
      },
      "layout": {
        "sectionClassName": "cintura-container",
        "topContainer": {
          "className": "cintura-top-container",
          "attachment": {
            "type": "image",
            //"src": require('../Images/InitialForm/QTPnJtPGXWES.png')
          }
        },
      },
      "type": "input",
      "identidicator": "8"
    },
    {
      "id": "ZbyxfGvX63T5",
      "title": "<b>Gracias por toda la info y tus medidas.</b>",
      "properties": {
        "description": "A la hora de llevar el seguimiento durante tu aventura en DOCE, intenta ser tú el que toma las medidas y pesos medios cada semana. Queremos datos constantes, no necesariamente \"perfectos\".<br/><br/>Al aprender a tomártelas tú mismo podrás ser autosuficiente y cumplir con los plazos de cada seguimiento.<br/><br/><b>¿Cómo saber que los datos son constantes?</b><br/>Toma la misma medida <i>tres veces</i> y saca una media, así haremos más pequeño el potencial margen de error... ¡somos humanos!",
        "hide_marks": true,
        "button_text": "Entendido, seguimos."
      },
      "type": "statement"
    },
    {
      "id": "g83Ty6o1KHjj",
      "title": "Tus objetivos.",
      "properties": {
        "description": "Vamos a entrar a evaluar tus objetivos, entorno, experiencia previa con el entrenamiento y la nutrición y otras cosas que nos ayudarán –y esperamos que te ayude a ti también– a conocerte mejor.",
        "show_button": false,
        "button_text": "Continuar",
        "hide_marks": true,
        "fields": [
          {
            "id": "nz8tIQ2EnI77",
            "title": "¿Vas a entrenar en un gimnasio o en casa?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "¿No ves tu opción? Elige \"otro\" y especifica, porfa.",
              "randomize": false,
              "allow_multiple_selection": false,
              "allow_other_choice": true,
              "vertical_alignment": true,
              "choices": [
                {
                  "id": "dmnUvWwuu8EZ",
                  "label": "Gimnasio"
                },
                {
                  "id": "WNBhP3kPZ0M8",
                  "label": "En casa"
                },
                {
                  "id": "fhnb9ZeKX8KP",
                  "label": "Box de CrossFit o similar"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "identidicator": "a.",
            "hideArrowIndentificator": true

          },
          {
            "id": "yY7utz84toGe",
            "title": "¿Has tenido alguna lesión previa / problemas médicos que puedan tener repercusiones en un programa de ejercicio?<br/>",
            "ref": `initialForm.questions`,
            "properties": {
              "allow_multiple_selection": false,
              "choices": [
                {
                  "id": "yes",
                  "label": "Si"
                },
                {
                  "id": "no",
                  "label": "No"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "yesOrNo": true,
            "identidicator": "b.",
            "hideArrowIndentificator": true
          },
          {
            "id": "q2wVZ4lDYK7F",
            "title": "Por favor, descríbenos tu lesión, cuándo la tuviste, si tienes un alta médica y todos los detalles que puedan ser relevantes.",
            "ref": `initialForm.questions`,
            "validations": {
              "required": false
            },
            "type": "input",
            "identidicator": "c.",
          },
          {
            "id": "Bbzd6GgaupkA",
            "title": "Del 1 al 10, ¿cómo puntuarías tu salud en general?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Piensa en el total de tu salud y calidad de vida. <br/>¿Te mueves, ves, sientes y haces lo que y cómo quieres? Ponle una nota del 1 al 10, usaremos esto como referencia para el futuro y para ir midiendo mejoras más allá de \"las medidas y el peso\".",
              "steps": 10,
              "start_at_one": false,
              "labels": {
                "left": "¿Sigo vivo?",
                "center": "Normal",
                "right": "Estoy como un roble"
              }
            },
            "validations": {
              "required": true
            },
            "type": "opinion_scale",
            "identidicator": "d.",
          },
          {
            "id": "gYrMOExSQqIC",
            "title": "¿Tienes alguna intolerancia o alergia a algún alimento y/o requisito nutricional especial?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Puedes elegir varias si aplica a tu caso, excepto cuando elijas \"No, nada.\"",
              "randomize": false,
              "allow_multiple_selection": true,
              "allow_other_choice": true,
              "vertical_alignment": true,
              "choices": [
                {
                  "id": "vb8HlUkfJFRZ",
                  //"ref": "6d358682-da64-4ca9-8ac1-ee56bfb07c7a",
                  "label": "No, nada."
                },
                {
                  "id": "pmkqNaH8HKnr",
                  //"ref": "f1aa1f5f-3180-48f5-b26e-437758fea7ca",
                  "label": "Intolerancia a la lactosa"
                },
                {
                  "id": "wXzfdZfr43cr",
                  //"ref": "bb33a1f4-edf4-40b9-aba8-63c82cbfdde1",
                  "label": "Intolerancia al gluten"
                },
                {
                  "id": "LFNZY7xszrlD",
                  //"ref": "3a467d4a-8670-4e2c-a19c-c7321b31bd13",
                  "label": "IBS (Síndrome del intestino irritable)"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice"
          },
          {
            "id": "NMX6GijzyKcL",
            "title": "¿Estás actualmente tomando alguna medicación bajo prescripción médica y/o tomas alguna droga para mejorar el rendimiento deportivo, cognitivo, etc?",
            "ref": `initialForm.questions`,
            "properties": {
              "allow_multiple_selection": false,
              "choices": [
                {
                  "id": "yes",
                  "label": "Si"
                },
                {
                  "id": "no",
                  "label": "No"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "yesOrNo": true,
            "identidicator": "f.",
            "hideArrowIndentificator": true
          },
          {
            "id": "N3hD8VY5leEx",
            "title": "¿Cuál/cuáles y para qué objetivo?",
            "ref": `initialForm.questions`,
            "validations": {
              "required": false
            },
            "type": "input",
            "identidicator": "g.",
            "hideArrowIndentificator": true
          },
          {
            "id": "G1gueZJZksM8",
            "title": "¿Qué quieres conseguir en este camino juntos?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Marca todas las opciones que crees que se alinean con tu objetivo actual.",
              "randomize": false,
              "allow_multiple_selection": true,
              "allow_other_choice": false,
              "vertical_alignment": false,
              "choices": [
                {
                  "id": "AUPwOni6UEIO",
                  //"ref": "1283bef6e434b648",
                  "label": "Perder grasa"
                },
                {
                  "id": "l2uaWsXbH2RP",
                  //"ref": "8aab8b8f20ff901f",
                  "label": "Ganar peso/músculo"
                },
                {
                  "id": "tlrpuGSNUbX7",
                  //"ref": "c2683c0a968b95ab",
                  "label": "\"Tonificar\""
                },
                {
                  "id": "OazuptYlXdfj",
                  //"ref": "c9b2e6eed0f2e3d7",
                  "label": "Ganar fuerza"
                },
                {
                  "id": "pGmDJEB9E7ou",
                  //"ref": "7e68e9a4648e7db1",
                  "label": "Mejorar mis hábitos nutricionales y de ejercicio"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "identidicator": "h.",
            "hideArrowIndentificator": true
          },
          {
            "id": "t05wBHZQUEvn",
            "title": "<b>¿Tonificar?</b> <br/><br/><b>Simplemente ten en cuenta que lo que estás diciendo aquí realmente es \"ganar algo de músculo para verme mejor mientras pierdo grasa para que se vea ese músculo\".</b><br/><br/>El término tonificar ha sido abusado por la industria una y otra vez, incluso llegando a hacer creer a la gente que podrías \"convertir la grasa en músculo\", ¡la alquimia del fitness! 🧙‍♂️🧪<br/><br/>Verás mucho más sobre esto en nuestra guía DOCE y los grupos privados una vez empecemos, pero por ahora quédate con este mensaje:<br/><br/><b>Tonificar no existe ni es un objetivo real.</b><br/><b>El mejor término aquí es \"perder grasa y ganar algo de músculo, consiguiendo una recomposición corporal\".</b><br/><br/>Estas palabras te ayudarán más a la hora de evaluar tu progreso a medio/largo plazo.<br/><br/>Seguimos con el cuestionario, ¡ya casi estas!",
            "properties": {
              "hide_marks": true,
              "button_text": "¡Entendido, gracias!"
            },
            "attachment": {
              "type": "video",
              //"src": require('../Images/InitialForm/Tonificar.mp4'),
            },
            "layout": {
              "attachment": {
                "properties": {
                  "className": "prefencias-video",
                }
              },
              "pageContent": {
                "className": 'video-statement'
              }
            },
            "type": "statement"
          },
          {
            "id": "nsK3lDEDwPVq",
            "title": "<b>Nuestra mente es el arma más poderosa que tenemos.</b><br/><br/>¿Qué estás dispuesto a hacer para convertir esos objetivos en realidad?<br/>",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Puedes estar dispuesto a darlo todo en los entrenamientos y cumplir con tus objetivos y hábitos de nutrición, pero quizás no quieres dejar, bajo ningún concepto, esa cervecita del viernes con los colegas. <br/><br/>¡Perfecto! Escríbelo aquí para que lo sepamos –y lo sepas tú– ;)"
            },
            "validations": {
              "required": true
            },
            "type": "long_text",
            "identidicator": "i.",
            "hideArrowIndentificator": true
          },
          {
            "id": "nsK3lDEDwPVq2",
            "title": "¿Qué es lo que tienes claro que NO harás?<br/>",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Puedes estar dispuesto a darlo todo en los entrenamientos y cumplir con tus objetivos y hábitos de nutrición, pero quizás no quieres dejar, bajo ningún concepto, esa cervecita del viernes con los colegas. <br/><br/>¡Perfecto! Escríbelo aquí para que lo sepamos –y lo sepas tú– ;)"
            },
            "validations": {
              "required": true
            },
            "type": "long_text",
            "identidicator": "i.",
            "hideArrowIndentificator": true
          },
          {
            "id": "ZQqr0x4In82j",
            "title": "Mirando a tu estilo de vida actual y siendo absolutamente realistas, ¿cuánto tiempo estás preparado para dedicarle al entrenamiento <b>cada semana</b>?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Recuerda: no es una competición ni más es mejor. <br/><br/>Simplemente queremos saber exactamente de cuántas horas dispones a la semana para entrenar y así poder sugerirte esos primeros pasos.",
              "randomize": false,
              "allow_multiple_selection": false,
              "allow_other_choice": false,
              "vertical_alignment": true,
              "choices": [
                {
                  "id": "pNCTqK26CN6M",
                  //"ref": "f60e9ae0-f8b2-4c5c-a772-9d77b2356d7f",
                  "label": "Menos de 3 horas"
                },
                {
                  "id": "FuzsGZ9cUpVa",
                  //"ref": "898ece58-7e67-4b57-bc7b-c2363b692369",
                  "label": "De 3 a 4 horas"
                },
                {
                  "id": "jjEnGkYF3oIc",
                  //"ref": "d72acf00-e3db-4c4c-a84b-330fee24f1cb",
                  "label": "De 4 a 6 horas"
                },
                {
                  "id": "VDQZylF5GrJb",
                  //"ref": "c14f3066-e970-4956-92c6-d685ab68b3b4",
                  "label": "Más de 6 horas"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "identidicator": "j.",
            "hideArrowIndentificator": true
          },
          {
            "id": "dHHM6bAhqV50",
            "title": "En una escala del 1 al 10, ¿cómo de disciplinado te ves a ti mismo?",
            "ref": `initialForm.questions`,
            "properties": {
              "steps": 10,
              "start_at_one": true,
              "labels": {
                "left": "Desayuno cerveza y pizza",
                "center": "¡Lo normal!",
                "right": "Me llaman \"Disciplina\""
              }
            },
            "validations": {
              "required": true
            },
            "type": "opinion_scale",
            "identidicator": "k.",
            "hideArrowIndentificator": true
          },
          {
            "id": "A9XVlcIWM8ae",
            "title": "Para tener una idea más clara de tus metas en relación a la composición corporal, ¿hay alguna persona a la que te gustaría parecerte físicamente (deportista / celebridad)?<br/><br/>¿A quién?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "En caso de no tener a nadie en mente ahora mismo simplemente responde \"nadie\"."
            },
            "validations": {
              "required": true
            },
            "type": "input",
            "identidicator": "l.",
            "hideArrowIndentificator": true
          },
          {
            "id": "ql3XA74QVOnI",
            "title": "¿Qué sueles hacer para “desconectar”?<br/><br/>¿Alguna de esas cosas puede interferir en el camino hacia tu objetivo?<br/><br/>En caso afirmativo, ¿cuál/cuáles?<br/>",
            "ref": `initialForm.questions`,
            "validations": {
              "required": true
            },
            "type": "input",
            "identidicator": "m.",
            "hideArrowIndentificator": true
          },
          {
            "id": "bM8oiKOgJ9WG",
            "title": "Gracias, {{personalData.nombre}}. <br/><br/>Conocernos a nosotros mismos es igual de importante que de complicado, tus reflexiones nos ayudarán a crear un camino que sea fácil se seguir pero no a expensas de tus objetivos.<br/><br/>En el equilibrio está la respuesta.<br/><br/><b>¿Seguimos? ¡Ya queda poco!</b>",
            "properties": {
              "hide_marks": true,
              "button_text": "¡Vamos!"
            },
            "type": "statement"
          }
        ]
      },
      "menu": {
        "title": "Tus objetivos.",
        "identidicator": "9"
      },
      "type": "group",
      "identidicator": "9"
    },
    {
      "id": "GuKUGqLklj6d",
      "title": "Tu historia con el ejercicio y/o el entrenamiento.",
      "properties": {
        "description": "Para crear un programa efectivo necesitamos un poco de historia y contexto sobre tu experiencia con el entrenamiento.<br/>",
        "show_button": false,
        "button_text": "Continuar",
        "fields": [
          {
            "id": "eWGJ3iA8Kriu",
            "title": "Elige la opción que más se acerque a tu historia con el entrenamiento actualmente.",
            "ref": `initialForm.questions`,
            "properties": {
              "randomize": false,
              "allow_multiple_selection": false,
              "allow_other_choice": false,
              "vertical_alignment": true,
              "choices": [
                {
                  "id": "VPi2TfcpLBWF",
                  //"ref": "72c26a48-4949-4460-857a-fc0393855027",
                  "label": "Nunca he entrenado en el gimnasio, ni he hecho deporte de forma regular."
                },
                {
                  "id": "i9GzHTih9V9F",
                  //"ref": "12d6fb43-6756-4f15-b8fb-2562bd0d7c21",
                  "label": "Nunca he entrenado en el gimnasio pero he hecho deporte de forma regular."
                },
                {
                  "id": "LECklCSJcjqB",
                  //"ref": "8dbd0e45-18c8-4b9e-b321-75afde0ecd3f",
                  "label": "He entrenado en el gimnasio y/o he hecho deporte a intervalos (irregular)."
                },
                {
                  "id": "muYLWxtdh0w0",
                  //"ref": "9d5d3615-e606-4233-b29d-0f6471f73c0b",
                  "label": "Llevo entrenando regularmente en el gimnasio menos de 6 meses."
                },
                {
                  "id": "aXNbJZPlJyoU",
                  //"ref": "4c047479-3e14-4b86-ab86-cdc4b0441858",
                  "label": "Llevo entrenando regularmente en el gimnasio de 6 a 18 meses."
                },
                {
                  "id": "g4NIStzdjrrY",
                  //"ref": "3e5790f2-d347-4b5a-9944-7c01ed3299ca",
                  "label": "Llevo entrenando regularmente en el gimnasio más de 18 meses."
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "identidicator": "a.",
            "hideArrowIndentificator": true
          },
          {
            "id": "UUbjFLnSmx4b",
            "title": "¿Hay algo que físicamente no puedas hacer actualmente y que siempre hayas querido hacer?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Esto no afectará en nada a tu programa, pero sí puede ser una buena meta a tener en cuenta para el medio/largo plazo y de nuevo, otro baremo a utilizar más allá del peso en la báscula y las medidas cuando vayas a medir tu progreso personal.<br/><br/>En caso de no tener nada, selecciona \"Otro\" y escribe \"no tengo nada en mente\".",
              "randomize": false,
              "allow_multiple_selection": false,
              "allow_other_choice": true,
              "vertical_alignment": true,
              "choices": [
                {
                  "id": "HJBstOE3vOpJ",
                  //"ref": "5e842fa2-1983-4c1f-bf2a-56270bbd082c",
                  "label": "Hacer una dominada con mi propio peso"
                },
                {
                  "id": "IXLB469z7rTc",
                  //"ref": "e2a3ab61-573b-4c60-a05a-9efc749d3016",
                  "label": "Hacer flexiones completas con mi propio peso"
                },
                {
                  "id": "fIdzA3T32g1N",
                  //"ref": "955279ad-e6eb-4713-bfc1-f48018d05e30",
                  "label": "Hacer un muscle up"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "identidicator": "b.",
            "hideArrowIndentificator": true
          }
        ]
      },
      "attachment": {
        "type": "video",
        //"src": require('../Images/InitialForm/EjercicioHistoria.mp4'),
      },
      "layout": {
        "attachment": {
          "properties": {
            "className": "historia-ejercicio",
          }
        }
      },
      "type": "group",
      "menu": {
        "title": "Tu historia con el ejercicio y/o el entrenamiento.",
        "identidicator": "10"
      },
      "identidicator": "10"
    },
    {
      "id": "lMdSLTCS4UAS",
      "title": "<b>Todo camino empieza por un primer paso.</b><br/><br/>En esta parte del cuestionario vamos a ver tus hábitos actuales, tu experiencia previa con la nutrición y otras cosillas.<br/><br/>",
      "properties": {
        "description": "Recuerda: <br/><br/>No buscamos la \"respuesta correcta\" ya que no la hay, buscamos lo que más se acerque a tu realidad actual, es una forma genial de conseguir información útil para movernos en la dirección que queremos y de reflexionar sobre algunas cosas que quizás ni te has planteado anteriormente.",
        "show_button": false,
        "button_text": "¡Adelante!",
        "fields": [
          {
            "id": "CxCZmYVM1aOa",
            "title": "¿Cómo empiezas tus mañanas ahora mismo?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Intenta explicarnos rápidamente lo que haces en las primeras dos horas desde que te levantas."
            },
            "validations": {
              "required": false
            },
            "attachment": {
              "type": "video",
              //"src": require('../Images/InitialForm/Moorings.mp4'),
            },
            "layout": {
              "attachment": {
                "properties": {
                  "className": "moorings-video",
                }
              }
            },
            "type": "input",
            "identidicator": "a.",
            "hideArrowIndentificator": true
          },
          {
            "id": "fy65rYUnke9V",
            "title": "¿Has intentado algo en el pasado para mejorar tus hábitos alimenticios y/o tu composición corporal?",
            "ref": `initialForm.questions`,
            "properties": {
              "allow_multiple_selection": false,
              "description": "Si en el pasado perdiste grasa, ganaste significativa masa muscular, conseguiste ambos y/o al menos intentaste uno cualquiera de ellos, marca \"sí\".",
              "choices": [
                {
                  "id": "yes",
                  "label": "Si"
                },
                {
                  "id": "no",
                  "label": "No"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "yesOrNo": true,
            "identidicator": "b.",
          },
          {
            "id": "CQXXHU4mXI0T",
            "title": "De esas cosas, ¿qué funcionó bien?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "No importa lo que estés haciendo ahora mismo, intenta contarnos lo que crees que fue bien mientras hacías ese cambio.<br/><br/>¿Seguiste una dieta específica?<br/>¿Algo con lo que te sentiste especialmente bien?"
            },
            "validations": {
              "required": false
            },
            "type": "input",
            "identidicator": "c.",
          },
          {
            "id": "mk3A5XQBnUvE",
            "title": "Ahora cuéntanos lo que crees que no fue del todo bien.",
            "ref": `initialForm.questions`,
            "validations": {
              "required": false
            },
            "type": "input",
            "identidicator": "d.",
          },
          {
            "id": "D7xstQOckdC6",
            "title": "¿Cómo querrías que tus hábitos de nutrición, ejercicio y/o estilo de vida fueran distintos?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Intenta ser específico.<br/>(Ejemplo: \"Me gustaría comer más verduras pero no me aclaro al cocinarlas o comprarlas\")"
            },
            "validations": {
              "required": true
            },
            "type": "long_text",
            "identidicator": "e.",

          },
          {
            "id": "ncfcJReFW7yq",
            "title": "¿Has hecho algún cambio en tus hábitos recientemente?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Ahora andas más que antes o intentas subir siempre las escaleras, por ejemplo...",
              "choices": [
                {
                  "id": "yes",
                  "label": "Si"
                },
                {
                  "id": "no",
                  "label": "No"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "yesOrNo": true,
            "identidicator": "f.",
          },
          {
            "id": "uMxhBzZalY1z",
            "title": "¿Nos cuentas qué has hecho exactamente? :)",
            "ref": `initialForm.questions`,
            "validations": {
              "required": false
            },
            "type": "input",
            "identidicator": "g.",
          },
          {
            "id": "FWtAUOu3bafX",
            "title": "¿Cuántas veces comes <b>al día</b>?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Incluye los _snacks_/picoteos que hagas actualmente.",
              "alphabetical_order": false,
              "randomize": false,
              "choices": [
                {
                  "id": 1,
                  "label": "Dos comidas."
                },
                {
                  "id": 2,
                  "label": "Dos comidas y un snack."
                },
                {
                  "id": 3,
                  "label": "Dos comidas y dos snacks."
                },
                {
                  "id": 4,
                  "label": "Tres comidas."
                },
                {
                  "id": 5,
                  "label": "Tres comidas y un snack."
                },
                {
                  "id": 6,
                  "label": "Tres comidas y dos snacks."
                },
                {
                  "id": 7,
                  "label": "Cuatro comidas."
                },
                {
                  "id": 8,
                  "label": "Cuatro comidas y un snack."
                },
                {
                  "id": 9,
                  "label": "Cuatro comidas y un snack."
                },
                {
                  "id": 10,
                  "label": "Cinco comidas."
                },
                {
                  "id": 11,
                  "label": "Otro."
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "identidicator": "h.",
            //"type": "dropdown"
          },
          {
            "id": "Iyuk7Zq0Zdjl",
            "title": "¿Otro? Especifica cuántas comidas y snacks haces al día a continuación.",
            "ref": `initialForm.questions`,
            "validations": {
              "required": false
            },
            "type": "input"
          },
          {
            "id": "QN4qIJrO1EG2",
            "title": "¿Cocinas tu propia comida a menudo?",
            "ref": `initialForm.questions`,
            "properties": {
              "randomize": false,
              "allow_multiple_selection": false,
              "allow_other_choice": false,
              "vertical_alignment": true,
              "choices": [
                {
                  "id": "dy73Lkgo9njg",
                  //"ref": "581b4cee-4e37-40a1-b0d9-e59078ed0dee",
                  "label": "Menos del 50% de mis comidas"
                },
                {
                  "id": "XX2lGc1lMlyL",
                  //"ref": "99636746-1e49-45b1-b858-2f5a205e6500",
                  "label": "Del 50 al 60% de mis comidas"
                },
                {
                  "id": "rICzzXmu8YvJ",
                  //"ref": "b1480ae3-4fcc-4895-934e-a975c5405486",
                  "label": "Del 60 al 80% de mis comidas"
                },
                {
                  "id": "vcU56xIURmXr",
                  //"ref": "94aec0b3-eaae-40f3-8d34-bcd224af2901",
                  "label": "Más del 80% de mis comidas"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "identidicator": "i.",
          },
          {
            "id": "c8Txs4vdWwBA",
            "title": "¿Estás dispuesto a cocinar durante al menos las próximas doce semanas para aprender los básicos de la nutrición y ponerte la vida –y tus objetivos más fáciles? <br/><br/>En caso negativo, ¿por qué no y qué piensas hacer para 'compensarlo' y seguir aprendiendo?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Cocinar en casa te hará comer \"como un pro\" fuera de ella, no lo olvides."
            },
            "validations": {
              "required": true
            },
            "type": "long_text"
          },
          {
            "id": "BIuyl0Yjty3G",
            "title": "<b>¡Enhorabuena por estar cocinando a menudo!</b><br/><br/>Cocinar y comer la mayor parte del tiempo en casa hará que salir a comer fuera y/o improvisar en tu nutrición sea mucho más sencillo.",
            "ref": `initialForm.questions`,
            "properties": {
              "hide_marks": true,
              "button_text": "¡Seguimos!"
            },
            "attachment": {
              "type": "video",
              //"src": require('../Images/InitialForm/Cooking.mp4'),
            },
            "layout": {
              "pageContent":{
                "className": "coocking-statement"
              },
              "attachment": {
                "properties": {
                  "className": "moorings-video",
                }
              }
            },
            // "layout": {
            //   "type": "wallpaper",
            //   "attachment": {
            //     "type": "video",
            //     "href": "https://www.pexels.com/video/salmon-in-a-pan-with-a-bit-of-water-3296399/",
            //     "properties": {
            //       "brightness": -0.4,
            //       "focal_point": {
            //         "x": 0,
            //         "y": 0
            //       }
            //     }
            //   }
            // },
            "type": "statement"
          },
          {
            "id": "iq4IxEfkeCcm",
            "title": "¿Cada cuanto comes en restaurantes, sitios de comida rápida, etc?",
            "ref": `initialForm.questions`,
            "properties": {
              "randomize": false,
              "allow_multiple_selection": false,
              "allow_other_choice": false,
              "vertical_alignment": true,
              "choices": [
                {
                  "id": "ygiFrWESuUbq",
                  //"ref": "791de723-4d5b-489d-b02a-fc49259d5ce8",
                  "label": "Una vez cada dos semanas"
                },
                {
                  "id": "njyS650QW5Yy",
                  //"ref": "f33f45b8-1e3a-48db-adad-8a50ac95744c",
                  "label": "Una vez a la semana"
                },
                {
                  "id": "gUJD389SEDVo",
                  //"ref": "c4fbc215-0ba0-4186-afe6-b20a56ca0732",
                  "label": "De 2 a 3 veces por semana"
                },
                {
                  "id": "ARPGlsHCSqSt",
                  //"ref": "7e2585a1-a8f6-4555-b24a-75999ac28b89",
                  "label": "Más de 3 veces por semana"
                },
                {
                  "id": "KSWk7o8QzsPa",
                  //"ref": "6d0c4a8e-0f69-427b-ba28-825ab53bf728",
                  "label": "No suelo comer fuera casi nada"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice"
          },
          {
            "id": "AiRWLwoi4JO2",
            "title": "¿Cuántas tazas de café, té y/o bebidas energéticas tomas al día?",
            "ref": `initialForm.questions`,
            "properties": {
              "randomize": false,
              "allow_multiple_selection": false,
              "allow_other_choice": false,
              "vertical_alignment": true,
              "choices": [
                {
                  "id": "ugsHUuAYWu44",
                  //"ref": "39062abf-e40d-4884-b86b-0ada25e67444",
                  "label": "Ninguna"
                },
                {
                  "id": "eoFTyx8r9rfX",
                  //"ref": "85e25bd8-9d6b-40e6-b35d-3b7e7a274be8",
                  "label": "Una"
                },
                {
                  "id": "E2Moca26YNfX",
                  //"ref": "b98ff6c9-00f8-452e-86f0-4cc27a7114c0",
                  "label": "Dos"
                },
                {
                  "id": "aVLrYdSMjXx7",
                  //"ref": "e54fa1e3-35e3-489e-947d-8a145f555b0a",
                  "label": "Tres"
                },
                {
                  "id": "RnDuTQIjHmiH",
                  //"ref": "0972333a-40b6-43c5-af4d-c1306d36bd24",
                  "label": "Más de tres"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice"
          },
          {
            "id": "LZprE2aCb0kp",
            "title": "Esos cafés, tés, etc, ¿llevan leche, azúcar, etc?",
            "ref": `initialForm.questions`,
            "properties": {
              "randomize": false,
              "allow_multiple_selection": false,
              "allow_other_choice": false,
              "vertical_alignment": true,
              "choices": [
                {
                  "id": "oUKaiitTsKaS",
                  //"ref": "12053692-e1c2-4116-896b-beb19a5fa808",
                  "label": "Leche"
                },
                {
                  "id": "lekp6SCvK5Ly",
                  //"ref": "e183963a-ecbb-4714-b869-90273ce49240",
                  "label": "Azúcar"
                },
                {
                  "id": "HUMSPqN0q5H2",
                  //"ref": "bedb4e2d-9ab1-4255-89d3-868531451a2f",
                  "label": "Leche y azúcar"
                },
                {
                  "id": "lneZYmG3FrYH",
                  //"ref": "4cc3e85c-2451-4615-a646-5b1026c6865c",
                  "label": "Leche, azúcar, siropes y otras cosas"
                },
                {
                  "id": "cwKvMt95xyZF",
                  //"ref": "fc608d7f-7371-409d-8284-81e28f0aaa6c",
                  "label": "Nada, los suelo tomar solos y/o con edulcorante"
                }
              ]
            },
            "validations": {
              "required": false
            },
            "type": "multiple_choice"
          },
          {
            "id": "UYCE5Alpl5Vt",
            "title": "¿Bebes alcohol?",
            "ref": `initialForm.questions`,
            "properties": {
              "randomize": false,
              "allow_multiple_selection": false,
              "allow_other_choice": false,
              "vertical_alignment": true,
              "choices": [
                {
                  "id": "G3DLlIhTlSzf",
                  //"ref": "a5b3df64-fdce-4e02-97be-b336f508143e",
                  "label": "No bebo"
                },
                {
                  "id": "TI20OYZ0YQ3H",
                  //"ref": "f11d3de8-8c89-46f8-b1f2-f6778e2f4027",
                  "label": "Solo vino/cerveza, una o dos bebidas por semana"
                },
                {
                  "id": "Yu1plKhB38kJ",
                  //"ref": "038c5ed3-84b3-4230-a98a-1bb67b3aa5ec",
                  "label": "Solo vino/cerveza, tres o más bebidas por semana"
                },
                {
                  "id": "vF52NOY595ST",
                  //"ref": "51c6895c-38df-40c6-b172-16c7fc592e68",
                  "label": "Dos o tres \"copazos\" por semana"
                },
                {
                  "id": "XsXzdO4ZPfLB",
                  //"ref": "0bfaaeb3-baf9-45f9-b94d-89be4536ad6b",
                  "label": "Por supuesto, mi vida es Rock&Roll ¡Copazos para todos!"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice"
          },
          {
            "id": "qKnEwiAQwDVZ",
            "title": "¿Cuánta agua bebes al día aproximadamente?",
            "ref": `initialForm.questions`,
            "properties": {
              "randomize": false,
              "allow_multiple_selection": false,
              "allow_other_choice": false,
              "vertical_alignment": true,
              "choices": [
                {
                  "id": "HCbjPICegJGw",
                  //"ref": "2d6145d9-e866-494b-a526-916920637e81",
                  "label": "Cuatro vasos o menos"
                },
                {
                  "id": "NXP6jiPuEa9f",
                  //"ref": "b48796d2-57d3-4570-9a5e-dfb26ff6d657",
                  "label": "De cuatro a seis vasos"
                },
                {
                  "id": "CZwFc3u5Ybuj",
                  //"ref": "68e75fc9-59bb-4850-b8ca-93d7fd5e73c4",
                  "label": "De seis a ocho"
                },
                {
                  "id": "jjE9Gpstii8l",
                  //"ref": "5ee55814-bfc3-4a77-b03b-46843369d1de",
                  "label": "Más de ocho"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice"
          },
          {
            "id": "etYYvfVTkf9I",
            "title": "<b>¿Dirías que tu estado emocional afecta a lo que comes en el día?</b>",
            "ref": `initialForm.questions`,
            "validations": {
              "required": true
            },
            "properties": {
              "allow_multiple_selection": false,
              "description": "Si en el pasado perdiste grasa, ganaste significativa masa muscular, conseguiste ambos y/o al menos intentaste uno cualquiera de ellos, marca \"sí\".",
              "choices": [
                {
                  "id": "yes",
                  "label": "Si"
                },
                {
                  "id": "no",
                  "label": "No"
                }
              ]
            },
            "type": "multiple_choice",
            "yesOrNo": true,
            "identidicator": "o.",
          },
          {
            "id": "mjbZQ2rqmxUb",
            "title": "¿Qué sueles comer en esos casos?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Recuerda: no hay respuesta correcta, tan solo intentamos reflexionar y ver qué estamos haciendo actualmente para conocernos mejor. Intenta ser preciso con lo que comes y la cantidad."
            },
            "validations": {
              "required": false
            },
            "type": "input",
            "identidicator": "p.",
          },
          {
            "id": "zbHNDQCSNR4V",
            "title": "<b>Para terminar con este apartado, ¿podrías explicarme cómo serían 3 días de comidas para ti?</b><br/><br/>Incluye al menos un día de fin de semana o \"especial\" que suelas tener fuera de tu rutina.<br/><br/>Sube el documento en Word, PDF o similar.",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Ejemplo:<br/><br/>+Día entre semana:<br/>- Desayuno: Café solo.<br/>- Medio día: Dos/tres galletas y un café en la oficina.<br/>- Comida: Un cuarto de pollo asado con patatas al horno y verduras, un poco de pan.<br/>- Cena: Ensalada gigante para la familia, un poco de vino y un yogur con fruta de postre.<br/><br/>+Día de fin de semana:<br/>- Desayuno: Tortitas con chocolate y café con leche.<br/>- Comida: Paella con la familia, pan y postre.<br/>- Tarde: Cerveza con los colegas.<br/>- Cena: Sobras de paella y algo de helado."
            },
            "validations": {
              "required": true
            },
            "type": "file_upload"
          },
          {
            "id": "KIeyI6xuGCGo",
            "title": "<b>¡Ya casi estamos!</b> <br/><br/>Sólo nos queda conocer un poco más de tu entorno y lo tendremos todo para comenzar.",
            "ref": `initialForm.questions`,
            "properties": {
              "hide_marks": false,
              "button_text": "¡Seguimos!"
            },
            "layout": {
              "pageContent": {
                "className": 'center-display'
              }
            },
            "type": "statement"
          }
        ]
      },
      "attachment": {
        "type": "image",
        "href": "https://images.typeform.com/images/eYrQ3HVZy5PX"
      },
      "type": "group",
      "menu": {
        "title": "<strong>Todo camino empieza por un primer paso.</strong>  En esta parte del cuestionario vamos a ver tus hábitos actuales, tu experiencia previa con la nutrición y otras cosillas.",
        "identidicator": "11"
      },
      "identidicator": "11"
    },
    {
      "id": "V6Du3glNogcY",
      "title": "<b>Estilo de vida y sueño.</b>",
      "properties": {
        "description": "Para terminar vamos a hablar un poco más al detalle de tu estilo de vida actual, el descanso, sueño y otras cosas de utilidad.",
        "show_button": false,
        "button_text": "Continuar",
        "fields": [
          {
            "id": "qcKDSKj6ljHR",
            "title": "¿A qué te dedicas? ¿Cuántas horas trabajas al día?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Ejemplo: Soy ingeniero de sistemas, trabajo unas 8 horas diarias de lunes a viernes."
            },
            "validations": {
              "required": true
            },
            "type": "long_text",
            "identidicator": "a.",
            "hideArrowIndentificator": true
          },
          {
            "id": "Fbc3AK5TGdJY",
            "title": "<b>¿Cuál es tu nivel de actividad física en el trabajo?</b>",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "\"Entrenamiento de fuerza\" pueden simplemente ser flexiones y/o sentadillas y no tiene porqué significar machacarte en el gimnasio únicamente. ¡Hay mil maneras de hacerlo!",
              "randomize": false,
              "allow_multiple_selection": false,
              "allow_other_choice": false,
              "vertical_alignment": false,
              "choices": [
                {
                  "id": "Cova4Esa0IOb",
                  "ref": "ad08feceb8e5fc92",
                  "label": "Sedentario. (Trabajo de oficina principalmente, recepción, call center, etc)"
                },
                {
                  "id": "eOHKmDVaTpiE",
                  "ref": "8d8efb9d2a5bdc7a",
                  "label": "Medianamente activo. (Entrenador personal, camarero, cocina, guardia jurado, policía, etc)"
                },
                {
                  "id": "dJZ5xbJJ7VxL",
                  "ref": "30907ffa-f085-4826-be20-552b97dc85b8",
                  "label": "Activo (Agricultor, monitor de sala con varias clases diarias, cartero a pie, etc)"
                },
                {
                  "id": "urQayDB4RU1P",
                  //"ref": "f322fd64-9a39-44dd-9be1-89f286ce3651",
                  "label": "Muy activo (Obrero y otros trabajos de alto impacto y movimiento constante)"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "identidicator": "b.",
            "hideArrowIndentificator": true
          },
          {
            "id": "W9gilDbTlOSf",
            "title": "Del 1 al 5, ¿cómo percibes tu nivel de estrés actual?",
            "ref": `initialForm.questions`,
            "properties": {
              "steps": 5,
              "start_at_one": false,
              "labels": {
                "left": "Súper zen",
                "center": "Normal",
                "right": "Estresado constantemente"
              }
            },
            "validations": {
              "required": true
            },
            "type": "opinion_scale",
            "identidicator": "c.",
            "hideArrowIndentificator": true
          },
          {
            "id": "QZUtBhsk1T0Y",
            "title": "¿Implica tu trabajo que comas y bebas fuera a menudo (cenas/comidas con clientes, cervezas con colegas del trabajo, etc)",
            "ref": `initialForm.questions`,
            "properties": {
              "randomize": false,
              "allow_multiple_selection": false,
              "allow_other_choice": false,
              "vertical_alignment": true,
              "choices": [
                {
                  "id": "aOkzLxtvJVLT",
                  //"ref": "968963b3-2cf3-4fa5-bb62-5fbbf358a0e7",
                  "label": "No"
                },
                {
                  "id": "bcJLp1aCwE8M",
                  //"ref": "deec486f-9236-4cb9-8edc-16141c58c74d",
                  "label": "Sí, una o dos veces por semana"
                },
                {
                  "id": "BnL63qUEm7hc",
                  //"ref": "b5a74229-3e9b-46dd-98ef-5bcf7bee9f9d",
                  "label": "Más de dos veces por semana"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "identidicator": "d.",
            "hideArrowIndentificator": true
          },
          {
            "id": "oihOfMsFbofq",
            "title": "¿Te cuesta desconectar por la noche?",
            "ref": `initialForm.questions`,
            "properties": {
              "randomize": false,
              "allow_multiple_selection": false,
              "allow_other_choice": false,
              "vertical_alignment": true,
              "choices": [
                {
                  "id": "lP3mHncnWelf",
                  //"ref": "317dc296-df34-46d2-b826-43cfa082dc53",
                  "label": "Sí, mi mente no para y me cuesta conciliar el sueño"
                },
                {
                  "id": "ZZmloN6jvm2D",
                  //"ref": "b892b2cc-4957-4e26-b70f-402f153ab7ee",
                  "label": "No, descanso perfectamente"
                },
                {
                  "id": "dCGvvykOGLji",
                  //"ref": "94b93640-5306-4512-9128-21f0c991c1d5",
                  "label": "A veces, depende del día"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "identidicator": "e.",
            "hideArrowIndentificator": true
          },
          {
            "id": "dZW7vYObZCch",
            "title": "<b>¿Cuántas horas sueles dormir de media cada noche?</b>",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Puede que duermas más los fines de semana por el trabajo, haz una media de toda la semana y elige la opción que más se acerque.",
              "randomize": false,
              "allow_multiple_selection": false,
              "allow_other_choice": false,
              "vertical_alignment": false,
              "choices": [
                {
                  "id": "w85DUNY1vrJC",
                  //"ref": "b697850a808e3922",
                  "label": "Menos de 6 horas"
                },
                {
                  "id": "zsO73q0Mj5v4",
                  //"ref": "d8b3c2cbe638df3c",
                  "label": "De 6 a 7 horas"
                },
                {
                  "id": "YTtT526mOtkU",
                  //"ref": "61be131884c252e6",
                  "label": "De 7 a 8 horas"
                },
                {
                  "id": "LzUgl8isuFcM",
                  //"ref": "c2e7acf6f188d9ed",
                  "label": "Más de 8 horas"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "identidicator": "f.",
            "hideArrowIndentificator": true
          },
          {
            "id": "u8rBuN9GFBAl",
            "title": "¿Usas alguna medicación y/o suplemento para ayudarte a dormir?",
            "ref": `initialForm.questions`,
            "properties": {
              "allow_multiple_selection": false,
              "choices": [
                {
                  "id": "yes",
                  "label": "Si"
                },
                {
                  "id": "no",
                  "label": "No"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "yesOrNo": true,
            "identidicator": "g.",
            "hideArrowIndentificator": true
          },
          {
            "id": "g0xmZHsgLjGh",
            "title": "¿Cuál y en qué dosis?",
            "ref": `initialForm.questions`,
            "validations": {
              "required": false
            },
            "type": "input",
            "identidicator": "h.",
          }
        ]
      },
      "attachment": {
        "type": "video",
        //"src": require('../Images/InitialForm/EstiloDeVida.mp4'),
      },
      "layout": {
        "attachment": {
          "properties": {
            "className": "prefencias-video",
          }
        },
        "pageContent": {
          "className": 'video-statement'
        }
      },
      "type": "statement",
      "group": true,
      "menu": {
        "title": "Tu historia con el ejercicio y/o el entrenamiento.",
        "identidicator": "12"
      },
      "identidicator": "12",
    },
    {
      "id": "LB2QMKD2OrNp",
      "title": "<b>Somos la media de las personas con las que nos rodeamos.</b><br/><br/>¡Vamos a descubrir un poco más de tu entorno para así crear hábitos que vayan en línea con tu vida!",
      "properties": {
        "show_button": false,
        "button_text": "Continuar",
        "fields": [
          {
            "id": "Sdm5MDfdPvaR",
            "title": "<b>¿Quién vive contigo?</b>",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Marca todas las opciones que vayan con tu situación actual.",
              "randomize": false,
              "allow_multiple_selection": true,
              "allow_other_choice": false,
              "vertical_alignment": false,
              "choices": [
                {
                  "id": "sE9i3tRg4Ycb",
                  //"ref": "01e5f8f3a9f5106f",
                  "label": "Vivo solo"
                },
                {
                  "id": "GJO1UaHKNk8H",
                  //"ref": "8c3e85ba25cadad6",
                  "label": "Mi pareja"
                },
                {
                  "id": "pqGdxyVp2sWH",
                  //"ref": "0d8f3ad8d68ce70a",
                  "label": "Compañero(s) de piso"
                },
                {
                  "id": "FpAdE4Wb5DhP",
                  //"ref": "32530ed924231870",
                  "label": "Mascota(s)"
                },
                {
                  "id": "yVgwHY994jhw",
                  //"ref": "6a81b7f32fa53341",
                  "label": "Otro familiar (padre, madre, hermano, primo...)"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "identidicator": "a.",
            "hideArrowIndentificator": true
          },
          {
            "id": "aLDwtXvDCvtf",
            "title": "<b>¿Quién hace la compra en casa?</b>",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Marca todas las opciones que vayan con tu situación actual.",
              "randomize": false,
              "allow_multiple_selection": true,
              "allow_other_choice": false,
              "vertical_alignment": false,
              "choices": [
                {
                  "id": "dIiqiKUWMw6o",
                  //"ref": "508082d3e0d73aa6",
                  "label": "Yo"
                },
                {
                  "id": "Cr0RbDOjy8dH",
                  //"ref": "07fae52401885e79",
                  "label": "Mi pareja"
                },
                {
                  "id": "tYBPJt65Nvg6",
                  //"ref": "a638d220e7bd9d59",
                  "label": "Compañero(s) de piso"
                },
                {
                  "id": "nXvSClS0qZqm",
                  //"ref": "d56e2c0d7ad8e6dd",
                  "label": "Otro familiar (padre, madre, hermano, primo...)"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "identidicator": "b.",
            "hideArrowIndentificator": true
          },
          {
            "id": "FMAl2pi6A2bd",
            "title": "Genial.<br/>Ahora dime, <b>¿quién es el chef de la casa?</b> ",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Marca todas las opciones que vayan con tu situación actual.",
              "randomize": false,
              "allow_multiple_selection": true,
              "allow_other_choice": false,
              "vertical_alignment": false,
              "choices": [
                {
                  "id": "Ipcum6eL3rw6",
                  //"ref": "0c5dc03611ea9f1f",
                  "label": "Soy yo! :-)"
                },
                {
                  "id": "cf6iPPm5xCgv",
                  //"ref": "5f517d851178e6ad",
                  "label": "Mi pareja"
                },
                {
                  "id": "xaDN4rbJNJ71",
                  //"ref": "bb4b57ca0426a9e3",
                  "label": "Compañero(s) de piso"
                },
                {
                  "id": "fhpp6LdpsAOA",
                  //"ref": "f099eab224d3fbf6",
                  "label": "Otro familiar (padre, madre, hermano, primo...)"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "identidicator": "c.",
            "hideArrowIndentificator": true
          },
          {
            "id": "GYLbOUOiaoUQ",
            "title": "¿Tienes hijos?",
            "ref": `initialForm.questions`,
            "properties": {
              "allow_multiple_selection": false,
              "choices": [
                {
                  "id": "yes",
                  "label": "Si"
                },
                {
                  "id": "no",
                  "label": "No"
                }
              ]
            },
            "validations": {
              "required": true
            },
            "type": "multiple_choice",
            "yesOrNo": true,
            "identidicator": "d.",
            "hideArrowIndentificator": true
          },
          {
            "id": "vGfUoUTFxZCh",
            "title": "¿Cómo crees que afecta esto a la comida que tienes en casa, tu entrenamiento, etc?",
            "ref": `initialForm.questions`,
            "validations": {
              "required": false
            },
            "type": "input",
            "identidicator": "e.",
            "hideArrowIndentificator": true
          }
        ]
      },
      "attachment": {
        "type": "video",
        //"src": require('../Images/InitialForm/VideoCoche.mp4'),
      },
      "layout": {
        "attachment": {
          "properties": {
            "className": "prefencias-video",
          }
        },
        "pageContent": {
          "className": 'video-statement'
        }
      },
      "type": "statement",
      "group": true,
      "menu": {
        "title": "<b>Somos la media de las personas con las que nos rodeamos.<b>",
        "identidicator": "13"
      },
      "identidicator": "13",
    },
    {
      "id": "d8FlbrQBAiP2",
      "title": "¡Estamos listos!<br/>Muchas gracias por tus respuestas. <br/><br/><b>¡Esperamos que hayas descubierto algo nuevo/sorprendente sobre ti!</b><br/><br/>Tan solo nos queda recopilar tus medidas y fotos actuales para tenerlo todo.",
      "properties": {
        "hide_marks": true,
        "button_text": "Vamos a ello"
      },
      "layout": {
        "pageContent": {
          "className": 'center-display'
        }
      },
      "type": "statement"
    },
    {
      "id": "TRPjdGyCgOys",
      "title": "Vamos con las medidas primero, ¿recuerdas esa guía sobre cómo tomarte medidas que vimos al principio?<br/>La tienes aquí de nuevo, por si acaso.",
      //"ref": "d180da23-a2b1-4cc8-a6ea-53a10fa18ab2",
      "properties": {
        "description": "Introduce todas las medidas en cm.",
        "show_button": true,
        "hide_marks" : true,
        "button_text": "Continuar",
        "fields": [
          {
            "id": "cFC66g9KrUj8",
            "title": "¿Cuál es tu medida de <b>cuello</b>?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "No necesitas incluir \"cm\", tan solo el número."
            },
            "validations": {
              "required": true
            },
            "type": "number",
            "identidicator": "a.",
            "hideArrowIndentificator": true
          },
          {
            "id": "BPCehBIkq1nh",
            "title": "¿Cuál es tu medida de <b>pecho</b>?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "A la altura de los pezones y alrededor de la espalda por debajo de los brazos"
            },
            "validations": {
              "required": true
            },
            "type": "number",
            "identidicator": "b.",
            "hideArrowIndentificator": true
          },
          {
            "id": "KtPqiaXbFL0e",
            "title": "¿Cuál es tu medida de <b>brazo izquierdo</b>?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Tómate las medidas de ambos brazos por separado, a media altura del bíceps contrayendo éste.<br/>"
            },
            "validations": {
              "required": true
            },
            "type": "number",
            "identidicator": "c.",
            "hideArrowIndentificator": true
          },
          {
            "id": "SltYsNNkPJUe",
            "title": "¿Cuál es tu medida de <b>brazo derecho</b>?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Tómate las medidas de ambos brazos por separado, a media altura del bíceps contrayendo éste.<br/>"
            },
            "validations": {
              "required": true
            },
            "type": "number",
            "identidicator": "d.",
            "hideArrowIndentificator": true
          },
          {
            "id": "g9KYAOQnLIUu",
            "title": "¿Cuál es tu medida <b>tres dedos por encima del ombligo</b>?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Coloca tres dedos por encima de tu ombligo y mide la circunferencia de tu cintura a esa altura.<br/><br/>Aprieta el abdomen como si te fueran a pegar un puñetazo, no \"escondas\" la barriga, simplemente aprieta."
            },
            "validations": {
              "required": true
            },
            "type": "number",
            "identidicator": "e.",
            "hideArrowIndentificator": true
          },
          {
            "id": "XdIFDnW5Tdbq",
            "title": "¿Cuál es tu medida de cintura, <b>en el ombligo</b>?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Coloca la cinta exactamente en tu ombligo y mide la circunferencia de tu cintura a esa altura.<br/><br/>Aprieta el abdomen como si te fueran a pegar un puñetazo, no \"escondas\" la barriga, simplemente aprieta."
            },
            "validations": {
              "required": true
            },
            "type": "number",
            "identidicator": "f.",
            "hideArrowIndentificator": true
          },
          {
            "id": "yNDoplLOYiaE",
            "title": "¿Cuál es tu medida <b>tres dedos por debajo del ombligo</b>?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Coloca tres dedos por encima de tu ombligo y mide la circunferencia de tu cintura a esa altura.<br/><br/>Aprieta el abdomen como si te fueran a pegar un puñetazo, no \"escondas\" la barriga, simplemente aprieta."
            },
            "validations": {
              "required": true
            },
            "type": "number",
            "identidicator": "g.",
            "hideArrowIndentificator": true
          },
          {
            "id": "iqlUkjDZw0vs",
            "title": "¿Cuál es tu medida de <b>cadera</b>?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "A media altura del glúteo."
            },
            "validations": {
              "required": true
            },
            "type": "number",
            "identidicator": "h.",
            "hideArrowIndentificator": true
          },
          {
            "id": "k2tkczvqdlpX",
            "title": "¿Cuál es tu medida de <b>pierna izquierda</b>?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Mide cada pierna por separado, a media altura del muslo."
            },
            "validations": {
              "required": true
            },
            "type": "number",
            "identidicator": "i.",
            "hideArrowIndentificator": true
          },
          {
            "id": "aC8WQq4GIXM7",
            "title": "¿Cuál es tu medida de <b>pierna derecha</b>?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Mide cada pierna por separado, a media altura del muslo."
            },
            "validations": {
              "required": true
            },
            "type": "number",
            "identidicator": "j.",
            "hideArrowIndentificator": true
          },
          {
            "id": "aL8LuXdlA8MY",
            "title": "¿Cuál es tu medida de <b>gemelo izquierdo</b>?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Sí, los gemelos no están en la guía visual, pero nos serán útiles en este punto.<br/><br/>Mide cada pierna por separado, a media altura del gemelo, en la parte más ancha."
            },
            "validations": {
              "required": true
            },
            "type": "number",
            "identidicator": "k.",
            "hideArrowIndentificator": true
          },
          {
            "id": "mygUBckFChue",
            "title": "¿Cuál es tu medida de <b>gemelo derecho</b>?",
            "ref": `initialForm.questions`,
            "properties": {
              "description": "Mide cada pierna por separado, a media altura del muslo, en la parte más ancha."
            },
            "validations": {
              "required": true
            },
            "type": "number",
            "identidicator": "l.",
            "hideArrowIndentificator": true
          },
          {
            "id": "A0r8W8s2ZHpd",
            "title": "¡Gracias por todas las medidas!<br/><br/>Vamos con las fotos. <br/><br/>Por favor, <b>adjunta aquí tu foto de frente.</b>",
            "ref": `initialForm.questions`,
            "validations": {
              "required": true
            },
            "type": "file_upload",
            "identidicator": "m.",
            "hideArrowIndentificator": true
          },
          {
            "id": "fQJBGRlsZnvl",
            "title": "Ahora <b>adjunta tu foto de espaldas.</b>",
            "ref": `initialForm.questions`,
            "validations": {
              "required": true
            },
            "type": "file_upload",
            "identidicator": "n.",
            "hideArrowIndentificator": true
          },
          {
            "id": "DClriwj3KyQt",
            "title": "¡También necesitamos tu perfil! <b>Sube aquí tu perfil izquierdo.</b>",
            "ref": `initialForm.questions`,
            "validations": {
              "required": true
            },
            "type": "file_upload",
            "identidicator": "o.",
            "hideArrowIndentificator": true
          },
          {
            "id": "j38ijKnLsNSx",
            "title": "Y por último, <b>sube aquí tu perfil derecho.</b>",
            "ref": `initialForm.questions`,
            "validations": {
              "required": true
            },
            "type": "file_upload",
            "identidicator": "p.",
            "hideArrowIndentificator": true
          },
          {
            "id": "DLaraJgGmSZW",
            "title": "Mil gracias, {{personalData.nombre}}.<br/><br/>Todas tus medidas y fotos nos serán de gran utilidad para crear el plan y evaluar el progreso durante nuestro trabajo juntos.",
            "ref": `initialForm.questions`,
            "properties": {
              "hide_marks": true,
              "button_text": "Continuar"
            },
            "type": "statement"
          }
        ]
      },
      "layout": {
        "sectionClassName": "cintura-container",
        "topContainer": {
          "className": "cintura-top-container",
          "attachment": {
            "type": "image",
            //"src": require('../Images/InitialForm/QTPnJtPGXWES.png')
          }
        },
      },
      "type": "input",
      "hideInput": true,
      "identidicator": "14",
      "menu": {
        "title": "Vamos con las medidas primero, ¿recuerdas esa guía sobre cómo tomarte medidas que vimos al principio?",
        "identidicator": "14"
      },
      "identidicator": "14"
    },
    {
      "id": "D5uNflxUPF33",
      "title": "<b>Última pregunta, ¿hay alguna condición médica en tu familia?</b>",
      "ref": `initialForm.questions`,
      "properties": {
        "description": "Ejemplo: Diabetes, enfermedades coronarias, historia de infartos, etc.<br/><br/>En caso afirmativo, amplía un poco los detalles.<br/>Esto es más bien una reflexión para ti y el porqué puedes estar haciendo todo esto realmente más que algo que modifique el plan en sí.<br/><br/>En caso negativo simplemente responde \"No, ninguna.\""
      },
      "validations": {
        "required": true
      },
      "type": "long_text",
      "identidicator": "15"
    },
    {
      "id": "WPwATE18TdVe",
      "title": "<b>DESCARGA DE RESPONSABILIDAD MÉDICA</b><br/><br/>Lo que viene a continuación es el \"típico párrafo legal y aburrido\" que es necesario, ya no solo desde un punto de vista de ley, sino por ambas partes como reflexión de que lo que estamos creando es un programa de entrenamiento que, como todo, puede conllevar riesgos.<br/><br/>Para la mayoría, la actividad física no tiene riesgos más allá de los considerados 'normales' y presenta múltiples beneficios.<br/><br/>Aún así, estamos obligados por ley a presentarte estos términos y a recomendarte que consultes con un médico antes de iniciar cualquier programa de entrenamiento para confirmar que no existe ninguna condición médica que pueda complicarse con la actividad física.<br/>",
      "ref": `initialForm.questions`,
      "properties": {
        "description": "Al aceptar declaro que deseo participar en actividades que pueden incluir ejercicio aeróbico, ejercicio con pesas y estiramientos.<br/><br/>Soy consciente de que mi participación en estas actividades puede acarrear un riesgo de lesión e incluso la muerte.<br/><br/>Confirmo que estoy participando de forma voluntaria en este programa y que he recibido/tengo intención de recibir consejo médico y mi médico está de acuerdo con que debería hacer ejercicio.",
        "allow_multiple_selection": false,
        "choices": [
          {
            "id": "yes",
            "label": "Acepto"
          },
          {
            "id": "no",
            "label": "No acepto"
          }
        ],
        "legal":true,
        "button_text": "Enviar",
        "hide_marks": true
      },
      "validations": {
        "required": true
      },
      "type": "multiple_choice",
      "identidicator": "16"

      
    }
  ],
  "logic": [
    {
      "type": "field",
      //"ref": "f5c0c6d0597c0060",
      "actions": [
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "2e6096608e9f68d0"
            }
          },
          "condition": {
            "op": "is",
            "vars": [
              {
                "type": "field",
                "value": "f5c0c6d0597c0060"
              },
              {
                "type": "constant",
                "value": true
              }
            ]
          }
        },
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "f4ffe251b10fa79a"
            }
          },
          "condition": {
            "op": "always",
            "vars": []
          }
        }
      ]
    },
    {
      "type": "field",
      "actions": [
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "0efae59e-166d-4a46-8243-07ee0a084e39"
            }
          },
          "condition": {
            "op": "is_not",
            "vars": [
              {
                "type": "field",
                "value": "777d3759-128a-4b32-963f-4a4fa071a962"
              },
              {
                "type": "constant",
                "value": true
              }
            ]
          }
        },
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "d6a6cef3-2fa9-4c70-ba13-5ee196f5ff0d"
            }
          },
          "condition": {
            "op": "is",
            "vars": [
              {
                "type": "field",
                "value": "777d3759-128a-4b32-963f-4a4fa071a962"
              },
              {
                "type": "constant",
                "value": true
              }
            ]
          }
        }
      ]
    },
    {
      "type": "field",
      //"ref": "20c5a396322ca0be",
      "actions": [
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "e087e7541dc4fc0c"
            }
          },
          "condition": {
            "op": "is",
            "vars": [
              {
                "type": "field",
                "value": "20c5a396322ca0be"
              },
              {
                "type": "constant",
                "value": true
              }
            ]
          }
        },
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "99b6bdac-d75d-4e1f-a60e-166fc32c5476"
            }
          },
          "condition": {
            "op": "always",
            "vars": []
          }
        }
      ]
    },
    {
      "type": "field",
      //"ref": "8800f28b-a999-4cf1-b562-7f07d1a4779d",
      "actions": [
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "0eb90e85-af63-4f82-85fc-ad400248621d"
            }
          },
          "condition": {
            "op": "is_not",
            "vars": [
              {
                "type": "field",
                "value": "8800f28b-a999-4cf1-b562-7f07d1a4779d"
              },
              {
                "type": "choice",
                "value": "39062abf-e40d-4884-b86b-0ada25e67444"
              }
            ]
          }
        },
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "546613fb-fd79-4035-b09e-674564c2719f"
            }
          },
          "condition": {
            "op": "always",
            "vars": []
          }
        }
      ]
    },
    {
      "type": "field",
      //"ref": "d5f069975dd47f3d",
      "actions": [
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "c63fb697-6425-4f0f-b41a-04cf34dc31d6"
            }
          },
          "condition": {
            "op": "is",
            "vars": [
              {
                "type": "field",
                "value": "d5f069975dd47f3d"
              },
              {
                "type": "choice",
                "value": "c2683c0a968b95ab"
              }
            ]
          }
        },
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "a50e2af94e7b4599"
            }
          },
          "condition": {
            "op": "always",
            "vars": []
          }
        }
      ]
    },
    {
      "type": "field",
      //"ref": "eed98471-2ad3-403e-8f0d-8899b7d697b1",
      "actions": [
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "66b5cb72-7d0f-4295-8569-46b4b6c38dd4"
            }
          },
          "condition": {
            "op": "or",
            "vars": [
              {
                "op": "is",
                "vars": [
                  {
                    "type": "field",
                    "value": "eed98471-2ad3-403e-8f0d-8899b7d697b1"
                  },
                  {
                    "type": "choice",
                    "value": "581b4cee-4e37-40a1-b0d9-e59078ed0dee"
                  }
                ]
              },
              {
                "op": "is",
                "vars": [
                  {
                    "type": "field",
                    "value": "eed98471-2ad3-403e-8f0d-8899b7d697b1"
                  },
                  {
                    "type": "choice",
                    "value": "99636746-1e49-45b1-b858-2f5a205e6500"
                  }
                ]
              },
              {
                "op": "is",
                "vars": [
                  {
                    "type": "field",
                    "value": "eed98471-2ad3-403e-8f0d-8899b7d697b1"
                  },
                  {
                    "type": "choice",
                    "value": "b1480ae3-4fcc-4895-934e-a975c5405486"
                  }
                ]
              }
            ]
          }
        },
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "8d874b95-311b-4634-b763-bd0fdfefe9e4"
            }
          },
          "condition": {
            "op": "always",
            "vars": []
          }
        }
      ]
    },
    {
      "type": "field",
      //"ref": "99b6bdac-d75d-4e1f-a60e-166fc32c5476",
      "actions": [
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "02ae95e9-9cfd-45ad-80f6-6c46bde428cc"
            }
          },
          "condition": {
            "op": "equal",
            "vars": [
              {
                "type": "field",
                "value": "99b6bdac-d75d-4e1f-a60e-166fc32c5476"
              },
              {
                "type": "constant",
                "value": "Otro."
              }
            ]
          }
        },
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "eed98471-2ad3-403e-8f0d-8899b7d697b1"
            }
          },
          "condition": {
            "op": "always",
            "vars": []
          }
        }
      ]
    },
    {
      "type": "field",
      //"ref": "01f58a45-aa1e-44df-809b-48372935a903",
      "actions": [
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "d5f069975dd47f3d"
            }
          },
          "condition": {
            "op": "is",
            "vars": [
              {
                "type": "field",
                "value": "01f58a45-aa1e-44df-809b-48372935a903"
              },
              {
                "type": "constant",
                "value": false
              }
            ]
          }
        },
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "12d3a46b-9051-464e-82b3-d45b82bd804d"
            }
          },
          "condition": {
            "op": "is",
            "vars": [
              {
                "type": "field",
                "value": "01f58a45-aa1e-44df-809b-48372935a903"
              },
              {
                "type": "constant",
                "value": true
              }
            ]
          }
        }
      ]
    },
    {
      "type": "field",
      //"ref": "e81d370b-8383-4014-b961-051b57185cca",
      "actions": [
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "453621df-5476-41af-a36c-a86343526219"
            }
          },
          "condition": {
            "op": "is",
            "vars": [
              {
                "type": "field",
                "value": "e81d370b-8383-4014-b961-051b57185cca"
              },
              {
                "type": "constant",
                "value": true
              }
            ]
          }
        },
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "10de26ed37b5a941"
            }
          },
          "condition": {
            "op": "always",
            "vars": []
          }
        }
      ]
    },
    {
      "type": "field",
      //"ref": "bfef5a24-63d4-4469-b42a-1e2f3f0f8d94",
      "actions": [
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "ea9b7e87-09a4-41bb-8350-05805d31d0a4"
            }
          },
          "condition": {
            "op": "is",
            "vars": [
              {
                "type": "field",
                "value": "bfef5a24-63d4-4469-b42a-1e2f3f0f8d94"
              },
              {
                "type": "constant",
                "value": true
              }
            ]
          }
        },
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "5e4885dd-a85c-4d47-86c0-4748368bad20"
            }
          },
          "condition": {
            "op": "always",
            "vars": []
          }
        }
      ]
    },
    {
      "type": "field",
      //"ref": "62565e40-9187-4f2f-8bb6-b7e71581bb89",
      "actions": [
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "b7323202-e86c-4718-85a1-e04676bf8b9e"
            }
          },
          "condition": {
            "op": "is",
            "vars": [
              {
                "type": "field",
                "value": "62565e40-9187-4f2f-8bb6-b7e71581bb89"
              },
              {
                "type": "constant",
                "value": true
              }
            ]
          }
        },
        {
          "action": "jump",
          "details": {
            "to": {
              "type": "field",
              "value": "da9d85e01c67c82e"
            }
          },
          "condition": {
            "op": "always",
            "vars": []
          }
        }
      ]
    }
  ],
  "_links": {
    "display": "https://programadoce.typeform.com/to/aiNPCc"
  }
}