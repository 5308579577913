import { clientsTypes } from '../actions/clientsActions'
import { setItemLocalStorage } from '../../utils/functions'
import { panelsClients } from '../../utils/constans'
import dotProp from 'dot-prop-immutable'
import Client from '../../models/Client'
const breadcrumsInitial = { text: 'Clientes' }

let initialState = {
	clients: null,
	clientSelected: null,
	breadcrums: [breadcrumsInitial],
	panelSelected: panelsClients.LIST, //list | personalData
}

if (typeof Storage !== 'undefined') {
	initialState = {
		...initialState,
		//clientes: JSON.parse(localStorage.getItem('clientes')) ?? initialState.clientes,
		//clientSelected: localStorage.getItem('clientSelected') ??  initialState.clientSelected,
		//panelSelected: localStorage.getItem('panelSelectedClients') ??  initialState.panelSelected
	}
}

const reducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case clientsTypes.SET_PANEL_SELECTED: {
			//setItemLocalStorage('panelSelectedClients', action.payload)
			return { ...state, panelSelected: action.payload }
		}
		case clientsTypes.SET_CLIENTS: {
			//setItemLocalStorage('clientes', JSON.stringify(action.payload))
			return { ...state, clients: action.payload }
		}
		case clientsTypes.SET_CLIENT_SELECTED: {
			const { path, value } = action.payload
			const newClient = path
				? dotProp.set(state.clientSelected, `${path}`, value)
				: value
			return { ...state, clientSelected: new Client(newClient) }
		}
		// case clientsTypes.MODIFY_CLIENT: {
		//   const { path, value } = dotProp.set(state.clientSelected, `${path}`, value)
		//   return { ...state, clientSelected: action.payload}
		// }

		default:
			return state
	}
}

export default reducer
