import React from 'react'

const Input = ({title='', value='', onChange, type='text', placeholder='', hideTitle = false, blocked = false}) => {
  const handleChange = (value) => {
    if(blocked) return false
    onChange(value)
  }
  return(
    <div className="input-crm-component">
      
      {!hideTitle && <div className="title-input">{title}</div>}
      <div className='container-input'>
        <input type={type} value={value} placeholder={placeholder} onChange={e=>handleChange(e.target.value)}/>
      </div>

    </div>
  )
}

export default Input