import { crmTypes } from '../actions/crmActions'
import { setItemLocalStorage } from '../../utils/functions'
import { panelsCrm } from '../../utils/constans' 
let initialState = {
  panelSelected: panelsCrm.DASHBOARD//dashorad, clients
}

if (typeof (Storage) !== 'undefined') {
  initialState = { 
    ...initialState, 
    //panelSelected: localStorage.getItem('panelSelectedCrm') ??  initialState.panelSelected
  }
}

const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case crmTypes.SET_PANEL_SELECTED: {
      //setItemLocalStorage('panelSelectedCrm', action.payload)
      return { ...state, panelSelected: action.payload }
    }
    default:
      return state
  }
}

export default reducer
