import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Popover, Divider } from '@material-ui/core'

import {
	updateTemplate,
	modifyTemplate,
	createTemplate,
	deleteTemplate,
	getTemplates
} from '../../redux/actions/templatesActions'

import {
	FormatBoldRounded,
	FormatItalicRounded,
	FormatUnderlinedRounded,
	FormatListNumberedRounded,
	FormatListBulletedRounded,
	SaveRounded,
	FolderRounded,
	ImageRounded,
	FormatIndentDecreaseRounded,
	FormatIndentIncreaseRounded,
	DeleteRounded
} from '@material-ui/icons'

import Alert from './Alert'
import Input from './Input'

const modules = {
	toolbar: {
		container: "#toolbar",
	},
	clipboard: {
		matchVisual: false,
	}
}

const TEMPLATE_FINAL_FASE = '<p>Hola de nuevo {0},</p><p>&nbsp;</p><p>Hemos visto tus datos. ¡Vamos con nuestro feedback!</p><p>&nbsp;</p><ul><li>Has {1}</li><li>Has {2}</li><li>NEAT: {3}</li></ul><p>&nbsp;</p><p><strong>OPCIÓN 1: Te vamos a ajustar ligeramente los macros, te los dejamos al final de este mensaje.</strong></p><p><strong>OPCIÓN 2: Muy buen trabajo, de momento vamos a mantener los mismos macros.</strong></p>'
const TEMPLATE_FEEDBACK_VARIOS = '<p>Por otro lado, te vamos a dejar debajo algunas partes relevantes de tu feedback en este check-in, y nuestras respuestas al respecto.</p><p>&nbsp;</p><p><strong>¿Ha habido algún ejercicio que te haya dado dolor articular?:</strong>&nbsp;{0}</p><p>&nbsp;</p><p><strong>Respuesta del equipo DOCE:&nbsp;</strong></p><p>&nbsp;</p><p><strong>¿Cuál ha sido tu nivel de adherencia al plan nutricional?:</strong>&nbsp;{1}</p><p>&nbsp;</p><p><strong>Respuesta del equipo DOCE:</strong></p><p>&nbsp;</p><p><strong>¿Por qué te pondrías esa nota?:&nbsp;</strong>{2}</p><p>&nbsp;</p><p><strong>Respuesta del equipo DOCE:</strong></p><p>&nbsp;</p><p><strong>Mini victoria:&nbsp;</strong>{3}</p><p>&nbsp;</p><p><strong>Respuesta del equipo DOCE:</strong></p><p>&nbsp;</p><p><strong>¿Qué crees que podría mejorar?:</strong>&nbsp;{4}</p><p>&nbsp;</p><p><strong>Respuesta del equipo DOCE:&nbsp;</strong></p><p>&nbsp;</p><p><strong>Si tuvieras que resumir esta fase en un ‘tweet’, ¿cómo sería?:&nbsp;</strong>{5}</p><p>&nbsp;</p><p><strong>Respuesta del equipo DOCE:</strong></p><p>&nbsp;</p><p><strong>Notas:&nbsp;</strong>{6}</p><p>&nbsp;</p><p><strong>Respuesta del equipo DOCE:&nbsp;</strong></p>'

const formats = [
	"header",
	"font",
	"size",
	"bold",
	"italic",
	"underline",
	"strike",
	"blockquote",
	"list",
	"bullet",
	"indent",
	"link",
	"image",
	"color"
]

const RichText = ({ value = '', onChange, templates, actions, extraDataTemplate = {} }) => {

	const [text, setText] = useState(value)
	const [createAlert, showCreateAlert] = useState(false)
	const [deleteAlert, setDeleteAlert] = useState(false)
	const [titleNewTemplate, setTitleNewTemplate] = useState('')

	const getStringDiffKg = (diffKg) => {
		if (diffKg === 0) {
			return `mantenido el peso`
		}
		if (diffKg > 0) {
			return `ganado ${diffKg}kg`
		}
		return `perdido ${Math.abs(diffKg)}kg`
	
	}
	const getStringdiffWaist = (diffWaist) => {
		if (diffWaist === 0) {
			return `mantenido la cintura`
		}
		if (diffWaist > 0) {
			return `ganado ${diffWaist}cm de cintura`
		}
		return `perdido ${Math.abs(diffWaist)}cm de cintura`
	}

	const modifyText = (value) => {
		console.log(value)
		if (value === TEMPLATE_FINAL_FASE) {
			value = prepareTemplateFinDeFase(value)
		}

		if (value === TEMPLATE_FEEDBACK_VARIOS) {
			value = prepareTemplateFeedbackVarios(value)
		}

		if ( value === '<p><br></p>') {
			setText('')
			onChange('')
		} else {
			setText(value)
			onChange(value)
		}
	}

	const insertTemplate = function (template) {
		modifyText(`${text}${template.content}`)
	}

	const updateTemplate = (template) => {
		const payload = {
			id: template.idTemplate,
			data: { 
				body: { 
					content: text
				} 
			}
		}
		actions.updateTemplate(payload)
		.then(({ template }) => {
			actions.modifyTemplate(template)
		})
		.catch( error => {
		})
	}

	const createTemplate = (action) => {
		if(action==="cancel"){
			return showCreateAlert(false)
		}
		const data = { 
			body: { 
				title: titleNewTemplate, 
				content: text
			} 
		}
		actions.createTemplate(data)
		.then(({ template }) => {
			actions.modifyTemplate(template)
			showCreateAlert(false)
			titleNewTemplate("")
		})
		.catch( error => {
		})

		
	}

	const deleteTemplate = (action) => {
		if(action==="cancel"){
			return setDeleteAlert(false)
		}
		const payload = {
			id: deleteAlert.idTemplate
		}
		actions.deleteTemplate(payload)
		.then(() => {
			actions.getTemplates()
			setDeleteAlert(false)
		})
		.catch( error => {
		})		
	}

	const prepareTemplateFinDeFase = value => {
		value = value.replace('{0}', extraDataTemplate.name.trim())
		value = value.replace('{1}', getStringDiffKg(extraDataTemplate.diffKg))
		value = value.replace('{2}', getStringdiffWaist(extraDataTemplate.diffWaist))
		value = value.replace('{3}', extraDataTemplate.neat)
		return value
	}

	const prepareTemplateFeedbackVarios = value => {
		value = value.replace('{0}', extraDataTemplate.endPhaseForm.exerciseWaistPainful.trim())
		value = value.replace('{1}', extraDataTemplate.endPhaseForm.levelAdherence.trim())
		value = value.replace('{2}', extraDataTemplate.endPhaseForm.whyThatNote.trim())
		value = value.replace('{3}', extraDataTemplate.endPhaseForm.miniVictory.trim())
		value = value.replace('{4}', extraDataTemplate.endPhaseForm.improvements.trim())
		value = value.replace('{5}', extraDataTemplate.endPhaseForm.tweet.trim())
		value = value.replace('{6}', extraDataTemplate.endPhaseForm.notes.trim())
		return value
	}

	return(
		<>
			<div className="text-editor">
				<ReactQuill value={text} onChange={(text)=>modifyText(text)} modules={modules} formats={formats} />
				<CustomToolbar templates={templates} insertTemplate={insertTemplate} updateTemplate={updateTemplate} showCreateAlert={()=>showCreateAlert(true)} setDeleteAlert={(template)=>setDeleteAlert(template)}/>
			</div>
			{ createAlert && 
				<Alert actionSelected={createTemplate} icon={SaveRounded} text="Introduce un nuevo nombre para tu plantilla" cancel="Cancelar" accept="Guardar">
					<Input hideTitle={true} onChange={value => setTitleNewTemplate(value)} value={titleNewTemplate}/>
				</Alert>
			}

			{ deleteAlert && 
				<Alert 
					actionSelected={deleteTemplate} 
					icon={DeleteRounded} 
					text={`¿Estas seguro que quieres eliminar la tarea <b>${deleteAlert.title}</b>?`} 
					cancel="Cancelar" 
					accept="Eliminar"/>
			}

		</>
	)
}


const CustomToolbar = ({templates, insertTemplate, updateTemplate, showCreateAlert, setDeleteAlert}) => {

	const [anchorTemplates, setAnchorTemplates] = useState(null);
	const openTemplates = Boolean(anchorTemplates);

	const [anchorSaveTemplate, setAnchorSaveTemplate] = useState(null);
	const openSaveTemplate = Boolean(anchorSaveTemplate);

	const [anchorDeleteTemplates, setAnchorDeleteTemplates] = useState(null);
	const openDeleteTemplates = Boolean(anchorDeleteTemplates);

	return(
		<div id="toolbar">
			
			{/* <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
				<option value="1" />
				<option value="2" />
				<option value="3" />
				<option selected />
			</select> */}

			<div className="group-toolbar">

				<div className="btn-rich-text">
					<button className="replace-btn ql-bold" />
					<div className="custom-button">
						<FormatBoldRounded/>
					</div>
				</div>

				<div className="btn-rich-text">
					<button className="replace-btn ql-italic" />
					<div className="custom-button">
						<FormatItalicRounded/>
					</div>
				</div>

				<div className="btn-rich-text">
					<button className="replace-btn ql-underline" />
					<div className="custom-button">
						<FormatUnderlinedRounded/>
					</div>
				</div>

				<div className="btn-rich-text">
					<button className="replace-btn ql-list" value="ordered"/>
					<div className="custom-button">
						<FormatListNumberedRounded/>
					</div>
				</div>

				<div className="btn-rich-text">
					<button className="replace-btn ql-list" value="bullet"/>
					<div className="custom-button">
						<FormatListBulletedRounded/>
					</div>
				</div>

				<div className="btn-rich-text">
					<button className="replace-btn ql-indent" value="-1"/>
					<div className="custom-button">
						<FormatIndentDecreaseRounded/>
					</div>
				</div>

				<div className="btn-rich-text">
					<button className="replace-btn ql-indent" value="+1"/>
					<div className="custom-button">
						<FormatIndentIncreaseRounded/>
					</div>
				</div>
				
				<div className="btn-rich-text">
					<button className="replace-btn ql-image" />
					<div className="custom-button">
						<ImageRounded/>
					</div>
				</div>
				
			</div>

			<div className="group-toolbar">
				<div className="btn-rich-text">
					<div className="custom-button" onClick={ (e) => setAnchorTemplates(e.currentTarget) }>
						<FolderRounded/>
					</div>
					<Popover 
						open={openTemplates} 
						anchorEl={anchorTemplates}
						anchorOrigin={{ vertical: 'top', horizontal: 'right', }} 
						transformOrigin={{ vertical: 'bottom', horizontal: 'right', }}
						onClose={()=>setAnchorTemplates(null)}
						disableRestoreFocus
						//onClick={handlePopoverClose}
					>
						<div className="pop-up-save-template">
							<div className='title'>INSERTAR PLANTILLA</div>
							<div className="content-popup-save-templates">
								{Object.entries(templates).map(([idTemplate, template]) => (
									<div key={idTemplate} className="btn-popup-list" onClick={()=>{insertTemplate(template); setAnchorTemplates(null)}}>{template.title}</div>
								))}
							</div>
						</div>
					</Popover>
				</div>

				<div className="btn-rich-text">
					<div className="custom-button" onClick={(e)=>setAnchorSaveTemplate(e.currentTarget)}>
						<SaveRounded/>
					</div>
					<Popover open={openSaveTemplate} anchorEl={anchorSaveTemplate}
						anchorOrigin={{ vertical: 'top', horizontal: 'right', }} transformOrigin={{ vertical: 'bottom', horizontal: 'right', }}
						onClose={()=>setAnchorSaveTemplate(null)}
						disableRestoreFocus
						//onClick={handlePopoverClose}
					>
						<div className="pop-up-save-template">
							<div className='title'>SOBRESCRIBIR PLANTILLA</div>
							<div className="content-popup-save-templates">
							{Object.entries(templates).map(([idTemplate, template]) => (
								<div key={idTemplate} className="btn-popup-list" onClick={()=>{updateTemplate(template); setAnchorSaveTemplate(null)}}>{template.title}</div>
							))}
							</div>
							<Divider/>
							<div className="content-popup-save-templates">
								<div className="btn-popup-list" onClick={()=>{showCreateAlert(); setAnchorSaveTemplate(null)}}>Guardar como nueva plantilla</div>
							</div>
						</div>
					</Popover>
				</div>

				<div className="btn-rich-text">
					<div className="custom-button" onClick={(e)=>setAnchorDeleteTemplates(e.currentTarget)}>
						<DeleteRounded/>
					</div>
					<Popover open={openDeleteTemplates} anchorEl={anchorDeleteTemplates}
						anchorOrigin={{ vertical: 'top', horizontal: 'right', }} transformOrigin={{ vertical: 'bottom', horizontal: 'right', }}
						onClose={()=>setAnchorDeleteTemplates(null)}
						disableRestoreFocus
						//onClick={handlePopoverClose}
					>
						<div className="pop-up-save-template">
							<div className='title'>ELIMINAR PLANTILLA</div>
							<div className="content-popup-save-templates">
							{Object.entries(templates).map(([idTemplate, template]) => (
								<div key={idTemplate} className="btn-popup-list" onClick={()=>{setDeleteAlert(template); setAnchorDeleteTemplates(null)}}>{template.title}</div>
							))}
							</div>
						</div>
					</Popover>
				</div>

			</div>


		</div>
	)
};

const mapStateToProps = ({templates}) => ({
	templates: templates.templates
});

const matchDispatchToProps = (dispatch) => ({
	actions: bindActionCreators({
		updateTemplate,
		modifyTemplate,
		createTemplate,
		deleteTemplate,
		getTemplates
	}, dispatch)	
})


export default connect(mapStateToProps, matchDispatchToProps)(RichText);