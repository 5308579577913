require('dotenv').config()

export const modulesQuill = {
	toolbar: [
		['bold', 'italic', 'underline', 'strike'],
		[
			{ list: 'ordered' },
			{ list: 'bullet' },
			{ indent: '-1' },
			{ indent: '+1' },
		],
		//[{ header: '1' }, { header: '2' }],
	],
}

export const formatsQuill = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'list',
	'indent',
	'bullet',
	'link',
]

export const panelsCrm = {
	DASHBOARD: 'DASHBOARD',
	CLIENTS: 'CLIENTS',
}

export const panelsClients = {
	LIST: 'LIST',
	INFORMATION: 'INFORMATION',
	FASES: 'FASES',
	INITIAL_FORM: 'INITIAL_FORM',
}

export const panelsDashboard = {
	INDICATORS: 'INDICATORS',
	CLIENTS: 'CLIENTS',
}

export const preguntasCuestionario = {
	general: [
		{
			idPregunta: 1,
			type: 'radiobutton',
			required: true,
			pregunta: '¿Vas a entrenar en un gimnasio o en casa?',
			options: [
				{ text: 'Gimnasio' },
				{ text: 'Casa' },
				{ text: 'Box de crossfit' },
			],
		},

		{
			idPregunta: 2,
			type: 'radiobutton',
			required: true,
			pregunta:
				'¿Ha tenido alguna lesión previa / problemas médicos que puedan tener repercusiones en un programa de ejercicio?',
			options: [{ text: 'Si' }, { text: 'No' }],
		},

		{
			idPregunta: 2.1,
			dependencies: {
				idPregunta: 2,
				ifValue: 'Si',
			},
			required: true,
			pregunta:
				'Por favor, descríbenos tu lesión, cuándo la tuviste, si tienes un alta médica y todos los detalles que puedan ser relevantes',
		},

		{
			idPregunta: 3,
			pregunta:
				'¿Usas o has usado drogas recreacionales, suplementos para mejorar el rendimiento o alguna medicina prescrita por tu médico?',
		},

		{
			idPregunta: 4,
			required: true,
			pregunta:
				'¿Qué le gustaría conseguir trabajando con un entrenador y por qué es importante para usted?',
			type: 'reorder',
			options: [
				{ text: 'Ganar músculo' },
				{ text: 'Perder grasa' },
				{ text: 'Tonificar' },
				{ text: 'Ganar fuerza' },
				{ text: 'Mejorar mis hábitos nutricionales y de ejercicio' },
			],
		},

		{
			idPregunta: 5,
			required: true,
			pregunta:
				'Para ayudarme a conceptualizar sus metas en relación a la composición corporal, ¿hay alguna persona a la que le gustaría parecerse fisicamente (deportista / celebridad)?',
		},

		{
			idPregunta: 6,
			required: true,
			pregunta: '¿Qué está dispuesto a hacer para convertir esto en realidad?',
			type: 'checkbox',
			options: [
				{ text: 'Seguir el plan nutricional al pie de la letra' },
				{
					text:
						'Seguir el plan nutricional pero los fines de semana tener mis caprichos',
				},
				{ text: 'Disminuir mi consumo de alcohol pero no dejar de beber.' },
			],
		},

		{
			idPregunta: 7,
			required: true,
			pregunta:
				'¿Cuánto tiempo está preparado para dedicarle al entrenamiento semanalmente?',
			type: 'radiobutton',
			options: [
				{ text: 'Menos de 3 horas' },
				{ text: 'de 3 a 4 horas' },
				{ text: 'de 4 a 6 horas' },
				{ text: 'Más de 6 horas' },
			],
		},

		{
			idPregunta: 8,
			pregunta:
				'¿Se ve a sí mismo como una persona capaz de ejercer autodisciplina? Escala del 1 al 10',
		},

		{
			idPregunta: 9,
			pregunta:
				'¿Qué vicios cree usted que pueden dificultarle conseguir sus metas o que se lo hayan dificultado en el pasado?',
			type: 'checkbox',
			options: [
				{ text: 'Salir de fiesta' },
				{ text: 'Antojos alimenticios' },
				{ text: 'Eventos laborales' },
				{ text: 'Reuniones familiares y sociales' },
			],
		},

		{
			idPregunta: 10,
			required: true,
			pregunta: 'Cuénteme su historial previo con el ejercicio',
			type: 'radiobutton',
			options: [
				{
					text:
						'Nunca he entrenado en el gimnasio, ni he hecho deporte de forma regular',
				},
				{
					text:
						'Nunca he entrenado en el gimnasio pero he hecho deporte de forma regular',
				},
				{
					text:
						'He entrenado en el gimnasio y he hecho deporte a intervalos (irregular)',
				},
				{
					text: 'Llevo entrenando regularmente en el gimnasio menos de 6 meses',
				},
				{
					text: 'Llevo entrenando regularmente en el gimnasio de 6 a 18 meses',
				},
				{
					text: 'Llevo entrenando regularmente en el gimnasio más de 18 meses',
				},
			],
		},

		{
			idPregunta: 11,
			required: true,
			pregunta:
				'¿Hay algo que físicamente no pueda hacer y que siempre haya querido hacer?',
		},
	],

	nutricion: [
		{
			idPregunta: 12,
			required: true,
			pregunta:
				'¿Tienes alguna intolerancia o alergia a algún alimento o algún requisito nutricional especial de tipo religioso?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }],
		},
		{
			idPregunta: 12.1,
			dependencies: {
				idPregunta: 12,
				ifValue: 'Si',
			},
			required: true,
			pregunta:
				'Por favor, descríbenos tu lesión, cuándo la tuviste, si tienes un alta médica y todos los detalles que puedan ser relevantes',
		},
		{
			idPregunta: 13,
			required: true,
			pregunta: '¿Estás familiarizado con el conteo de Macros?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }],
		},
		{
			idPregunta: 14,
			required: true,
			pregunta:
				'Si tu objetivo es perder peso, contesta esta pregunta (NO OBLIGATORIA) ¿Has perdido peso antes? Si es así, ¿Seguiste alguna dieta específica? ¿Qué hiciste?',
		},
		{
			idPregunta: 15,
			required: true,
			pregunta:
				'Si tu objetivo es subir de peso, contesta esta pregunta (NO OBLIGATORIA) ¿Has subido de peso antes? Si es así, ¿Seguiste alguna dieta específica? ¿Qué hiciste?',
		},
		{
			idPregunta: 16,
			required: true,
			pregunta: '¿Cuántas veces comes al día? Incluyendo snacks / picar.',
			type: 'radiobutton',
			options: [
				{ text: 'Dos comidas al día' },
				{ text: 'Dos comidas y un snack al día' },
				{ text: 'Dos comidas y dos snacks al día' },
				{ text: 'Tres comidas' },
				{ text: 'Tres comidas y un snack' },
				{ text: 'Tres comidas y dos snacks' },
				{ text: 'Cuatro comidas' },
				{ text: 'Cuatro comidas y un snack' },
				{ text: 'Cuatro comidas y dos snacks' },
				{ text: 'Cinco comidas' },
			],
		},
		{
			idPregunta: 17,
			pregunta: '¿Desayunas habitualmente?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }],
		},
		{
			idPregunta: 18,
			pregunta: '¿A qué hora es tu última comida?',
			type: 'radiobutton',
			options: [
				{ text: 'De 20:00 a 21:00' },
				{ text: 'De 21:00 a 22:00' },
				{ text: 'De 22:00 a 23:00' },
				{ text: 'De 23:00 a 00:00' },
				{ text: 'Más tarde de las 00:00' },
			],
		},
		{
			idPregunta: 19,
			pregunta:
				'¿Cómo te sientes de 30 a 90 minutos después de comer muchos carbohidratos? Especialmente productos con gluten / a base de trigo (ej: pasta, cereales etc).',
			type: 'radiobutton',
			options: [
				{ text: 'Somnoliento' },
				{ text: 'Aletargado (me cuesta concentrarme)' },
				{ text: 'Normal' },
				{ text: 'Energizado' },
			],
		},
		{
			idPregunta: 20,
			pregunta: '¿Dirías que te gusta más lo salado o lo dulce?',
			type: 'radiobutton',
			options: [{ text: 'Salado' }, { text: 'Dulce' }, { text: 'Indiferente' }],
		},
		{
			idPregunta: 21,
			pregunta: '¿Te entran antojos?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }],
		},
		{
			idPregunta: 22,
			pregunta: '¿Cocinas tu propia comida a menudo?',
			type: 'radiobutton',
			options: [
				{ text: 'Menos del 50% de mis comidas' },
				{ text: 'Del 50% al 60% de mis comidas' },
				{ text: 'Del 60% al 80% de mis comidas' },
				{ text: 'Más del 80% de mis comidas' },
			],
		},
		{
			idPregunta: 23,
			pregunta: '¿Sueles comer más comida casera o precocinada / de lata?',
			type: 'radiobutton',
			options: [
				{ text: 'Comida casera' },
				{ text: 'Comida precocinada de lata' },
			],
		},
		{
			idPregunta: 24,
			pregunta: '¿Cada cuánto comes en restaurantes o sitios de comida rápida?',
			type: 'radiobutton',
			options: [
				{ text: '1 vez cada dos semanas' },
				{ text: '1 vez a la semana' },
				{ text: 'De 2 a 3 veces por semana' },
				{ text: 'Más de 3 veces por semana' },
			],
		},
		{
			idPregunta: 25,
			pregunta:
				'¿Cuantas tazas de café / té / bebidas energéticas tomas al día?',
			type: 'radiobutton',
			options: [
				{ text: 'Ninguna' },
				{ text: 'Una' },
				{ text: 'Dos' },
				{ text: 'Tres' },
				{ text: 'Más de tres' },
			],
		},
		{
			idPregunta: 26,
			pregunta:
				'¿Estas tazas de de café / té / bebidas energéticas son con leche y/o azúcar?',
			type: 'radiobutton',
			options: [{ text: 'Leche' }, { text: 'Azúcar' }, { text: 'Ambas' }],
		},
		{
			idPregunta: 27,
			pregunta: '¿Bebes alcohol?',
			type: 'radiobutton',
			options: [
				{ text: 'No bebo' },
				{ text: 'Solo vino o cerveza, una o dos por semana' },
				{ text: 'Solo vino o cerveza, tres o más por semana' },
				{ text: 'Dos o tres copas de bebidas “duras” por semana' },
				{
					text:
						'Por supuesto, me gusta el Rock&amp;Roll (cuatro o cinco bebidas “duras” por semana o más)',
				},
			],
		},
		{
			idPregunta: 28,
			pregunta: '¿Cuánta agua bebes al día? Usemos vasos de 250ml',
			type: 'radiobutton',
			options: [
				{ text: 'Cuatro vasos o menos' },
				{ text: 'De cuatro a seis vasos' },
				{ text: 'De seis a ocho' },
				{ text: 'Más de ocho' },
			],
		},
		{
			idPregunta: 29,
			pregunta: '¿Dirías que tu estado emocional afecta tu forma de comer?',
			type: 'checkbox',
			options: [
				{ text: 'Sí, como más' },
				{ text: 'Sí, como menos' },
				{ text: 'Sí, como peor' },
				{ text: 'No' },
			],
		},
		{
			idPregunta: 30,
			pregunta:
				'Por favor explicame como sería un día de comidas habitual para ti',
		},
	],

	varios: [
		{
			idPregunta: 31,
			pregunta: '¿Cada cuánto vas al cuarto de baño?',
			type: 'radiobutton',
			options: [
				{ text: 'Dos o tres veces por semana' },
				{ text: 'De tres a cinco veces por semana' },
				{ text: 'Una vez al día' },
				{ text: 'Dos veces al día' },
				{ text: 'Más de dos veces al día' },
			],
		},
		{
			idPregunta: 32,
			pregunta: '¿A qué te dedicas?',
		},
		{
			idPregunta: 33,
			pregunta: '¿Cuántas horas trabajas al día?',
		},
		{
			idPregunta: 34,
			pregunta: '¿Cuál es tu nivel de actividad física en el trabajo?',
			type: 'radiobutton',
			options: [
				{ text: 'sedentario (trabajo de oficina)' },
				{
					text:
						'medianamente activo (entrenador personal, camarero, guardia jurado que hace rondas, etc.)',
				},
				{ text: 'Muy activo (obrero, jornalero, monitor de spinning, etc.)' },
			],
		},
		{
			idPregunta: 35,
			pregunta: '¿Cómo percibes tu nivel de estrés? (1 es bajo, 5 es alto)',
			type: 'radiobutton',
			options: [
				{ text: '1' },
				{ text: '2' },
				{ text: '3' },
				{ text: '4' },
				{ text: '5' },
			],
		},
		{
			idPregunta: 36,
			pregunta:
				'¿Implica tu trabajo que comas y bebas fuera a menudo (cenas / comidas con clientes, cervezas con colegas de trabajo.. etc)?',
			type: 'radiobutton',
			options: [
				{ text: 'No' },
				{ text: 'Sí, una o dos veces por semana' },
				{ text: 'Más de dos veces por semana' },
			],
		},
		{
			idPregunta: 37,
			pregunta: '¿Tienes más energía por la mañana o por la noche?',
			type: 'radiobutton',
			options: [{ text: 'mañana' }, { text: 'noche' }],
		},
		{
			idPregunta: 38,
			pregunta: '¿Te cuesta desconectar por la noche?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }, { text: 'A veces' }],
		},
		{
			idPregunta: 39,
			pregunta: '¿Te cuesta dormir por la noche?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }, { text: 'A veces' }],
		},
		{
			idPregunta: 40,
			pregunta: '¿Te cuesta levantarte por la mañana?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }, { text: 'A veces' }],
		},
		{
			idPregunta: 41,
			pregunta: '¿Cuántas horas sueles dormir por la noche?',
			type: 'radiobutton',
			options: [
				{ text: 'menos de 6 horas' },
				{ text: 'De 6 a 7 horas' },
				{ text: 'De 7 a 8 horas' },
				{ text: 'Más de 8 horas' },
			],
		},
		{
			idPregunta: 42,
			pregunta: '¿Te vas a la cama más tarde de las 11 de la noche?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }, { text: 'A veces' }],
		},
		{
			idPregunta: 43,
			pregunta: '¿Usas alguna medicación o suplemento para ayudarte a dormir?',
		},
		{
			idPregunta: 44,
			pregunta: '¿Te despiertas antes de las 6 de la mañana?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }, { text: 'A veces' }],
		},
		{
			idPregunta: 45,
			pregunta: '¿Te falta la energía a medio día?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }, { text: 'A veces' }],
		},
		{
			idPregunta: 46,
			pregunta: '¿Estado civil?',
			type: 'radiobutton',
			options: [
				{ text: 'Soltero' },
				{ text: 'Con pareja' },
				{ text: 'Casado' },
			],
		},
		{
			idPregunta: 47,
			pregunta: '¿Tienes hijos?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }],
		},
		{
			idPregunta: 48,
			pregunta: '¿Qué edad tienen tus hijos?',
			type: 'radiobutton',
			options: [
				{ text: 'Menos de tres' },
				{ text: 'De cuatro a 10' },
				{ text: 'De 10 a 14' },
				{ text: 'De 14 a 18' },
				{ text: 'Más de 18' },
			],
		},
	],
}

export const optionsTrainingTypes = [
	'Principiantes (Unisex)',
	'Intermedios / avanzados hombres',
	'Intermedios / avanzados mujeres',
	'Home workout hombres',
	'Home workout mujeres',
	'Rutinas ebook',
	'3W hipertrofia',
	'3W pérdida de grasa',
	'3W fuerza',
	'Programa Anual FMAX',
]

export const optionsTrainingSubTypes = {
	'Principiantes (Unisex)': [
		'Principiantes Fase 1',
		'Principiantes Fase 2',
		'Principiantes Fase 3',
		'Principiantes Fase 4',
		'Principiantes Fase 5',
		'Principiantes Fase 6',
	],
	'Intermedios / avanzados hombres': [
		'Intermedios/avanzados masc Fase 1',
		'Intermedios/avanzados masc Fase 2',
		'Intermedios/avanzados masc Fase 3 (brazo)',
		'Intermedios/avanzados masc Fase 4 (espalda)',
		'Intermedios/avanzados masc Fase 5',
		'Intermedios/avanzados masc Fase 6',
		'Intermedios/avanzados masc Fase 7',
		'Intermedios/avanzados masc Fase 8',
		'Intermedios/avanzados masc Fase 9',
		'Intermedios/avanzados masc Fase 10',
		'Intermedios/avanzados masc Fase 11',
		'Intermedios/avanzados masc Fase 12',
		'Intermedios/avanzados masc Fase 13',
		'Intermedios/avanzados masc Fase 14',
	],
	'Intermedios / avanzados mujeres': [
		'Intermedios/avanzados fem Fase 1',
		'Intermedios/avanzados fem Fase 2',
		'Intermedios/avanzados fem Fase 3',
		'Intermedios/avanzados fem Fase 4',
		'Intermedios/avanzados fem Fase 5',
		'Intermedios/avanzados fem Fase 6',
		'Intermedios/avanzados fem Fase 7',
		'Intermedios/avanzados fem Fase 8',
		'Intermedios/avanzados fem Fase 9',
		'Intermedios/avanzados fem Fase 10',
		'Intermedios/avanzados fem Fase 11',
		'Intermedios/avanzados fem Fase 12',
	],
	'Home workout hombres': [
		'Home workout masc',
		'Home workout masc Fase 2',
		'Home workout masc Fase 3',
		'Home workout masc Fase 4',
		'Home workout masc Fase 5',
		'Home workout masc Fase 6',
		'Home workout masc Fase 7',
	],
	'Home workout mujeres': [
		'Home workout fem',
		'Home workout fem 2',
		'Home workout fem 3',
		'Home workout fem 4',
		'Home workout fem 5',
		'Home workout fem 6',
		'Home workout fem 7',
		'Home workout fem 8',
		'Home workout fem 9',
	],
	'Rutinas ebook': [
		'Rutina 1',
		'Rutina 2',
		'Rutina 3',
		'Rutina 4',
		'Rutina 5',
		'Rutina 6',
		'Rutina 7',
		'Rutina 8',
		'Rutina 9',
		'Rutina 10',
		'Rutina 11',
		'Rutina 12',
	],
	'3W hipertrofia': [
		'Acumulación 1',
		'Intensificación 1',
		'Acumulación 2',
		'Intensificación 2',
	],
	'3W pérdida de grasa': [
		'Acumulación 1',
		'Intensificación 1',
		'Acumulación 2',
		'Intensificación 2',
	],
	'3W fuerza': [
		'Acumulación 1',
		'Intensificación 1',
		'Acumulación 2',
		'Intensificación 2',
		'Acumulación 3',
		'Intensificación 3',
	],
	'Programa Anual FMAX': [
		'Fase 1 - Hipertrofia - Acumulación 1',
    'Fase 2 - Hipertrofia - Intensificación 1',
    'Fase 3 - Hipertrofia - Acumulación 2',
    'Fase 4 - Hipertrofia - Intensificación 2',
    'Fase 5 - Fuerza - Acumulación 1',
    'Fase 6 - Fuerza - Intensificación 1',
    'Fase 7 - Fuerza - Acumulación 2',
    'Fase 8 - Fuerza - Intensificación 2',
	] 
}

export const optionsPlanesNutricionales = ['Plan 1', 'Plan 2', 'Plan 3']

export const optionsValidateInitialForm = [
	'Sin validar',
	'Validado correctamente',
	'Validación errónea',
]

export const optionsMFPInitialForm = ['Si', 'No']

export const estructuraChat = {
	formularioInicial: {},
	fases: {},
	dudas: {},
}

export const VALIDATED_EMPTY = 'VALIDATED_EMPTY'

export const emptyFile = {
	name: '',
	lastModified: 0,
	size: 0,
	type: '',
	data: '',
}

export const emailsIds = {
	nofiticationEmail: 'b12e1e93-93e7-46b3-9f55-64297cc518aa',
	verificatedInitialForm: '82dc9b00-d172-4a44-96b4-6817ffcda195',
	phase1: '612f6fd5-e8c5-4ece-bd66-226d9420ef18',
	newPhase: 'c97f9c5a-ab94-4772-804a-8554335b1a11',
	noTemplates: 'Nueva notificación',
	checkSent: '547e52c3-90f9-4f51-87b7-341144b16cd9',
	endPhaseSent: '8511b545-e75f-418b-aed3-200d13e0f663',
}

export const emailsSubjects = {
	nofiticationEmail: 'Tienes una notificación nueva',
}

// export const SERVER_URL = process.env.REACT_APP_SERVER_URL
export const SERVER_URL = 'https://api.programadoce.com/'

export const CLIENT_STATUS = [
	'Cuenta Cancelada',
	'Cuenta Congelada',
	'Descongelar Cuenta',
]

export const CLIENT_STATUS_API_VALUES = {
	ACCOUNT_CANCELED: 'Cuenta Cancelada',
	'Cuenta Cancelada': 'ACCOUNT_CANCELED',
	ACCOUNT_FROZEN: 'Cuenta Congelada',
	'Cuenta Congelada': 'ACCOUNT_FROZEN',
	ACCOUNT_UNFROZEN: 'Descongelar Cuenta',
	'Descongelar Cuenta': 'ACCOUNT_UNFROZEN',
}

export const MACROS_QUALITY = {
	vb8HlUkfJFRZ: 'Todo registrado y pesado',
	pmkqNaH8HKnr: 'Los fines de semana no registro',
	wXzfdZfr43cr: 'Registro todo pero peso el 80% de mis comidas',
	LFNZY7xszrlD: 'Registro todo pero peso el 60% de mis comidas o menos',
	LFNZY7xszrlz: 'No registro todo'
}