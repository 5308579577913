import React from 'react'
import jp from 'jsonpath'
import { initialForm } from '../utils/initialForm'
import { SERVER_URL } from '../utils/constans'
import moment from 'moment'
import axios from 'axios'
export const setItemLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
}

export const createFile = (attachment) => {
  if(!attachment?.data || !attachment?.type) return false
  const byteCharacters = atob(attachment.data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob( [byteArray], { type: attachment.type });
  return file
}

export const generateFile = async (attachment) => {
  try {
    const { data } = await axios.get(`${SERVER_URL}${attachment.data}`, {responseType: 'blob'})
    return data
  } catch (error) {
    console.log(error);
    return 
  }
  
}

export const isFile = (attachment) => {
  if(!attachment?.data || !attachment?.type) return false
  return true
}

export const createFilePond = (attachment) => {
  const file = {
    //source: createFile(attachment),
    source: generateFile(attachment),
    options: {
      type: 'local',
      load: true,
      file: {
        name: attachment?.name ?? '',
        size: attachment?.size ?? '',
        type: attachment?.type ?? ''
      }
    }
  }
  return file
}

export const getDataInitialForm = ({ id, value, client }) => {
  if(client){
    const ids = jp.query(client, `$..questions["${id}"]`)[0]
    if(Array.isArray(ids)){
      let answer = ""
      ids.forEach( id => {
        answer += jp.query(initialForm, `$..fields..[?(@.id=="${id}")]`)?.[0]?.label + ', '
      })
      answer += ";"
      answer = answer.replace(', ;', "")
      answer = answer.replace(';', "")
      return answer
    }else{
      return ids
    }
  }
  return jp.query(initialForm, `$..fields[?(@.id=="${id}")]`)?.[0]?.[value]?.replace(/<\/?[^>]+(>|$)/g, "");
}

export const convertDataFile = file => {
  return file ? `data:${file.type};base64,${file.data}` : null
}

export const setImagesScroll = (date,images) => images.map( (image, index) => {
  let type = ""
  if(index ===0){
    type = "Frontal"
  }else if(index === 1){
    type = "Espalda"
  }else if(index === 2){
    type = "Lateral Derecho"
  }else if(index === 3){
    type = "Lateral Izquierdo"
  }
  const obj = {
    img: <img src={`${SERVER_URL}${image.data}`} />,
    date: moment(new Date(date)).format("DD/MM/YYYY"),
    type
  }
  return obj
})

export const getDataComparation = ({ phase: currentPhase, client, step }) => {

  let phases = Object.entries(client.phases).map( ([idPhase, phase]) => phase )
  phases.reverse()

  let data = []
  
  phases.forEach((phase, index) => {
    if(phase.phaseNumber <= currentPhase.phaseNumber){
      if(data.length < 3){
        if( !(step === "check" && phase.phaseNumber === currentPhase.phaseNumber) ){
          data.push({
            step: "endPhase",
            startDate: phase?.endPhase?.startDate ? moment(new Date(phase?.endPhase?.startDate)).format("DD/MM/YYYY") : '-',
            weight: phase.endPhase.questions.averageCurrentWeight ?? "-" ,
            waist: phase.endPhase.questions.averageWaistCurrent ?? "-",
            imgFront: phase.endPhase.images.A0r8W8s2ZHpd?.data ?? "-",
            imgBack: phase.endPhase.images.fQJBGRlsZnvl?.data ?? "-",
            imgRight: phase.endPhase.images.DClriwj3KyQt?.data ?? "-",
            imgLeft: phase.endPhase.images.j38ijKnLsNSx?.data ?? "-"
          })
        }
      }
      if(data.length < 3){
        data.push({
          step: "check",
          startDate: phase.check.startDate ? moment(new Date(phase.check.startDate)).format("DD/MM/YYYY") : '-',
          weight: phase.check.questions.averageCurrentWeight ?? "-" ,
          waist: phase.check.questions.averageWaistCurrent ?? "-",
          imgFront: phase.check.images.A0r8W8s2ZHpd?.data ?? "-",
          imgBack: phase.check.images.fQJBGRlsZnvl?.data ?? "-",
          imgRight: phase.check.images.DClriwj3KyQt?.data ?? "-",
          imgLeft: phase.check.images.j38ijKnLsNSx?.data ?? "-"
        })
      }
    }

    

  })

  data.push({
    step: "initialForm",
    startDate: moment(client.initialForm.createdDate).format("DD/MM/YYYY") ?? '-',
    weight: client.initialForm.questions['pgcEflgaqTna'] ?? "-" ,
    waist: client.initialForm.questions['QTPnJtPGXWES'] ?? "-",
    imgFront: client.initialForm.questions['A0r8W8s2ZHpd'].data ?? "-",
    imgBack: client.initialForm.questions['fQJBGRlsZnvl'].data ?? "-",
    imgRight: client.initialForm.questions['DClriwj3KyQt'].data ?? "-",
    imgLeft: client.initialForm.questions['j38ijKnLsNSx'].data ?? "-"
  })
  data.reverse()
  return data
}

export const setImagesComparation = phases =>  {
  let pathFront = [] 
  let pathBack = [] 
  let pathRight = []
  let pathLeft = []
  let images = []
  phases.forEach(phase => {
    pathFront.push({
      url: phase.imgFront,
      date: phase.startDate
    })
    pathBack.push({
      url: phase.imgBack,
      date: phase.startDate
    })
    pathRight.push({
      url: phase.imgRight,
      date: phase.startDate
    })
    pathLeft.push({
      url: phase.imgLeft,
      date: phase.startDate
    })
  });
  pathFront.map(image => {
    images.push({
      img: <img src={`${SERVER_URL}${image.url}`} />,
      date: image.date,
      type: 'Frontal'
  })})
  pathBack.map(image => {
    images.push({
      img: <img src={`${SERVER_URL}${image.url}`} />,
      date: image.date,
      type: 'Espalda'
  })})
  pathRight.map(image => {
    images.push({
      img: <img src={`${SERVER_URL}${image.url}`} />,
      date: image.date,
      type: 'Lateral Derecho'
  })})
  pathLeft.map(image => {
    images.push({
      img: <img src={`${SERVER_URL}${image.url}`} />,
      date: image.date,
      type: 'Lateral Izquierdo'
  })})
  return images
}

export const blobToBase64 = (blob, file) => {
  if(!blob) return ({ ...file, data: "" })
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = function () { 
      var base64String = reader.result;
      let base64 = base64String.substring(base64String.indexOf(',') + 1)
      file = {
        ...file,
        data: base64
      }
      return resolve(file)
    }
  })
}

export const getStringHtml = (text = "") => {
  var elem = document.createElement('span');
  elem.innerHTML = text
  return elem?.textContent?.trim() || elem?.innerText?.trim() || ""
}