import React, { memo } from 'react'
import { UndoRounded, SaveRounded } from '@material-ui/icons'
const UpdateButtons = memo(({undoData, saveData}) => {
  return(
    <div className='settings-panels'>
      <div className='div-undo-icon' onClick={undoData}><UndoRounded/></div>
      <div className='div-save-icon' onClick={saveData}><SaveRounded/></div>
    </div>
  )
})

export default UpdateButtons