import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { KeyboardArrowRightRounded } from '@material-ui/icons'

import { panelsClients } from '../../../../../utils/constans'
import { setClientSelected, setPanelSelected, getPhases } from '../../../../../redux/actions/clientsActions';
import { setNotification, setLoading } from '../../../../../redux/actions/globalActions'


const List = ({clients, clientSelected, actions}) => {

  const selectClient = (idClient) => {
    //pedir sus phases 
    actions.setLoading(true)
    actions.getPhases({id :idClient })
    .then(({ phases })=>{
      actions.setLoading(false)
      actions.setClientSelected({value: {...clients[idClient], phases }})
      actions.setPanelSelected(panelsClients.INITIAL_FORM)
    })
    .catch(error => {
      console.log(error);
      actions.setLoading(false)
      actions.setNotification({ visibility: true, text: "Error al pedir el cliente", status: "close", moment: Date.now() })
    })
  }
  
  return(
    <div className="panel-list-clients">
      <table id='table-clients'>

        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Nombre</th>
            <th>Teléfono móvil</th>
            <th>Email</th>
            <th>Fase actual</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {clients &&
           Object.entries(clients).map(([index, client]) => {
             return (
               <tr key={index} className={`${clientSelected?.idClient === client?.idClient ? 'active-row' : ''}`}>
                 <td></td>
                 <td></td>
                 <td>{client?.personalData?.nombre ?? '-'} {client?.personalData?.apellidos}</td>
                 <td>{client?.personalData?.telefono ?? '-'}</td>
                 <td>{client?.personalData?.email ?? '-'}</td>
                 <td>-</td>
                 <td onClick={() => selectClient(client.idClient)}>
                   <div className='more' >
                    <KeyboardArrowRightRounded />
                  </div>
                </td>
               </tr>
             )
           })
            
          }
        </tbody>

      </table>

    </div>
  )
}

const mapStateToProps = ({clients}) => ({
  clients: clients.clients,
  clientSelected: clients.clientSelected,
});

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setClientSelected,
      setPanelSelected,
      getPhases,
      setLoading,
      setNotification
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(List);