import React, { useState, useRef, useLayoutEffect, memo } from 'react'
import { KeyboardArrowDownRounded } from '@material-ui/icons'

const Select = ({title='', value='', onChange, type='text', placeholder='', options = [], blocked = false}) => {

  const [showMenu, setShowMenu] = useState(false)

  const changeView = (show) => {
    if(blocked) return null
    setShowMenu(show)
  }

  return(

    <div className={`input-crm-component`}>
        <div className='title-input'>{title}:</div>
        <div className={`container-input container-select pr ${blocked?'blocked-input':''}`} onClick={()=>changeView(true)}>
          <input readOnly={true} value={value}/>
          <div className='arrow-select'>
            <KeyboardArrowDownRounded/>
          </div>
          {showMenu && 
            <Options options={options} onClose={()=>setShowMenu(false)} onChange={!blocked ? onChange: null}/>
          }
        </div>
    </div>
  )
}

export default Select

const Options = memo(({ options, onChange, onClose }) => {

  const refElement = useRef()
  const clickOutSide = ({target}) => !refElement.current?.contains(target) && onClose()
  useLayoutEffect(()=>{
    document.addEventListener('mousedown',clickOutSide, false)
    return () => document.removeEventListener('mousedown',clickOutSide, false)
  },[])
  const handleOnChange = (option) => {
    onChange(option)
    onClose()
  }

  return (
    <div className='lista-seleccionable-input' onClick={e=>e.stopPropagation()} ref={refElement}>
      {options.map(( option, id )=>
        <div key={id} onClick={()=>{handleOnChange(option)}}>{option}</div>
      )}
    </div>
  );
})