import React, { useState, useLayoutEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { setCurrentUser } from '../../../redux/actions/userActions'
import { checkPermissions } from './PrivateUtils';
import User from '../../../models/User';
const PrivateRoute = ({ component: Component, currentUser, access, actions, ...rest }) => {
 
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState(false);

  useLayoutEffect(() => {

    const auth = JSON.parse(sessionStorage.getItem?.("auth"))
    if(auth && auth._id){
      setAuth(true)
      return setLoading(false);
    }else{
      return setLoading(false);
    }
  }, [currentUser.isAuthenticated]);

  return (
    <Route
      {...rest}
      render={props =>
        loading ?
          <div>Loading...</div>
        : auth ? //currentUser.isAuthenticated && checkPermissions(currentUser.roles, access) ? 
            <Component {...props} />
          :
            <Redirect to='/login' />
      }
    />
  );
};

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser
});

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setCurrentUser
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(PrivateRoute);