import axios from 'axios'
import Client from '../../models/Client'
const ethosClient = require('doce-client').createClient()

export const clientsTypes = {
	SET_BREADCRUMS: 'CLIENTS_SET_BREADCRUMS',
	SET_PANEL_SELECTED: 'CLIENTS_SET_PANEL_SELECTED',
	SET_CLIENTS: 'CLIENTS_SET_CLIENTS',
	SET_CLIENT_SELECTED: 'CLIENTS_SET_CLIENT',
	SET_DATA_INDICATORS: 'CLIENTS_SET_DATA_INDICATORS',
	MODIFY_CLIENT: 'CLIENTS_MODIFY_CLIENT',
}

export const setBreadcrums = (payload) => ({
	type: clientsTypes.SET_BREADCRUMS,
	payload,
})
export const setPanelSelected = (payload) => ({
	type: clientsTypes.SET_PANEL_SELECTED,
	payload,
})
export const setClients = (payload) => ({
	type: clientsTypes.SET_CLIENTS,
	payload,
})
export const setClientSelected = (payload) => ({
	type: clientsTypes.SET_CLIENT_SELECTED,
	payload,
})
export const modifyClient = (payload) => ({
	type: clientsTypes.MODIFY_CLIENT,
	payload,
})

export const getClients = () => async (dispatch) => {
	try {
		const { body } = await ethosClient.getClients()
		return dispatch(setClients(body.clients))
	} catch (error) {}
}

export const createClient = ({ data }) => async (dispatch) => {
	try {
		const { body } = await ethosClient.createClient({ data })
		return body
	} catch (error) {}
}

export const getIndicators = () => async (dispatch) => {
	try {
		const { body } = await ethosClient.getIndicators()
		return body
	} catch (error) {}
}

export const updateClient = ({ id, data }) => async (dispatch) => {
	try {
		const { body } = await ethosClient.updateClient({ id, data })
		return body
	} catch (error) {}
}

export const getPhases = ({ id }) => async (dispatch) => {
	try {
		const { body } = await ethosClient.getPhases({ id })
		return body
	} catch (error) {}
}

export const getHistories = ({ id }) => async (dispatch) => {
	try {
		const { body } = await ethosClient.getHistories({ id })
		return body
	} catch (error) {}
}
