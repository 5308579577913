import React, {useEffect} from 'react'
import { FormattedMessage } from 'react-intl';
import { BlockRounded } from '@material-ui/icons';
import axios from 'axios'

const Forbidden = () => {

  useEffect(()=>{
    console.log("Forbidden!!!!!!!!!")
  },[])

  return (
    <div className='error-pages'>
      <div className='error-icon'> <BlockRounded/> </div>
      <div className='error-message'>
        <h1> <FormattedMessage id='forbidden.title' /> </h1>
        <p> <FormattedMessage id='forbidden.subtitle' /> </p>
        <p> <FormattedMessage id='forbidden.message' /> </p>
      </div>
    </div>
  );

}

export default Forbidden
