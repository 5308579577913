const ethosClient = require('doce-client').createClient()


export const createPhase = ({ id }) => async (dispatch) => {
  try {    
    const { body } = await ethosClient.createPhase({ id })
    return body
  } catch (error) {
    
  }
}

export const updatePhase = ({ id, data }) => async (dispatch) => {
  try {
    const { body } = await ethosClient.updatePhase({ id, data })
    return body
  } catch (error) {
    console.log(error);
  }
}
