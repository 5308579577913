import { dashboardTypes } from '../actions/dashboardActions'
import { setItemLocalStorage } from '../../utils/functions'
import { panelsDashboard } from '../../utils/constans'

const dashboardBreadcrumsInitial = { text: "Dashboard" }

let initialState = {
  panelSelected: panelsDashboard.INDICATORS,
  indicatorsDashboard: {
  
    situacionGeneral: {
      text: "Situación general",
      indicators: {
        pagosAlCorriente:{
          keyIndicator: 'pagosAlCorriente',
          text: "Pagos al corriente",
          data: [],
        },
        congelaciones:{
          keyIndicator: "congelaciones",
          text: "Congelaciones",
          data: [],
        },
        cuentasCanceladas:{
          keyIndicator: "cuentasCanceladas",
          text: "Cuentas canceladas",
          data: [],
        },
      },
    },

    gestionesPendientes: {
      text: "Gestiones pendientes",
      indicators:{
        finesDeFase:{
          keyIndicator: "finesDeFase",
          text: "Fines de fase",
          data: [],
        },
        updatesDosSemanas:{
          keyIndicator: "updatesDosSemanas",
          text: "Updates de 2 semanas",
          data: [],
        },
        esperandoEntranamiento: {
          keyIndicator: "esperandoEntranamiento",
          text: "Esperando Entrenamiento",
          value: [],
        },
        esperandoFeedback: {
          keyIndicator: "esperandoFeedback",
          text: "Esperando Feedback",
          value: [],
        },
        mensajesDudas: {
          keyIndicator: "mensajesDudas",
          text: "Mensajes/dudas",
          data: [],
        },
        nuevasAltas: {
          keyIndicator: "nuevasAltas",
          text: "Altas nuevas",
          data: [],
        },
        cuestionariosIniciales: {
          keyIndicator: "cuestionariosIniciales",
          text: "Cuestionarios iniciales",
          data: [],
        }
      }
    },

    facturacion: {
      text: "Facturación",
      indicators: {
        nuevosPedidos: {
          keyIndicator: "nuevosPedidos",
          text: "Nuevos pedidos DOCE",
          data: [],
        },
        renovaciones: {
          keyIndicator: "renovaciones",
          text: "Renovaciones DOCE",
          data: [],
        },
        ingresosUltimoMes: {
          keyIndicator: "ingresosUltimoMes",
          text: "Ingresos último mes",
          data: [],
        }
      }
    }

  },
  
  breadcrums: [dashboardBreadcrumsInitial],
}

if (typeof (Storage) !== 'undefined') {
  initialState = { 
    ...initialState, 
  }
}

const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case dashboardTypes.SET_BREADCRUMS: {
      const { keyPanel, newPanel } = action.payload
      let newBreadcrums = []

      state.breadcrums.some( (panel, key) => {
        if (key<keyPanel) {
          newBreadcrums.push(panel)
        } else if (key===keyPanel) {
          newBreadcrums.push(newPanel)
          return true
        }
        return false
      })

      if (newBreadcrums.length === 1) {
        newBreadcrums.push(newPanel)
      }

      setItemLocalStorage('breadcrumsDashboard', action.payload)
      return { ...state, breadcrums: newBreadcrums }
    }
      
    case dashboardTypes.SET_INDICATORS: {
      return { 
        ...state, 
        indicatorsDashboard: {
          
          situacionGeneral: {
            ...state.indicatorsDashboard.situacionGeneral,
            indicators: {
              ...state.indicatorsDashboard.situacionGeneral.indicators,
              congelaciones: {
                ...state.indicatorsDashboard.situacionGeneral.indicators.congelaciones,
                data: action.payload.generalStatus.accountFrozen,
              },
              cuentasCanceladas: {
                ...state.indicatorsDashboard.situacionGeneral.indicators.cuentasCanceladas,
                data: action.payload.generalStatus.accountCanceled,
              },
              pagosAlCorriente: {
                ...state.indicatorsDashboard.situacionGeneral.indicators.pagosAlCorriente,
                data: action.payload.generalStatus.correctPayments,
              },
            }
          },
          
          gestionesPendientes: {
            ...state.indicatorsDashboard.gestionesPendientes,
            indicators: {
              ...state.indicatorsDashboard.gestionesPendientes.indicators,
              nuevasAltas: {
                ...state.indicatorsDashboard.gestionesPendientes.indicators.nuevasAltas,
                data: action.payload.gestionesPendientes.nuevasAltas,
              },
              cuestionariosIniciales: {
                ...state.indicatorsDashboard.gestionesPendientes.indicators.cuestionariosIniciales,
                data: action.payload.gestionesPendientes.cuestionariosIniciales,
              },
              updatesDosSemanas: {
                ...state.indicatorsDashboard.gestionesPendientes.indicators.updatesDosSemanas,
                data: action.payload.gestionesPendientes.updatesDosSemanas,
              },
              finesDeFase: {
                ...state.indicatorsDashboard.gestionesPendientes.indicators.finesDeFase,
                data: action.payload.gestionesPendientes.finesDeFase,
              },
              mensajesDudas: {
                ...state.indicatorsDashboard.gestionesPendientes.indicators.mensajesDudas,
                data: action.payload.gestionesPendientes.mensajesDudas,
              },
              esperandoEntranamiento: {
                ...state.indicatorsDashboard.gestionesPendientes.indicators.esperandoEntranamiento,
                data: action.payload.gestionesPendientes.esperandoEntranamiento,
              },
              esperandoFeedback: {
                ...state.indicatorsDashboard.gestionesPendientes.indicators.esperandoFeedback,
                data: action.payload.gestionesPendientes.esperandoFeedback,
              }
            }
          }
        } 
      }
    }

    case dashboardTypes.SET_PANEL_SELECTED: {
      return { ...state, panelSelected: action.payload }
    }
    

    default:
      return state
  }
}

export default reducer

