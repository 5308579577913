import React, { useState, useEffect, memo } from 'react'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEqual } from 'underscore'
import Input from '../../../../Shared/Input'
import Select from '../../../../Shared/Select'
import UpdateButtons from '../../../../Shared/UpdateButtons'
import { optionsValidateInitialForm, optionsTrainingTypes, optionsTrainingSubTypes, emptyFile, emailsIds, emailsSubjects } from '../../../../../utils/constans'
import { createFile, createFilePond, isFile, getDataInitialForm, setImagesScroll, generateFile } from '../../../../../utils/functions';
import { updateClient, setClientSelected } from '../../../../../redux/actions/clientsActions'
import { setNotification, setLoading } from '../../../../../redux/actions/globalActions'
import RichText from '../../../../Shared/RichText';
import FormRichText from '../../../../Shared/FormRichText'
import { OpenInNewRounded, DoneRounded, SendRounded } from '@material-ui/icons'
import { initialForm } from '../../../../../utils/initialForm'
import ImagesComparation from '../../../../Shared/ImagesComparation'
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import 'filepond/dist/filepond.min.css';
import History from '../../../../../models/History'

registerPlugin(FilePondPluginFileEncode);

const ImportantData = (props) => {

  const [nutritionalPlanTrainingFiles, setNutritionalPlanTrainingFiles] = useState([])
  const [nutritionalPlanTraining, setNutritionalPlanTraining] = useState(props.nutritionalPlanTraining)
  const [trainingNeat, setTrainingNeat] = useState(props.trainingNeat)
  const [nutritionalPlanRest, setNutritionalPlanRest] = useState(props.nutritionalPlanRest)
  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})

  useEffect(() => {
    setState({ ...props })
    setInitialFiles()
  }, [props])

  useEffect(()=> { 
    setEdited(
      !isEqual(state, props)
      || !isEqual(nutritionalPlanTraining, props.nutritionalPlanTraining)
      || !isEqual(trainingNeat, props.trainingNeat)
      || !isEqual(nutritionalPlanRest, props.nutritionalPlanRest)
    )
  }, [state, nutritionalPlanTraining, trainingNeat, nutritionalPlanRest])

  const setInitialFiles = () => {
    setNutritionalPlanTrainingFiles(props?.nutritionalPlanTraining?.size > 0 ? [createFilePond(props.nutritionalPlanTraining)] : [])
    setNutritionalPlanTraining(props.nutritionalPlanTraining)
    setTrainingNeat(props.trainingNeat)
    setNutritionalPlanRest(props.nutritionalPlanRest)
  }

  const saveData = () => {

    if(state.validate === optionsValidateInitialForm[2] && !state.wrongValidation){
      console.log("debes introducir una explicacion de por que es erronea");
      return null
    }

    let multiPathPhase = {}, multiPathClient = {}, multiPathHistory = []

    if(props.validate !== state.validate) {
      multiPathClient["initialForm.validate"] = state.validate

      if(state.validate === optionsValidateInitialForm[1]){
        multiPathClient["status"] = "INITIAL_FORM_VALIDATED"
        multiPathHistory.push(new History({
          idClient: props.clientSelected.idClient,
          category: "INITIAL_FORM",
          from: "EMPLOYEE",
          to: "CLIENT",
          createdBy: "SYSTEM",
          metadata: {
            idTemplate: emailsIds.verificatedInitialForm,
            subject: "Formulario inicial validado",
            content: props?.templates?.[emailsIds.verificatedInitialForm]?.content?.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates ,
            notification: true,
            hiddenResponse: true,
            sent:{
              email: props.clientSelected.personalData.email,
              msg: props?.templates?.[emailsIds.nofiticationEmail]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates
            }
          }
        }))
      } else if(state.validate === optionsValidateInitialForm[2]) {
        multiPathClient["status"] = "INITIAL_FORM_WRONG_VALIDATED"
        multiPathHistory.push(new History({
          idClient: props.clientSelected.idClient,
          category: "INITIAL_FORM",
          from: "EMPLOYEE",
          to: "CLIENT",
          createdBy: "SYSTEM",
          metadata: {
            subject: "Errores en el formulario inicial",
            content: state?.wrongValidation.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates,
            notification: true,
            //hiddenOkButton: true,
            hiddenResponse: true,
            visibleFaq: true,
            deleteAfterForm: true,
            button:{
              text: "Formulario inicial",
              action: "showInitialForm",
            },
            sent:{
              email: props.clientSelected.personalData.email,
              msg: props?.templates?.[emailsIds.nofiticationEmail]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates
            }
          }
        }))
      }
    }
    if(props.wrongValidation!==state.wrongValidation) multiPathClient["initialForm.wrongValidation"] = state.wrongValidation

    if(props.trainingType!==state.trainingType) multiPathPhase["training.trainingType"] = state.trainingType
    if(props.trainingSubType!==state.trainingSubType) multiPathPhase["training.trainingSubType"] = state.trainingSubType
    if(props.trainingNeat!==state.trainingNeat) multiPathPhase["training.trainingNeat"] = state.trainingNeat

    if (!isEqual(nutritionalPlanTraining, props.nutritionalPlanTraining)) {
      multiPathPhase['training.nutritionalPlans.nutritionalPlanTraining'] = nutritionalPlanTraining ?? emptyFile
    }

    if (!isEqual(nutritionalPlanRest, props.nutritionalPlanRest)) {
      multiPathPhase['training.nutritionalPlans.nutritionalPlanRest'] = nutritionalPlanRest ?? emptyFile
    }

    const payload = {
      id: props.idClient,
      data: { 
        body: {
          ...multiPathClient,
          phaseNumber: 1,
          idPhase: Object.values(props.clientSelected?.phases)?.find(phase => phase.phaseNumber === 1)?.idPhase ?? null, 
          phase: Object.keys(multiPathPhase).length > 0 ? multiPathPhase : null,
          history: Object.keys(multiPathHistory).length > 0 ? multiPathHistory : null,
        } 
      }
    }
  
    props.actions.setLoading(true)
    props.actions.updateClient(payload)
    .then( ({ client, phase }) => {
      let newClient = { ...props.clientSelected, ...client }
      
      if(phase){
        newClient = { ...newClient, phases: { ...props.clientSelected.phases, [phase.idPhase] : phase } }
        setNutritionalPlanTrainingFiles([])
        setNutritionalPlanTraining(phase?.training?.nutritionalPlans?.nutritionalPlanTraining ?? emptyFile)
        setTrainingNeat(phase?.training?.trainingNeat ?? emptyFile)
        setNutritionalPlanRest(phase?.training?.nutritionalPlans?.nutritionalPlanRest ?? emptyFile)
      }
      if(client) props.actions.setClientSelected({value: newClient})
      props.actions.setNotification({ visibility: true, text: "Se ha guardado correctamente", status: "done", moment: Date.now() })
      props.actions.setLoading(false)
    })
    .catch( error => {
      console.log(error);
      props.actions.setNotification({ visibility: true, text: "Ha ocurrido un error", status: "close", moment: Date.now() })
      props.actions.setLoading(false)
    })

  }

  const setFile = (data, id) => {
    if(data && !data.getFileEncodeBase64String()) return null
    const file = {
      name: data?.file?.name ?? '',
      lastModified: data?.file?.lastModified ?? 0,
      size: data?.file?.size ?? 0,
      type: data?.file?.type ?? '',
      data: data?.getFileEncodeBase64String() ?? ''
    }
    if(id === 'nutritionalPlanTraining'){
      setNutritionalPlanTrainingFiles(!data ? [] : [data])
      setNutritionalPlanTraining(data ? file : emptyFile)
    }else if(id === 'nutritionalPlanRest'){
      setNutritionalPlanRest(data ? file : emptyFile)
    }
  }

  const openFile = async (id) => {
    const blob = await generateFile(state[id])
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  }

  const undoData = () => {
    setState({...props})
    setNutritionalPlanTraining(props.nutritionalPlanTraining)
    setTrainingNeat(props.trainingNeat)
    setNutritionalPlanRest(props.nutritionalPlanRest)
    setInitialFiles()
  }

  const onChangeTraining = trainingType => {
    if(trainingType === state.trainingType) return false
    setState({ ...state, trainingType, trainingSubType: "" })
  }

  const assignPhase = () => {
    let multiPathClient = {}, multiPathPhase = {}, multiPathHistory = []
    //cambios en el CLIENT
    multiPathClient["currentPhase.idPhase"] = props.idPhase
    multiPathClient["currentPhase.phaseNumber"] = 1
    multiPathClient["currentPhase.step"] = "training"
    //Cambios en la PHASE
    multiPathPhase["training.startDate"] = "date:now"
    //Cambios en el HISTORY
    multiPathHistory.push(new History({
      idClient: props.clientSelected.idClient,
      category: "PHASE_1",
      from: "EMPLOYEE",
      to: "CLIENT",
      createdBy: "SYSTEM",
      metadata: {
        idTemplate: "528c6498-0b75-4893-b6a5-ee1dff0c8b76",
        subject: "Ya tienes tu nueva fase disponible",
        content: props?.templates?.[emailsIds.phase1]?.content
          .replace(/<p>/g, '<div>')
          .replace(/<\/p>/g, '</div>')
          .replace('{neat}', props.trainingNeat) ?? emailsIds.noTemplates,
        notification: true,
        hiddenResponse: true,
        sent:{
          email: props.clientSelected.personalData.email,
          msg: props?.templates?.[emailsIds.nofiticationEmail]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates
        }
      }
    }))

    const payload = {
      id: props.idClient,
      data: { 
        body: {
          ...multiPathClient,
          phase: multiPathPhase,
          phaseNumber: 1,
          idPhase: Object.values(props.clientSelected?.phases)?.find(phase => phase.phaseNumber === 1)?.idPhase ?? null, 
          history: multiPathHistory
        } 
      }
    }
    props.actions.setLoading(true)
    props.actions.updateClient(payload)
    .then( ({ client, phase }) => {
      let newClient = { ...props.clientSelected, ...client }
      if(phase){
        newClient = { ...newClient, phases: { ...props.clientSelected.phases, [phase.idPhase] : phase } }
        setNutritionalPlanTrainingFiles([])
        setNutritionalPlanTraining(phase.training.nutritionalPlans.nutritionalPlanTraining)
        setTrainingNeat(phase.training.nutritionalPlans.trainingNeat)
        setNutritionalPlanRest(phase.training.nutritionalPlans.nutritionalPlanRest)
      }
      if(client) props.actions.setClientSelected({value: newClient})
      props.actions.setNotification({ visibility: true, text: "Se ha enviado correctamente", status: "done", moment: Date.now() })
      props.actions.setLoading(false)
    })
  }

  return(
    <div className="container-with-dependency">
      <div className="container-dependency-parent">
        <div className='sub-container-informacion employee-mode botton-position'>
          { edited && !props.block && 
            <UpdateButtons saveData={saveData} undoData={() => undoData()} />
          }
          <p className='title-informacion'>Validación</p>
          <div className="inputs-container just-one">
            <Select title="Validar cuestionario" value={state.validate} options={optionsValidateInitialForm} onChange={validate => setState({ ...state, validate })}/>
          </div>
          {state.validate===optionsValidateInitialForm[2] &&
            <div className="inputs-container">
              <FormRichText title="Detalla la validación errónea:" value={state.wrongValidation} onChange={wrongValidation => setState({ ...state, wrongValidation })}/>
            </div>
          }
          {props.validate===optionsValidateInitialForm[1] && state.validate===optionsValidateInitialForm[1] &&
            <>
              <br/>
              <p className='title-informacion'>Fase nueva</p>
              <div className="inputs-container">
                <Select
                  title="Tipo de entrenamiento"
                  value={state.trainingType}
                  options={optionsTrainingTypes}
                  onChange={trainingType => onChangeTraining(trainingType)}
                />
                <Select
                  blocked={!optionsTrainingSubTypes?.[state?.trainingType]}
                  title="Número de Fase"
                  value={state.trainingSubType}
                  options={optionsTrainingSubTypes?.[state?.trainingType] ?? []}
                  onChange={trainingSubType => setState({ ...state, trainingSubType })}
                />
              </div>
              <div className="inputs-container">
                <div className={`input-crm-component`}>
                  <div class="title-input">Plan Nutricional Dia de Entrenamiento:</div>
                  <div
                    className="container-filepond-download-file"
                    style={{ marginBottom: '15px' }}
                  >
                    {/* <FilePond id={'a'} files={state?.nutritionalPlanTraining?.size > 0 ? [()=>createFilePond(state.nutritionalPlanTraining)] : []} labelIdle='Selecciona un fichero' onupdatefiles={ ([file]) => setFile(file, "nutritionalPlanTraining")}/> */}
                    <FilePond id={'a'} files={nutritionalPlanTrainingFiles} labelIdle='Selecciona un fichero' onupdatefiles={ ([file]) => setFile(file, "nutritionalPlanTraining")}/>
                    {isEqual(props.nutritionalPlanTraining, nutritionalPlanTraining) && isFile(nutritionalPlanTraining) && 
                      <div className="container-icon-new-window">
                        <div onClick={() => openFile('nutritionalPlanTraining')}><OpenInNewRounded/></div>
                      </div>
                    }
                  </div>
                </div>
                <Input
                  title="NEAT:"
                  value={state.trainingNeat}
                  onChange={trainingNeat => setState({ ...state, trainingNeat })}
                />
              </div>

              { !props.phase?.training?.startDate && 
                props.trainingType && 
                props.trainingSubType && 
                <div className="display-flex-center margin-top-35" >
                  <div className="btn-confirm-phase" onClick={assignPhase}>
                    <div>Enviar fase</div>
                    <div className="icon-btn"><SendRounded /></div>
                  </div>
                </div>
              }
              { props.phase?.training?.startDate &&
                <div className="display-flex-center margin-top-35 sent-btn">
                  <div className="btn-confirm-phase">
                    <div>Fase enviada</div>
                    <div className="icon-btn"><DoneRounded /></div>
                  </div>
                </div>              
              }
            </>
          }
        </div>
      </div>
      <ClientPanel title={props.title} client={props.clientSelected} />
    </div>
  )
}

const mapStateToProps = ({clients, templates}) => ({
  clientSelected: clients.clientSelected,
  templates: templates.templates
});

const matchDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    updateClient,
    setClientSelected,
    setNotification,
    setLoading
  }, dispatch)	
})

export default connect(mapStateToProps, matchDispatchToProps)(ImportantData);

const ClientPanel = memo(({ title, client }) => {
  return(
    <div className='sub-container-informacion client-mode'>

      <p className='title-informacion'>{title}</p>

      <div className="inputs-container">
        <Input title={"Nombre"} value={ client?.personalData.nombre } blocked={true} />
        <Input title={"Apellidos"} value={ client?.personalData.apellidos } blocked={true} />
      </div>

      <div className="inputs-container">
        <Input title={"Email"} value={client?.personalData.email} blocked={true} />
        <Input title={"Teléfono"} value={client?.personalData.telefono} blocked={true} />
      </div>

      <div className="inputs-container">
        <Input title={"Altura"} value={`${client?.initialForm?.questions?.['UeWRML5xeKni'] ? client?.initialForm?.questions?.['UeWRML5xeKni'] + " cm" : ""}`} blocked={true} />
        <Input title={"Peso"} value={`${client?.initialForm?.questions?.['pgcEflgaqTna'] ? client?.initialForm?.questions?.['pgcEflgaqTna'] + " kg" : ""}`} blocked={true} />
      </div>

      <div className="inputs-container">
        <Input title={"Cintura"} value={`${client?.initialForm?.questions?.['QTPnJtPGXWES'] ? client?.initialForm?.questions?.['QTPnJtPGXWES'] + " cm" : ""}`} blocked={true} />
        <Input title={getDataInitialForm({ id: "nz8tIQ2EnI77", value:'title'})} value={getDataInitialForm({ id: "nz8tIQ2EnI77", client })} blocked={true} />
      </div>
      <div className="inputs-container">
        <Input title={"¿Cuál es tu objetivo y por qué es importante para ti?"} value={`${client?.initialForm?.questions?.['oicOk66uYPBM'] ? client?.initialForm?.questions?.['oicOk66uYPBM'] + " cm" : ""}`} blocked={true} />
      </div>

      <div className="inputs-container">
        <Input title={getDataInitialForm({ id: "yY7utz84toGe", value:'title'})} value={getDataInitialForm({ id: "yY7utz84toGe", client })} blocked={true} />
        <Input title={getDataInitialForm({ id: "q2wVZ4lDYK7F", value:'title'})} value={getDataInitialForm({ id: "q2wVZ4lDYK7F", client })} blocked={true} />
      </div>

      <div className="inputs-container">
        <Input title={getDataInitialForm({ id: "gYrMOExSQqIC", value:'title'})} value={getDataInitialForm({ id: "gYrMOExSQqIC", client })} blocked={true} />
        <Input title={getDataInitialForm({ id: "NMX6GijzyKcL", value:'title'})} value={getDataInitialForm({ id: "NMX6GijzyKcL", client })} blocked={true} />
      </div>

      <div className="inputs-container">
        <Input title={getDataInitialForm({ id: "G1gueZJZksM8", value:'title'})} value={getDataInitialForm({ id: "G1gueZJZksM8", client })} blocked={true} />
        <Input title={getDataInitialForm({ id: "nsK3lDEDwPVq", value:'title'})} value={getDataInitialForm({ id: "nsK3lDEDwPVq", client })} blocked={true} />
      </div>

      <div className="inputs-container">
        <Input title={getDataInitialForm({ id: "ZQqr0x4In82j", value:'title'})} value={getDataInitialForm({ id: "ZQqr0x4In82j", client })} blocked={true} />
        <Input title={getDataInitialForm({ id: "A9XVlcIWM8ae", value:'title'})} value={getDataInitialForm({ id: "A9XVlcIWM8ae", client })} blocked={true} />
      </div>

      <div className="inputs-container">
        <Input title={getDataInitialForm({ id: "eWGJ3iA8Kriu", value:'title'})} value={getDataInitialForm({ id: "eWGJ3iA8Kriu", client })} blocked={true} />
        <Input title={getDataInitialForm({ id: "UUbjFLnSmx4b", value:'title'})} value={getDataInitialForm({ id: "UUbjFLnSmx4b", client })} blocked={true} />
      </div>

      <div className="inputs-container">
        <Input title={getDataInitialForm({ id: "fy65rYUnke9V", value:'title'})} value={getDataInitialForm({ id: "fy65rYUnke9V", client })} blocked={true} />
        <Input title={getDataInitialForm({ id: "FWtAUOu3bafX", value:'title'})} value={getDataInitialForm({ id: "FWtAUOu3bafX", client })} blocked={true} />
      </div>

      <ImagesComparation
        images={setImagesScroll(client.initialForm.createdDate, [
          getDataInitialForm({ id: "A0r8W8s2ZHpd", client }),
          getDataInitialForm({ id: "fQJBGRlsZnvl", client }),
          getDataInitialForm({ id: "DClriwj3KyQt", client }),
          getDataInitialForm({ id: "j38ijKnLsNSx", client })
        ])}
      />
      
    </div>

  )
})
