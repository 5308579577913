import React from 'react'
import { connect } from 'react-redux';
import Input from '../../../../Shared/Input'
import { getDataInitialForm } from '../../../../../utils/functions';

const EntireForm = ({title, client}) => {

  return(
    <div className="container-with-dependency">

      <div className='sub-container-informacion client-mode'>

        <p className='title-informacion'>{title}</p>

        <div className="inputs-container">
          <Input title={'Fecha de nacimiento'} value={getDataInitialForm({ id: 'pBFjoOOwnLDK', client})} onChange={null}/>
          <Input title={'Correo electrónico'} value={getDataInitialForm({ id: 'OsP3adUakyP7', client})} onChange={null}/>
        </div>

        <div className="inputs-container">
          <Input title={'Teléfono móvil'} value={getDataInitialForm({ id: 'ZsihWuXobwQA', client})} onChange={null}/>
          <Input title={'Altura (cm)'} value={getDataInitialForm({ id: 'UeWRML5xeKni', client})} onChange={null}/>
        </div>

        <div className="inputs-container">
          <Input title={'Peso (kg)'} value={getDataInitialForm({ id: 'pgcEflgaqTna', client})} onChange={null}/>
          <Input title={'Cintura (cm)'} value={getDataInitialForm({ id: 'QTPnJtPGXWES', client})} onChange={null}/>
        </div>

        <div className="inputs-container">
          <Input title={'¿Vas a entrenar en un gimnasio o en casa?'} value={getDataInitialForm({ id: 'nz8tIQ2EnI77', client})} onChange={null}/>
          <Input title={'¿Has tenido alguna lesión previa?'} value={getDataInitialForm({ id: 'yY7utz84toGe', client})} onChange={null}/>
        </div>

        <div className="inputs-container">
          <Input title={'Por favor, descríbenos tu lesión'} value={getDataInitialForm({ id: 'q2wVZ4lDYK7F', client})} onChange={null}/>
          <Input title={'Del 1 al 10, ¿cómo puntuarías tu salud en general?'} value={getDataInitialForm({ id: 'Bbzd6GgaupkA', client})} onChange={null}/>
        </div>

        <div className="inputs-container">
          <Input title={'¿Tienes alguna intolerancia o alergia a algún alimento y/o requisito nutricional especial?'} value={getDataInitialForm({ id: 'gYrMOExSQqIC', client})} onChange={null}/>
          <Input title={'¿Estás actualmente tomando alguna medicación bajo prescripción médica y/o tomas alguna droga para mejorar el rendimiento deportivo, cognitivo, etc?'} value={getDataInitialForm({ id: 'NMX6GijzyKcL', client})} onChange={null}/>
        </div>

        <div className="inputs-container">
          <Input title={'¿Cuál/cuáles y para qué objetivo?'} value={getDataInitialForm({ id: 'N3hD8VY5leEx', client})} onChange={null}/>
          <Input title={'¿Qué quieres conseguir en este camino juntos?'} value={getDataInitialForm({ id: 'G1gueZJZksM8', client})} onChange={null}/>
        </div>

        <div className="inputs-container">
          <Input title={'Nuestra mente es el arma más poderosa que tenemos.¿Qué estás dispuesto a hacer para convertir esos objetivos en realidad?'} value={getDataInitialForm({ id: 'nsK3lDEDwPVq', client})} onChange={null}/>
          <Input title={'¿Qué es lo que tienes claro que NO harás?'} value={getDataInitialForm({ id: 'nsK3lDEDwPVq2', client})} onChange={null}/>
        </div>

        <div className="inputs-container">
          <Input title={'¿cuánto tiempo estás preparado para dedicarle al entrenamiento cada semana?'} value={getDataInitialForm({ id: 'ZQqr0x4In82j', client})} onChange={null}/>
          <Input title={'En una escala del 1 al 10, ¿cómo de disciplinado te ves a ti mismo?'} value={getDataInitialForm({ id: 'dHHM6bAhqV50', client})} onChange={null}/>
        </div>

        <div className="inputs-container">
          <Input title={'Para tener una idea más clara de tus metas en relación a la composición corporal, ¿hay alguna persona a la que te gustaría parecerte físicamente (deportista / celebridad)?¿A quién?'} value={getDataInitialForm({ id: 'A9XVlcIWM8ae', client})} onChange={null}/>
          <Input title={'¿Qué sueles hacer para “desconectar”?¿Alguna de esas cosas puede interferir en el camino hacia tu objetivo?En caso afirmativo, ¿cuál/cuáles?'} value={getDataInitialForm({ id: 'ql3XA74QVOnI', client})} onChange={null}/>
        </div>
        
        <div className="inputs-container">
          <Input title={'Tu historia con el ejercicio y/o el entrenamiento.'} value={getDataInitialForm({ id: 'GuKUGqLklj6d', client})} onChange={null}/>
          <Input title={'Elige la opción que más se acerque a tu historia con el entrenamiento actualmente.'} value={getDataInitialForm({ id: 'eWGJ3iA8Kriu', client})} onChange={null}/>
        </div>

       
        <div className="inputs-container">
          <Input title={'¿Hay algo que físicamente no puedas hacer actualmente y que siempre hayas querido hacer?'} value={getDataInitialForm({ id: 'UUbjFLnSmx4b', client})} onChange={null}/>
          <Input title={'¿Cómo empiezas tus mañanas ahora mismo?'} value={getDataInitialForm({ id: 'CxCZmYVM1aOa', client})} onChange={null}/>
        </div>

       
        <div className="inputs-container">
          <Input title={'¿Has intentado algo en el pasado para mejorar tus hábitos alimenticios y/o tu composición corporal?'} value={getDataInitialForm({ id: 'fy65rYUnke9V', client})} onChange={null}/>
          <Input title={'De esas cosas, ¿qué funcionó bien?'} value={getDataInitialForm({ id: 'CQXXHU4mXI0T', client})} onChange={null}/>
        </div>

       
        <div className="inputs-container">
          <Input title={'Ahora cuéntanos lo que crees que no fue del todo bien.'} value={getDataInitialForm({ id: 'mk3A5XQBnUvE', client})} onChange={null}/>
          <Input title={'¿Cómo querrías que tus hábitos de nutrición, ejercicio y/o estilo de vida fueran distintos?'} value={getDataInitialForm({ id: 'D7xstQOckdC6', client})} onChange={null}/>
        </div>

       
        <div className="inputs-container">
          <Input title={'¿Has hecho algún cambio en tus hábitos recientemente?'} value={getDataInitialForm({ id: 'ncfcJReFW7yq', client})} onChange={null}/>
          <Input title={'¿Nos cuentas qué has hecho exactamente? :)'} value={getDataInitialForm({ id: 'uMxhBzZalY1z', client})} onChange={null}/>
        </div>

       
        <div className="inputs-container">
          <Input title={'¿Cuántas veces comes al día?'} value={getDataInitialForm({ id: 'FWtAUOu3bafX', client})} onChange={null}/>
          <Input title={'¿Otro? Especifica cuántas comidas y snacks haces al día a continuación.'} value={getDataInitialForm({ id: 'Iyuk7Zq0Zdjl', client})} onChange={null}/>
        </div>

       
        <div className="inputs-container">
          <Input title={'¿Cocinas tu propia comida a menudo?'} value={getDataInitialForm({ id: 'QN4qIJrO1EG2', client})} onChange={null}/>
          <Input title={'¿Estás dispuesto a cocinar durante al menos las próximas doce semanas para aprender los básicos de la nutrición y ponerte la vida –y tus objetivos más fáciles? En caso negativo, ¿por qué no y qué piensas hacer para compensarlo y seguir aprendiendo?'} value={getDataInitialForm({ id: 'c8Txs4vdWwBA', client})} onChange={null}/>
        </div>

       
        <div className="inputs-container">
          <Input title={'¿Cada cuanto comes en restaurantes, sitios de comida rápida, etc?'} value={getDataInitialForm({ id: 'iq4IxEfkeCcm', client})} onChange={null}/>
          <Input title={'¿Cuántas tazas de café, té y/o bebidas energéticas tomas al día?'} value={getDataInitialForm({ id: 'AiRWLwoi4JO2', client})} onChange={null}/>
        </div>

       
        <div className="inputs-container">
          <Input title={'Esos cafés, tés, etc, ¿llevan leche, azúcar, etc?'} value={getDataInitialForm({ id: 'LZprE2aCb0kp', client})} onChange={null}/>
          <Input title={'¿Bebes alcohol?'} value={getDataInitialForm({ id: 'UYCE5Alpl5Vt', client})} onChange={null}/>
        </div>

       
        <div className="inputs-container">
          <Input title={'¿Cuánta agua bebes al día aproximadamente?'} value={getDataInitialForm({ id: 'qKnEwiAQwDVZ', client})} onChange={null}/>
          <Input title={'¿Dirías que tu estado emocional afecta a lo que comes en el día?'} value={getDataInitialForm({ id: 'etYYvfVTkf9I', client})} onChange={null}/>
        </div>

       
        <div className="inputs-container">
          <Input title={'¿Qué sueles comer en esos casos?'} value={getDataInitialForm({ id: 'mjbZQ2rqmxUb', client})} onChange={null}/>
          <Input title={'¿podrías explicarme cómo serían 3 días de comidas para ti?'} value={getDataInitialForm({ id: 'zbHNDQCSNR4V', client})} onChange={null}/>
        </div>

       
        <div className="inputs-container">
          <Input title={'¿A qué te dedicas? ¿Cuántas horas trabajas al día?'} value={getDataInitialForm({ id: 'qcKDSKj6ljHR', client})} onChange={null}/>
          <Input title={'¿Cuál es tu nivel de actividad física en el trabajo?'} value={getDataInitialForm({ id: 'Fbc3AK5TGdJY', client})} onChange={null}/>
        </div>
       
       <div className="inputs-container">
         <Input title={'Del 1 al 5, ¿cómo percibes tu nivel de estrés actual?'} value={getDataInitialForm({ id: 'W9gilDbTlOSf', client})} onChange={null}/>
         <Input title={'¿Implica tu trabajo que comas y bebas fuera a menudo (cenas/comidas con clientes, cervezas con colegas del trabajo, etc)'} value={getDataInitialForm({ id: 'QZUtBhsk1T0Y', client})} onChange={null}/>
       </div>

      
       <div className="inputs-container">
         <Input title={'¿Te cuesta desconectar por la noche?'} value={getDataInitialForm({ id: 'oihOfMsFbofq', client})} onChange={null}/>
         <Input title={'¿Cuántas horas sueles dormir de media cada noche?'} value={getDataInitialForm({ id: 'dZW7vYObZCch', client})} onChange={null}/>
       </div>
       
       <div className="inputs-container">
         <Input title={'¿Usas alguna medicación y/o suplemento para ayudarte a dormir?'} value={getDataInitialForm({ id: 'u8rBuN9GFBAl', client})} onChange={null}/>
         <Input title={'¿Cuál y en qué dosis?'} value={getDataInitialForm({ id: 'g0xmZHsgLjGh', client})} onChange={null}/>
       </div>

      
       <div className="inputs-container">
         <Input title={'¿Quién vive contigo?'} value={getDataInitialForm({ id: 'Sdm5MDfdPvaR', client})} onChange={null}/>
         <Input title={'¿Quién hace la compra en casa?'} value={getDataInitialForm({ id: 'aLDwtXvDCvtf', client})} onChange={null}/>
       </div>
       
       <div className="inputs-container">
         <Input title={'Genial.Ahora dime, ¿quién es el chef de la casa?'} value={getDataInitialForm({ id: 'FMAl2pi6A2bd', client})} onChange={null}/>
         <Input title={'¿Tienes hijos?'} value={getDataInitialForm({ id: 'GYLbOUOiaoUQ', client})} onChange={null}/>
       </div>

      
       <div className="inputs-container">
         <Input title={'¿Cómo crees que afecta esto a la comida que tienes en casa, tu entrenamiento, etc?'} value={getDataInitialForm({ id: 'vGfUoUTFxZCh', client})} onChange={null}/>
         <Input title={'¿Cuál es tu medida de cuello?'} value={getDataInitialForm({ id: 'cFC66g9KrUj8', client})} onChange={null}/>
       </div>
       
       <div className="inputs-container">
         <Input title={'¿Cuál es tu medida de pecho?'} value={getDataInitialForm({ id: 'BPCehBIkq1nh', client})} onChange={null}/>
         <Input title={'¿Cuál es tu medida de brazo izquierdo?'} value={getDataInitialForm({ id: 'KtPqiaXbFL0e', client})} onChange={null}/>
       </div>

      
       <div className="inputs-container">
         <Input title={'¿Cuál es tu medida de brazo derecho?'} value={getDataInitialForm({ id: 'SltYsNNkPJUe', client})} onChange={null}/>
         <Input title={'¿Cuál es tu medida tres dedos por encima del ombligo?'} value={getDataInitialForm({ id: 'g9KYAOQnLIUu', client})} onChange={null}/>
       </div>
       
       <div className="inputs-container">
         <Input title={'¿Cuál es tu medida de cintura, en el ombligo?'} value={getDataInitialForm({ id: 'XdIFDnW5Tdbq', client})} onChange={null}/>
         <Input title={'¿Cuál es tu medida tres dedos por debajo del ombligo?'} value={getDataInitialForm({ id: 'yNDoplLOYiaE', client})} onChange={null}/>
       </div>

      
       <div className="inputs-container">
         <Input title={'¿Cuál es tu medida de cadera?'} value={getDataInitialForm({ id: 'iqlUkjDZw0vs', client})} onChange={null}/>
         <Input title={'¿Cuál es tu medida de pierna izquierda?'} value={getDataInitialForm({ id: 'k2tkczvqdlpX', client})} onChange={null}/>
       </div>
       
       <div className="inputs-container">
         <Input title={'¿Cuál es tu medida de pierna derecha?'} value={getDataInitialForm({ id: 'aC8WQq4GIXM7', client})} onChange={null}/>
         <Input title={'¿Cuál es tu medida de gemelo izquierdo?'} value={getDataInitialForm({ id: 'aL8LuXdlA8MY', client})} onChange={null}/>
       </div>

      
       <div className="inputs-container">
         <Input title={'¿Cuál es tu medida de gemelo derecho?'} value={getDataInitialForm({ id: 'mygUBckFChue', client})} onChange={null}/>
         <Input title={'Última pregunta, ¿hay alguna condición médica en tu familia?'} value={getDataInitialForm({ id: 'D5uNflxUPF33', client})} onChange={null}/>
       </div>
       
      </div>

    </div>
  )
}


const mapStateToProps = ({clients}) => ({
  client: clients.clientSelected
});

export default connect(mapStateToProps)(EntireForm);
