import React from 'react';
import Notification from '../Shared/Notification'
import Loading from '../Shared/Loading'

const Layout = (props) => {
  return(
    <div>
      <Notification />
      {props.children}
      <Loading />
    </div>
  )
}

export default Layout