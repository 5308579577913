import React from 'react'
import Header from './Headers/List/Header'
import HeaderFases from './Headers/Fases/Header'
import List from './List/List'
import Chat from '../../../Shared/Chat'
import { connect } from 'react-redux';
import { panelsClients } from '../../../../utils/constans'
import Information from './Information/Information'
import Fases from './Phases/Phases'
import InitialForm from './InitialForm/InitialForm'

const Clients = ({panelSelected}) => (
  <>
    <div className="content-clients-with-chat">
      <div className="header-plus-content-clients">
        { (panelSelected===panelsClients.LIST || panelSelected===panelsClients.INFORMATION || panelSelected===panelsClients.INITIAL_FORM) && <Header />}
        { panelSelected===panelsClients.FASES && <HeaderFases />}
        <div className="content-clients-chat box-crm">
          { panelSelected===panelsClients.LIST && <List /> }
          { panelSelected===panelsClients.INFORMATION && <Information /> }
          { panelSelected===panelsClients.INITIAL_FORM && <InitialForm /> }
          { panelSelected===panelsClients.FASES && <Fases /> }
        </div>
      </div>
      <Chat />
    </div>
  </>
)

const mapStateToProps = ({clients}) => ({
  panelSelected: clients.panelSelected
});

export default connect(mapStateToProps)(Clients);

