import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

const Oauth2 = ({ dispatch }) => {
  const [success, setSuccess] = useState(false);
  useLayoutEffect(() => {
    /*axios
    .get(`/oauth2/authorize/${window.location.search}`)
    .then(() => {
      setSuccess(true);
    })
    .catch(err => console.error(err));
    */
  });
  return success ? <Redirect to='/' /> : <div>Loading ...</div>;
};


export default Oauth2;
