import React, { useState, useEffect, useRef } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'underscore'
import Input from '../../../../Shared/Input'
import Select from '../../../../Shared/Select'
import UpdateButtons from '../../../../Shared/UpdateButtons'
import { optionsTrainingTypes, optionsTrainingSubTypes, emptyFile } from '../../../../../utils/constans'
import { updatePhase } from '../../../../../redux/actions/phasesActions'
import { setClientSelected } from '../../../../../redux/actions/clientsActions'
import { setNotification, setLoading } from '../../../../../redux/actions/globalActions'

import moment from 'moment'
import { OpenInNewRounded, DirectionsRunRounded } from '@material-ui/icons'

// Import React FilePond
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { createFilePond, isFile, generateFile } from '../../../../../utils/functions';
import { ArrowRightRounded } from '@material-ui/icons'

const Training = (props) => {

  const [showPanel, setShowPanel] = useState(false)

  const [nutritionalPlanTrainingFiles, setNutritionalPlanTrainingFiles] = useState([])
  const [nutritionalPlanTraining, setNutritionalPlanTraining] = useState(props.nutritionalPlanTraining)

  const [nutritionalPlanRestFiles, setNutritionalPlanRestFiles] = useState([])
  const [nutritionalPlanRest, setNutritionalPlanRest] = useState(props.nutritionalPlanRest)

  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})

  useEffect(()=> { setState({...props}); setInitialFiles() }, [props])
  useEffect(()=> { 
    setEdited(!_.isEqual(state, props) || !_.isEqual(nutritionalPlanTraining, props.nutritionalPlanTraining) || !_.isEqual(nutritionalPlanRest, props.nutritionalPlanRest)) 
  }, [state, nutritionalPlanTraining, nutritionalPlanRest])



  const setInitialFiles = () => {
    setNutritionalPlanTrainingFiles(props?.nutritionalPlanTraining?.size > 0 ? [createFilePond(props.nutritionalPlanTraining)] : [])
    setNutritionalPlanRestFiles(props?.nutritionalPlanRest?.size > 0 ? [createFilePond(props.nutritionalPlanRest)] : [])
    setNutritionalPlanTraining(props.nutritionalPlanTraining)
    setNutritionalPlanRest(props.nutritionalPlanRest)
  }

  const saveData = () => {

    let multiPath = {}
    if(props.trainingType!==state.trainingType) multiPath["training.trainingType"] = state.trainingType
    if(props.trainingSubType!==state.trainingSubType) multiPath["training.trainingSubType"] = state.trainingSubType

    if(!_.isEqual(nutritionalPlanTraining, props.nutritionalPlanTraining)) multiPath["training.nutritionalPlans.nutritionalPlanTraining"] = nutritionalPlanTraining ?? emptyFile
    if(!_.isEqual(nutritionalPlanRest, props.nutritionalPlanRest)) multiPath["training.nutritionalPlans.nutritionalPlanRest"] = nutritionalPlanRest ?? emptyFile

    const payload = {
      id: props.idPhase,
      data: { 
        body: { 
          ...multiPath,
          phaseNumber: props.phaseNumber,
          idClient: props.idClient
        } 
      }
    }
    props.actions.setLoading(true)
    props.actions.updatePhase(payload)
    .then( ({ phase }) => {
      props.actions.setClientSelected({path:`phases.${phase.idPhase}`, value: phase})
      props.actions.setNotification({ visibility: true, text: "Se ha guardado correctamente", status: "done", moment: Date.now() })
      props.actions.setLoading(false)
    })
    .catch( error => {
      console.log(error);
      props.actions.setNotification({ visibility: true, text: "Ha ocurrido un error", status: "close", moment: Date.now() })
      props.actions.setLoading(false)
    })
  }

  const setFile = (data, id) => {
    
    if(data && !data.getFileEncodeBase64String()) return null
    const file = {
      name: data?.file?.name ?? '',
      lastModified: data?.file?.lastModified ?? 0,
      size: data?.file?.size ?? 0,
      type: data?.file?.type ?? '',
      data: data?.getFileEncodeBase64String() ?? ''
    }
    if(id === 'nutritionalPlanTraining'){
      setNutritionalPlanTrainingFiles(!data ? [] : [data])
      setNutritionalPlanTraining(data ? file : null)
    }else if(id === 'nutritionalPlanRest'){
      setNutritionalPlanRestFiles(!data ? [] : [data])
      setNutritionalPlanRest(data ? file : null)
    }
  }

  const openFile = async (id) => {
    const blob = await generateFile(state[id])
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  }

  const undoData = () => {
    setState({...props})
    setNutritionalPlanTraining(props.nutritionalPlanTraining)
    setNutritionalPlanRest(props.nutritionalPlanRest)
    setInitialFiles()
  }
  const onChangeTraining = trainingType => {
    if(trainingType === state.trainingType) return false
    setState({ ...state, trainingType, trainingSubType: "" })
  }
  return(
    <div className={`sub-container-informacion employee-mode ${!showPanel ? 'hide-panel-component' : ''}`}>

      {edited && !props.block && <UpdateButtons saveData={saveData} undoData={()=>undoData()}/> }

      <div className="arrow-more-content" onClick={() => setShowPanel(!showPanel)}><ArrowRightRounded /></div>
      <p className='title-informacion'>
        {props.title}
        {props.startDate && <span className="date-title">{moment(props.startDate).format('DD/MM/YYYY')}</span> }
      </p>
      {props.client.currentPhase.idPhase === props.idPhase && props.client.currentPhase.step==="training" &&
        <div className="current-icon"><DirectionsRunRounded /></div>
      }
      {showPanel &&
        <>
          <div className="inputs-container">
            <Select title="Tipo de entrenamiento" value={state.trainingType} options={optionsTrainingTypes} onChange={trainingType => onChangeTraining(trainingType)}/>
            <Select blocked={!optionsTrainingSubTypes?.[state?.trainingType]} title="Número de Fase" value={state.trainingSubType} options={optionsTrainingSubTypes?.[state?.trainingType] ?? []} onChange={trainingSubType => setState({ ...state, trainingSubType })}/>
          </div>
          <div className="inputs-container">
            <div className={`input-crm-component`}>
              <div class="title-input">Plan Nutricional Dia de Entrenamiento:</div>
              <div className="container-filepond-download-file">
                {/* <FilePond id={'a'} files={state?.nutritionalPlanTraining?.size > 0 ? [()=>createFilePond(state.nutritionalPlanTraining)] : []} labelIdle='Selecciona un fichero' onupdatefiles={ ([file]) => setFile(file, "nutritionalPlanTraining")}/> */}
                <FilePond id={'a'} files={nutritionalPlanTrainingFiles} labelIdle='Selecciona un fichero' onupdatefiles={ ([file]) => setFile(file, "nutritionalPlanTraining")}/>
                {_.isEqual(props.nutritionalPlanTraining, nutritionalPlanTraining) && isFile(nutritionalPlanTraining) && 
                  <div className="container-icon-new-window">
                    <div onClick={() => openFile('nutritionalPlanTraining')}><OpenInNewRounded/></div>
                  </div>
                }
              </div>
            </div>
            <div className={`input-crm-component`}>
              <div class="title-input">Plan Nutricional Día de Descanso:</div>
              <div className="container-filepond-download-file">
                <FilePond files={nutritionalPlanRestFiles} labelIdle='Selecciona un fichero'  onupdatefiles={ ([file]) => setFile(file, "nutritionalPlanRest")}/>
                {_.isEqual(props.nutritionalPlanRest, nutritionalPlanRest) && isFile(nutritionalPlanRest) && 
                  <div className="container-icon-new-window">
                    <div onClick={() => openFile('nutritionalPlanRest')}><OpenInNewRounded/></div>
                  </div>
                }
              </div>
            </div>
          </div>

        </>
      }

    </div>
  )
}

const matchDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    updatePhase,
    setClientSelected,
    setNotification,
    setLoading
  }, dispatch)	
}); 

export default connect(null, matchDispatchToProps)(Training);