class History {

  idHistory = ""
  idClient = ""
  category = ""
  from = ""
  to = ""
  createdBy = "SYSTEM"
  metadata = {}
  messages = []

  constructor(obj){
    if(obj){
      this.idHistory = obj?.idHistory ?? this.idHistory
      this.idClient = obj?.idClient ?? this.idClient
      this.category = obj?.category ?? this.type
      this.from = obj?.from ?? this.from
      this.to = obj?.to ?? this.to
      this.createdBy = obj?.createdBy ?? this.createdBy

      this.metadata = obj?.metadata ?? this.metadata
      this.messages = obj?.messages ?? this.messages
    }
  }

}

export default History