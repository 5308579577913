import React, { useState } from 'react'
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { signIn } from '../../redux/actions/employeeActions'
const USER = [ "admin", "bella.doce"]
const PASS = [ "doceadmin2020.", "Xb9QAYPefs$&LE9w" ]

const Login = ({ location, actions }) => {

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [authenticated, setAuthenticated] = useState(false)
  const [redirectTo, setRedirectTo] = useState({pathname: location?.state?.redirect ?? "/"})


  const signIn = () => {
    if (USER.includes(username) && PASS.includes(password)) {
      const payload = {
        data: { 
          body: {
            username,
            password
          }
        }
      }
      actions.signIn(payload)
      .then(({ employee }) => {
        sessionStorage.setItem("auth", JSON.stringify(employee))
        setAuthenticated(true)
      })
        .catch(error => {
          console.log(error)
        })
    } else {
      setError("El usuario o la contraseña son incorrectos")
    }
  }

  return(
    authenticated ? <Redirect to={redirectTo} /> :

    <div className="login-crm">
      <form>
        <div className="containerApp contLogin">
            <h1 className="is">Iniciar sesión</h1>
            <div >
              <div className={`container-simple-input `}>
                <div className='title-input'>Username:</div>
                <div className='container-input'>
                  <input id="username" type="text" value={username} className="TextInput TextInput_large" onChange={ e => setUsername(e.target.value)} />
                </div>
              </div>
              <div className={`container-simple-input `}>
                <div className='title-input'>Password:</div>
                <div className='container-input'>
                  <input id="password" type="password" value={password} className="TextInput TextInput_large" onChange={ e => setPassword(e.target.value)} />
                </div>
              </div>
              <div className="login_info"><span >{error}</span></div>
              <div className='container-btn-log-in'>
                <div onClick={signIn} className="btnContinuar">Log in</div>
              </div>
            </div>
        </div>
      </form>
    </div>

  )
}

const matchDispatchToProps = (dispatch) => {
  return { actions: bindActionCreators({ signIn }, dispatch)}
}

export default connect(null, matchDispatchToProps)(Login)