import { historyTypes } from '../actions/historyActions';
var initialState = {
  forceGetHistories: Date.now()
}

export default function (state = initialState, action) {
  switch (action.type) {

    case historyTypes.FORCE_GET_HISTORIES:{
      console.log("cambiando force");
      return { ...state, forceGetHistories: Date.now() }
    }
    
    default:{
      return state;
    }
  }
}