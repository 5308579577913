import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Training from './Training'
import Check from './Check'
import EndPhase from './EndPhase'
import { optionsValidateInitialForm, MACROS_QUALITY } from '../../../../../utils/constans'
import { getDataComparation, isFile } from '../../../../../utils/functions'


const Phases = ({ client, phases }) => {

  return(
    <div className="panel-list-clients panel-data">


      { Object.entries(client.phases).map(([id, phase], index)=>{
        if(id!==client.currentPhase.idPhase && client?.currentPhase?.phaseNumber - 1 !== phase.phaseNumber) return false
        //if(index < Object.keys(client.phases).length - 1) return null
        const nextPhase = Object.entries(client.phases).find(([index, nextPhase]) => nextPhase.phaseNumber === phase.phaseNumber + 1)?.[1]
        const prevPhase = Object.entries(client.phases).find(([index, prevPhase]) => prevPhase.phaseNumber === phase.phaseNumber - 1)?.[1]

        return(
          <div className="container-fase">
            <div className={`identificator-fase ${ client.currentPhase.idPhase === phase.idPhase ? 'current-phase' : ''}`}>
              {`Fase ${phase.phaseNumber}`}
              </div>
            
            <div className='container-informacion'>
              <Training 
                title='Entrenamiento'
                idClient={client.idClient}
                client={client}
                idPhase={phase.idPhase}
                phaseNumber={phase.phaseNumber}
                trainingType = {phase.training.trainingType}
                trainingSubType = {phase.training.trainingSubType}
                startDate = {phase.training.startDate}
                nutritionalPlanTraining = {phase.training.nutritionalPlans.nutritionalPlanTraining}
                nutritionalPlanRest = {phase.training.nutritionalPlans.nutritionalPlanRest}
              />
            </div>

            <div className='container-informacion'>
              <Check
                title='Check de fase'
                idClient={client.idClient}
                idPhase={phase.idPhase}
                phaseNumber={phase.phaseNumber}
                startDate={phase.check.startDate}
                feedbackSent={phase.check.feedbackSent}

                
                isMfp={client.isMfp}
                questionsMacrosQuality={MACROS_QUALITY[phase.check.questions.macrosQuality]}
                questionsProtein={phase.check.questions.protein}
                questionsCarbohydrates={phase.check.questions.carbohydrates}
                questionsFats={phase.check.questions.fats}

                dataComparation={getDataComparation({ phase, client, step: "check" })}

                validated = {phase.check.validated ?? optionsValidateInitialForm[0]}
                wrongValidation = {phase.check.wrongValidation}

                nutritionFeedack = {phase.check.nutritionFeedack}
                otherFeedack = {phase.check.otherFeedack}
                
                editableNotes = {client.editableNotes}
                globalNotes = {client.globalNotes}
                
                prevPhase = {prevPhase}
                prevKcal = {prevPhase?.endPhase?.mfp?.kcal}
                kcal = {phase?.check?.mfp?.kcal}
                prevProtein = {prevPhase?.endPhase?.mfp?.protein}
                protein = {phase?.check?.mfp?.protein}
                prevCarbohydrates = {prevPhase?.endPhase?.mfp?.carbohydrates}
                carbohydrates = {phase?.check?.mfp?.carbohydrates}
                prevFats = {prevPhase?.endPhase?.mfp?.fats}
                fats = {phase?.check?.mfp?.fats}

                averageCurrentWeight = {phase.check.questions.averageCurrentWeight}
                averageWaistCurrent = {phase.check.questions.averageWaistCurrent}
                personalBest = {phase.check.questions.personalBest}
                neat = {phase.check.questions.neat}
                exerciseWaistPainful = {phase.check.questions.exerciseWaistPainful}
                levelAdherence = {phase.check.questions.levelAdherence}
                whyThatNote = {phase.check.questions.whyThatNote}
                whatDidWell = {phase.check.questions.whatDidWell}
                improvements = {phase.check.questions.improvements}
                tweet = {phase.check.questions.tweet}
                miniVictory = {phase.check.questions.miniVictory}
                priority = {phase.check.questions.priority}
                notes = {phase.check.questions.notes}
                
                neck = {phase.check.measurements.neck}
                chest = {phase.check.measurements.chest}
                rightArm = {phase.check.measurements.rightArm}
                leftArm = {phase.check.measurements.leftArm}
                waist1 = {phase.check.measurements.waist1}
                waist2 = {phase.check.measurements.waist2}
                waist3 = {phase.check.measurements.waist3}
                hip = {phase.check.measurements.hip}
                rightLeg = {phase.check.measurements.rightLeg}
                leftLeg = {phase.check.measurements.leftLeg}
                rightTwin = {phase.check.measurements.rightTwin}
                leftTwin = {phase.check.measurements.leftTwin}
                images = {phase.check.images}
              />
            </div>
            <div className='container-informacion'>

              <EndPhase
                title='Fin de fase'
                idClient={client.idClient}
                idPhase={phase.idPhase}
                phaseNumber={phase.phaseNumber}
                startDate={phase.endPhase.startDate}
                feedbackSent={phase.endPhase.feedbackSent}

                nextPhaseNumber={phase.phaseNumber + 1}
                isMfp={client.isMfp}
                questionsMacrosQuality = { MACROS_QUALITY[phase.endPhase.questions.macrosQuality] }
                questionsProtein = { phase.endPhase.questions.protein }
                questionsCarbohydrates = { phase.endPhase.questions.carbohydrates }
                questionsFats={phase.endPhase.questions.fats}

                validated = {phase.endPhase.validated ?? optionsValidateInitialForm[0]}
                wrongValidation = {phase.endPhase.wrongValidation}

                dataComparation={getDataComparation({ phase, client, step: "endPhase" })}

                nutritionFeedack = {phase.endPhase.nutritionFeedack}
                otherFeedack = {phase.endPhase.otherFeedack}
                
                editableNotes = {client.editableNotes} 
                globalNotes = {client.globalNotes} 
                
                prevKcal = {phase?.check.mfp?.kcal}
                kcal = {phase?.endPhase?.mfp?.kcal}
                prevProtein = {phase?.check?.mfp?.protein}
                protein = {phase?.endPhase?.mfp?.protein}
                prevCarbohydrates = {phase?.check?.mfp?.carbohydrates}
                carbohydrates = {phase?.endPhase?.mfp?.carbohydrates}
                prevFats = {phase?.check?.mfp?.fats}
                fats = {phase?.endPhase?.mfp?.fats}

                averageCurrentWeight = {phase.endPhase.questions.averageCurrentWeight}
                averageWaistCurrent = {phase.endPhase.questions.averageWaistCurrent}
                personalBest = {phase.endPhase.questions.personalBest}
                neat = {phase.endPhase.questions.neat}
                exerciseWaistPainful = {phase.endPhase.questions.exerciseWaistPainful}
                levelAdherence = {phase.endPhase.questions.levelAdherence}
                whyThatNote = {phase.endPhase.questions.whyThatNote}
                whatDidWell = {phase.endPhase.questions.whatDidWell}
                improvements = {phase.endPhase.questions.improvements}
                tweet = {phase.endPhase.questions.tweet}
                miniVictory = {phase.endPhase.questions.miniVictory}
                priority = {phase.endPhase.questions.priority}
                notes = {phase.endPhase.questions.notes}

                averageCurrentWeight = {phase.endPhase.questions.averageCurrentWeight}
                averageWaistCurrent = {phase.endPhase.questions.averageWaistCurrent}
                personalBest = {phase.endPhase.questions.personalBest}
                neat = {phase.endPhase.questions.neat}
                exerciseWaistPainful = {phase.endPhase.questions.exerciseWaistPainful}
                levelAdherence = {phase.endPhase.questions.levelAdherence}
                whyThatNote = {phase.endPhase.questions.whyThatNote}
                whatDidWell = {phase.endPhase.questions.whatDidWell}
                improvements = {phase.endPhase.questions.improvements}
                tweet = {phase.endPhase.questions.tweet}
                miniVictory = {phase.endPhase.questions.miniVictory}
                priority = {phase.endPhase.questions.priority}
                notes = {phase.endPhase.questions.notes}
                
                neck = {phase.endPhase.measurements.neck}
                chest = {phase.endPhase.measurements.chest}
                rightArm = {phase.endPhase.measurements.rightArm}
                leftArm = {phase.endPhase.measurements.leftArm}
                waist1 = {phase.endPhase.measurements.waist1}
                waist2 = {phase.endPhase.measurements.waist2}
                waist3 = {phase.endPhase.measurements.waist3}
                hip = {phase.endPhase.measurements.hip}
                rightLeg = {phase.endPhase.measurements.rightLeg}
                leftLeg = {phase.endPhase.measurements.leftLeg}
                rightTwin = {phase.endPhase.measurements.rightTwin}
                leftTwin = {phase.endPhase.measurements.leftTwin}
                images = {phase.check.images}

                nextPhase = {nextPhase}

                trainingType = {nextPhase?.training?.trainingType ?? phase.training.trainingType}
                trainingSubType = {nextPhase?.training?.trainingSubType ?? phase.training.trainingSubType}
                nutritionalPlanTraining = {isFile(nextPhase?.training?.nutritionalPlans?.nutritionalPlanTraining) ? nextPhase.training.nutritionalPlans.nutritionalPlanTraining : phase.training.nutritionalPlans.nutritionalPlanTraining}
                nutritionalPlanRest = {isFile(nextPhase?.training?.nutritionalPlans?.nutritionalPlanRest) ? nextPhase.training.nutritionalPlans.nutritionalPlanRest : phase.training.nutritionalPlans.nutritionalPlanRest}

              />
            </div>


            

          </div>
        )
      }) 

      }

      {/* <div className="container-fase">
        <div className="identificator-fase">Fase 1</div>
        
        <div className='container-informacion'>
          <Entrenamiento 
            title='1. Entrenamiento' 
            idClient={client.idClient}
            tipoEntrenamiento = {""}
          />
          <CheckFase 
            title='2. Check de fase'
            idClient={client.idClient}
          />
        </div>

      </div>

      <div className="container-fase">
        <div className="identificator-fase">Fase 2</div>
        <div className='container-informacion'>
          <Entrenamiento 
            title='1. Entrenamiento' 
            idClient={client.idClient}
            tipoEntrenamiento = {""}
          />
          <CheckFase 
            title='2. Check de fase'
            idClient={client.idClient}
          />
        </div>
      </div>

      <div className="container-fase">
        <div className="identificator-fase">Fase 3</div>
        <div className='container-informacion'>
          <Entrenamiento 
            title='1. Entrenamiento' 
            idClient={client.idClient}
            tipoEntrenamiento = {""}
          />
          <CheckFase 
            title='2. Check de fase'
            idClient={client.idClient}
          />
        </div>
      </div>

      <div className="container-fase">
        <div className="identificator-fase">Fase 4</div>
        <div className='container-informacion'>
          <Entrenamiento 
            title='1. Entrenamiento' 
            idClient={client.idClient}
            tipoEntrenamiento = {""}
          />
          <CheckFase 
            title='2. Check de fase'
            idClient={client.idClient}
          />
        </div>
      </div> */}

    </div>
  )
}

const mapStateToProps = ({clients}) => ({
  client: clients.clientSelected
});

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({

    }, dispatch)	
	};
}

export default connect(mapStateToProps, matchDispatchToProps)(Phases)