import React, { useState, useRef, useEffect, memo } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../redux/actions/globalActions';
import { CloseRounded, DoneRounded, WarningRounded } from '@material-ui/icons'
import _ from 'underscore'

let timeOut;
const Notification = memo((props) => {
  const refElement = useRef()
  const [state, setState] = useState({...props})

  useEffect(()=>{
    clearTimeout(timeOut)
    const element = refElement.current;
    if(element && !_.isEqual(state,props)){
      if(state.visibility && props.visibility && element.classList.value.includes('container-pop-up-info-visible')) {
        element.classList.remove('container-pop-up-info-visible')
        timeOut = setTimeout(() => {
          element.classList.add('container-pop-up-info-visible')
          setState({ visibility: props.visibility, text: props.text, status: props.status, moment: props.moment })
          showNotification(props.visibility)
        }, 300);
      }else{
        element.classList.add('container-pop-up-info-visible')
        setState({ visibility: props.visibility, text: props.text, status: props.status, moment: props.moment })
        showNotification(props.visibility)
      }
    }
  }, [props])

  const showNotification = visibility => {
    if (visibility) timeOut = setTimeout(() => props.actions.setNotification({ visibility: false }), 4500);
  }

  return(
    <div ref={refElement} className={`container-pop-up-info ${state.visibility ? 'container-pop-up-info-visible' : ''}`}>
      {state.status &&
        <div className={`container-pop-up-info-icon status-${state.status}`}>
          {state.status === "close" && <CloseRounded />}
          {state.status === "done" && <DoneRounded />}
          {state.status === "warning" && <WarningRounded />}
        </div>
      }
      <div className="text-center">{state.text}</div>
    </div>
  )
})

const mapStateToProps = ({ global }) => {
  return {
    visibility: global.notification.visibility,
    text: global.notification.text,
    status: global.notification.status,
    moment: global.notification.moment,
  }
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setNotification
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(Notification);