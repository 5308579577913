const ethosClient = require('doce-client').createClient()
export const historyTypes = {
  FORCE_GET_HISTORIES: "FORCE_GET_HISTORIES"
}
export const updateHistory = ({ id, data }) => async (dispatch) => {
  try {
    const { body } = await ethosClient.updateHistory({ id, data })
    return Promise.resolve(body)
  } catch (error) {
    console.log(error)
  }
}


export const reloadHistories = () => ({ type: historyTypes.FORCE_GET_HISTORIES })
