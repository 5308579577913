import { isEqual } from 'underscore'
import { FileCopyRounded } from '@material-ui/icons'
import moment from 'moment'

import React, { useState, useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Input from '../../../../Shared/Input'
import Select from '../../../../Shared/Select'
import UpdateButtons from '../../../../Shared/UpdateButtons'
import FormRichText from '../../../../Shared/FormRichText'
import ImagesComparation from '../../../../Shared/ImagesComparation'
// Import React FilePond
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { updatePhase } from '../../../../../redux/actions/phasesActions'
import { getClients, setClientSelected } from '../../../../../redux/actions/clientsActions'
import { setNotification, setLoading } from '../../../../../redux/actions/globalActions'
import History from '../../../../../models/History'

import ChartWrapper from './components/ChartWrapper'

import {
  optionsValidateInitialForm,
  optionsTrainingTypes,
  optionsTrainingSubTypes,
  optionsMFPInitialForm,
  emptyFile,
  emailsIds
} from '../../../../../utils/constans'

import {
  OpenInNewRounded,
  ArrowRightRounded,
  DoneRounded,
  SendRounded,
  DirectionsRunRounded
} from '@material-ui/icons'

import {
  createFilePond,
  isFile,
  setImagesComparation,
  generateFile,
  blobToBase64,
  getStringHtml
} from '../../../../../utils/functions';


const EndPhase = (props) => {

  const [showPanel, setShowPanel] = useState(false)
  const [showMore, setShowMore] = useState(false)

  const [nutritionalPlanTrainingFiles, setNutritionalPlanTrainingFiles] = useState([])
  const [nutritionalPlanTraining, setNutritionalPlanTraining] = useState(props.nutritionalPlanTraining)

  const [nutritionalPlanRestFiles, setNutritionalPlanRestFiles] = useState([])
  const [nutritionalPlanRest, setNutritionalPlanRest] = useState(props.nutritionalPlanRest)

  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})

  useEffect(() => { setState({ ...props }); setInitialFiles() }, [props])
  
  useEffect(()=> { 
    setEdited(!isEqual(state, props) || !isEqual(nutritionalPlanTraining, props.nutritionalPlanTraining) || !isEqual(nutritionalPlanRest, props.nutritionalPlanRest)) 
  }, [state, nutritionalPlanTraining, nutritionalPlanRest])
  
  useEffect(() => {
    if (!state.carbohydrates || !state.fats || !state.protein) return

    const kcal = Math.round(state.carbohydrates*4 + state.protein*4 + state.fats*9)
    setState({ ...state, kcal })
  }, [state.carbohydrates, state.protein, state.fats])

  const setInitialFiles = () => {
    setNutritionalPlanTrainingFiles(props?.nutritionalPlanTraining?.size > 0 ? [createFilePond(props.nutritionalPlanTraining)] : [])
    setNutritionalPlanRestFiles(props?.nutritionalPlanRest?.size > 0 ? [createFilePond(props.nutritionalPlanRest)] : [])
    setNutritionalPlanTraining(props.nutritionalPlanTraining)
    setNutritionalPlanRest(props.nutritionalPlanRest)
  }

  const saveData = async () => {


    let multiPath = {}, multiPathNextPhase= {},multiPathHistory = [], multiPathClient = {}

    if(props.validated!==state.validated) {
      multiPath[`endPhase.validated`] = state.validated
      if(state.validated === optionsValidateInitialForm[1]){
        multiPathClient["status"] = "WAITING_ASSIGN_TRAINING"
        multiPathHistory.push(new History({
          idClient: props.clientSelected.idClient,
          category: `PHASE_${props.phaseNumber}`,
          from: "EMPLOYEE",
          to: "CLIENT",
          createdBy: "SYSTEM",
          metadata: {
            //idTemplate: emailsIds.verificatedInitialForm,
            subject: "Check validado",
            content: props?.templates?.[emailsIds.endPhaseSent]?.content?.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates ,
            notification: true,
            hiddenResponse: true,
            sent:{
              email: props.clientSelected.personalData.email,
              msg: props?.templates?.[emailsIds.nofiticationEmail]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates
            }
          }
        }))
      }else if(state.validated === optionsValidateInitialForm[2]){
        multiPathClient["status"] = "END_PHASE_WRONG_VALIDATED"
        multiPathHistory.push(new History({
          idClient: props.clientSelected.idClient,
          category: `PHASE_${props.phaseNumber}`,
          from: "EMPLOYEE",
          to: "CLIENT",
          createdBy: "SYSTEM",
          metadata: {
            idPhase: props.idPhase,
            step: "endPhase",
            subject: "Errores en el fin de fase",
            content: state?.wrongValidation.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates,
            notification: true,
            button:{
              text: "Fin de fase",
              action: "showCheck"
            },
            sent:{
              email: props.clientSelected.personalData.email,
              msg: props?.templates?.[emailsIds.nofiticationEmail]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates
            }
          }
        }))
      }
    }
    if(props.wrongValidation !== state.wrongValidation) multiPath[`endPhase.wrongValidation`] = state.wrongValidation
    if(props.nutritionFeedack !== state.nutritionFeedack) multiPath[`endPhase.nutritionFeedack`] = getStringHtml(state.nutritionFeedack) ? state.nutritionFeedack : getStringHtml(state.nutritionFeedack)
    if(props.otherFeedack !== state.otherFeedack) multiPath[`endPhase.otherFeedack`] = getStringHtml(state.otherFeedack) ? state.otherFeedack : getStringHtml(state.otherFeedack)

    if(props.editableNotes !== state.editableNotes) multiPath[`editableNotes`] = state.editableNotes

    if(props.kcal !== state.kcal) multiPath[`endPhase.mfp.kcal`] = state.kcal
    if(props.protein !== state.protein) multiPath[`endPhase.mfp.protein`] = state.protein
    if(props.carbohydrates !== state.carbohydrates) multiPath[`endPhase.mfp.carbohydrates`] = state.carbohydrates
    if(props.fats !== state.fats) multiPath[`endPhase.mfp.fats`] = state.fats

    if(props.questionsMacrosQuality !== state.questionsMacrosQuality) multiPath[`endPhase.questions.kcal`] = +state.questionsMacrosQuality
    if(props.questionsProtein !== state.questionsProtein) multiPath[`endPhase.questions.protein`] = +state.questionsProtein
    if(props.questionsCarbohydrates !== state.questionsCarbohydrates) multiPath[`endPhase.questions.carbohydrates`] = +state.questionsCarbohydrates
    if(props.questionsFats !== state.questionsFats) multiPath[`endPhase.questions.fats`] = +state.questionsFats

   
    if(props.isMfp !== state.isMfp ) multiPathClient[`isMfp`] = state.isMfp

    //if(props.trainingType!==state.trainingType) 
      multiPathNextPhase["training.trainingType"] = state.trainingType
    //if(props.trainingSubType!==state.trainingSubType) 
      multiPathNextPhase["training.trainingSubType"] = state.trainingSubType

    //if(!isEqual(nutritionalPlanTraining, props.nutritionalPlanTraining))
    if(nutritionalPlanTraining?.data?.includes('uploads/files')){
      multiPathNextPhase["training.nutritionalPlans.nutritionalPlanTraining"] = nutritionalPlanTraining?.data?.trim() ? await blobToBase64(await generateFile(nutritionalPlanTraining), nutritionalPlanTraining) : emptyFile
    }else{
      multiPathNextPhase["training.nutritionalPlans.nutritionalPlanTraining"] = nutritionalPlanTraining ?? emptyFile
    }

    if(nutritionalPlanRest?.data?.includes('uploads/files')){
      multiPathNextPhase["training.nutritionalPlans.nutritionalPlanRest"] = nutritionalPlanRest?.data?.trim() ? await blobToBase64(await generateFile(nutritionalPlanRest), nutritionalPlanRest) : emptyFile
    }else{
      multiPathNextPhase["training.nutritionalPlans.nutritionalPlanRest"] = nutritionalPlanRest ?? emptyFile
    }


    let payload = {
      id: props.idPhase,
      data: { 
        body: { 
          ...multiPath,
          idClient: props.idClient,
          client: multiPathClient,
          nextPhaseNumber: props.nextPhaseNumber, 
          idNextPhase: props?.nextPhase?.idPhase ?? null,
          nextPhase: multiPathNextPhase,
          history: Object.keys(multiPathHistory).length>0 ? multiPathHistory : null,

        } 
      }
    }    

    props.actions.setLoading(true)
    props.actions.updatePhase(payload)
    .then(({ phase, client, nextPhase }) => {
      props.actions.getClients()
      props.actions.setClientSelected({path:`phases.${phase.idPhase}`, value: phase})
      if(client?.isMfp){
        props.actions.setClientSelected({path:`isMfp`, value: client?.isMfp})
      }
      if(nextPhase){
        props.actions.setClientSelected({path:`phases.${nextPhase.idPhase}`, value: nextPhase})
      }
      props.actions.setNotification({ visibility: true, text: "Se ha guardado correctamente", status: "done", moment: Date.now() })
      props.actions.setLoading(false)

    })
    .catch( error => {

    })

  }

  const setFile = (data, id) => {
    
    if(data && !data.getFileEncodeBase64String()) return null
    const file = {
      name: data?.file?.name ?? '',
      lastModified: data?.file?.lastModified ?? 0,
      size: data?.file?.size ?? 0,
      type: data?.file?.type ?? '',
      data: data?.getFileEncodeBase64String() ?? ''
    }
    if(id === 'nutritionalPlanTraining'){
      setNutritionalPlanTrainingFiles(!data ? [] : [data])
      setNutritionalPlanTraining(data ? file : null)
    }else if(id === 'nutritionalPlanRest'){
      setNutritionalPlanRestFiles(!data ? [] : [data])
      setNutritionalPlanRest(data ? file : null)
    }
    //setState({ ...state, [id]: data ? file : null })
  }

  const openFile = async (id) => {
    try {
      const blob = await generateFile(state[id])
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    } catch (error) {
      console.log(error);
    }
  }

  const sentFeedback = () => {
    let multiPathClient = {}, multiPathPhase = {}, multiPathHistory = []
    //cambios en el CLIENT
    multiPathClient["status"] = "IN_PROGRESS"
    //Cambios en la PHASE
    multiPathPhase["endPhase.feedbackSent"] = "date:now"
    //Cambios en el HISTORY
    multiPathHistory.push(new History({
      idClient: props.idClient,
      category: `PHASE_${props.phaseNumber}`,
      from: "EMPLOYEE",
      to: "CLIENT",
      createdBy: "SYSTEM",
      metadata: {
        //idTemplate: "528c6498-0b75-4893-b6a5-ee1dff0c8b76",
        subject: `Feedback del fin de fase ${props.phaseNumber}`,
        content: `${state.nutritionFeedack}</br>${state.otherFeedack}
                  ${state.isMfp === optionsMFPInitialForm[0] ? isEmptyMfp() ? getMfpData() : '' : ''}`,
        notification: true,
        hiddenOkButton: true,
        sent:{
          email: props.clientSelected.personalData.email,
          msg: props?.templates?.[emailsIds.nofiticationEmail]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates
        }
      }
    }))

    const payload = {
      id: props.idPhase,
      data: { 
        body: {
          ...multiPathPhase,
          idClient: props.idClient,
          client: multiPathClient,
          history: multiPathHistory   
        } 
      }
    }
    props.actions.setLoading(true)
    props.actions.updatePhase(payload)
    .then( ({ phase, client }) => {
      props.actions.setClientSelected({path:`phases.${phase.idPhase}`, value: phase})
      props.actions.setNotification({ visibility: true, text: "Se ha enviado correctamente", status: "done", moment: Date.now() })
      props.actions.setLoading(false)
    })
    
  }

  const sendNewPhase = () => {

    let multiPathNextPhase= {}, multiPathClient = {}, multiPathHistory = []
    multiPathNextPhase["training.startDate"] = "date:now"
    
    multiPathNextPhase["training.trainingSubType"] = state.trainingSubType
    multiPathNextPhase["training.trainingType"] = state.trainingType
    multiPathNextPhase["training.nutritionalPlans.nutritionalPlanTraining"] = nutritionalPlanTraining
    multiPathNextPhase["training.nutritionalPlans.nutritionalPlanRest"] = nutritionalPlanRest

    multiPathClient["status"] = "WAITING_FEEDBACK"
    multiPathClient["currentPhase.idPhase"] = Object.entries(props.clientSelected.phases).find( ([id, phase]) => +phase.phaseNumber === +props.nextPhaseNumber)[0]
    multiPathClient["currentPhase.phaseNumber"] = props.nextPhaseNumber
    multiPathClient["currentPhase.step"] = "training"
    multiPathHistory.push(new History({
      idClient: props.idClient,
      category: `PHASE_${props.nextPhaseNumber}`,
      from: "EMPLOYEE",
      to: "CLIENT",
      createdBy: "SYSTEM",
      metadata: {
        //idTemplate: "528c6498-0b75-4893-b6a5-ee1dff0c8b76",
        subject: `Nueva fase ${props.nextPhaseNumber}`,
        content: props?.templates?.[emailsIds.newPhase]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates,
        notification: true,
        hiddenResponse: true,
        sent:{
          email: props.clientSelected.personalData.email,
          msg: props?.templates?.[emailsIds.newPhase]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates,
        }
      }
    }))

    let payload = {
      id: props.idPhase,
      data: { 
        body: { 
          idClient: props.idClient,
          client: multiPathClient,
          nextPhaseNumber: props.nextPhaseNumber, 
          idNextPhase: props?.nextPhase?.idPhase ?? null,
          nextPhase: multiPathNextPhase,
          history: multiPathHistory
        } 
      }
    }    
    props.actions.setLoading(true)
    props.actions.updatePhase(payload)
    .then( ({ phase, client, nextPhase }) => {
      props.actions.setClientSelected({path:`currentPhase`, value: client.currentPhase})
      props.actions.setClientSelected({path:`phases.${phase.idPhase}`, value: phase})

      if(client?.isMfp){
        props.actions.setClientSelected({path:`isMfp`, value: client?.isMfp})
      }
      if(nextPhase){
        props.actions.setClientSelected({path:`phases.${nextPhase.idPhase}`, value: nextPhase})
      }
      props.actions.setNotification({ visibility: true, text: "Se ha guardado correctamente", status: "done", moment: Date.now() })
      props.actions.setLoading(false)

    })
    .catch( error => {

    })

  }

  const isEmptyMfp = () => {
    return !isEqual(state.kcal) || !isEqual(state.protein) || !isEqual(state.carbohydrates) || !isEqual(state.fats)
  }

  const getMfpData = () => {
    let data = `</br>Tus macros son:</br><br>
    <ul><li>Media de <b>Calorías</b>: ${state.kcal}</li>
        <li>Media de <b>Proteinas</b>: ${state.protein} gr</li>
        <li>Media de <b>Carbohidratos</b>: ${state.carbohydrates} gr</li>
        <li>Media de <b>Grasas</b>: ${state.fats} gr</li></ul>`

    return data
  }

  const getRichTextExtraData = () => {
    return {
      name: state.clientSelected.personalData.nombre,
      diffKg: getDifferenceBetween('weight'),
      diffWaist: getDifferenceBetween('waist'),
      neat: state.neat,
      endPhaseForm: {
        exerciseWaistPainful: state.exerciseWaistPainful,
        levelAdherence: state.levelAdherence,
        whyThatNote: state.whyThatNote,
        miniVictory: state.miniVictory,
        improvements: state.improvements,
        tweet: state.tweet,
        notes: state.notes,
      }
    }
  }

  const getDifferenceBetween = (attribute) => {
    const lastI = state.dataComparation.length - 1
    let preI = state.dataComparation.length - 2
    const lastValue = state.dataComparation[lastI][attribute]
    let preValue = state.dataComparation[preI][attribute]

    if (!preValue) {
      preI -= 1
      preValue = state.dataComparation[preI][attribute]
    }
    
    return (parseFloat(lastValue) - parseFloat(preValue)).toFixed(2)
  }

  const copyPreviousMacros = () => {
    const { prevCarbohydrates, prevProtein, prevFats } = state
    const copyMacros = {
      carbohydrates: prevCarbohydrates || 0,
      protein: prevProtein || 0,
      fats: prevFats || 0,
    }

    setState({ ...state, ...copyMacros })
  }

  return(
    <div className="container-with-dependency">
      {showPanel &&
        <div className="container-dependency-parent">
          <div className='sub-container-informacion employee-mode botton-position'>
          { edited && !props.block && <UpdateButtons saveData={saveData} undoData={()=>setState({...props})}/> }
            {props.clientSelected.currentPhase.idPhase === props.idPhase && props.clientSelected.currentPhase.step==="endPhase" &&
              <div className="current-icon employee-current"><DirectionsRunRounded /></div>
            }
            <p className='title-informacion'>Validación Check de fase</p>
            <div className="inputs-container">
              <Select title="Validar check" value={state.validated} options={optionsValidateInitialForm} onChange={validated => setState({ ...state, validated })}/>
              <Select title="Es MFP" value={state.isMfp} options={optionsMFPInitialForm} onChange={isMfp => setState({ ...state, isMfp })}/>
            </div>         

            {state.validated===optionsValidateInitialForm[2] &&
            <div className="inputs-container">
              <FormRichText title="Detalla la validación errónea:" value={state.wrongValidation} onChange={wrongValidation => setState({ ...state, wrongValidation })}/>
            </div>
          }

          {state.isMfp === optionsMFPInitialForm[0] && 
            <>
              <div className="inputs-container">
                <Input
                  title="Kcal"
                  value={state.kcal}
                  onChange={kcal => setState({ ...state, kcal })}
                />
                <Input
                  title="Proteínas"
                  value={state.protein}
                  onChange={protein => setState({ ...state, protein })}
                />
                <div className="copy-icon" onClick={copyPreviousMacros}>
                  <FileCopyRounded />
                </div>
              </div>
              <div className="inputs-container">
                <Input
                  title="Carbohidratos"
                  value={state.carbohydrates}
                  onChange={carbohydrates => setState({ ...state, carbohydrates })}
                />
                <Input
                  title="Grasas"
                  value={state.fats}
                  onChange={fats => setState({ ...state, fats })}
                />
              </div>
            </>
          }

          {state.validate===optionsValidateInitialForm[2] &&
            <div className="inputs-container">
              <FormRichText title="Detalla la validación errónea:" value={state.wrongValidation} onChange={wrongValidation => setState({ ...state, wrongValidation })}/>
            </div>
          }

          {props.validated===optionsValidateInitialForm[1] && state.validated===optionsValidateInitialForm[1] &&
            <>
              <br/>
              <p className='title-informacion'>Entrenamiento Fase {props.nextPhaseNumber}</p>

              <div className="inputs-container">
                <Select blocked={props.validated!==optionsValidateInitialForm[1]} title="Tipo de entrenamiento" value={state.trainingType} options={optionsTrainingTypes} onChange={trainingType => setState({ ...state, trainingType })}/>
                <Select blocked={props.validated!==optionsValidateInitialForm[1] || !optionsTrainingSubTypes?.[state?.trainingType]} title="Número de Fase" value={state.trainingSubType} options={optionsTrainingSubTypes?.[state?.trainingType] ?? []} onChange={trainingSubType => setState({ ...state, trainingSubType })}/>
              </div>
                
              <div className="inputs-container">
                <div className={`input-crm-component`}>
                  {/* <div class="title-input">Plan Nutricional Dia de Entrenamiento:</div> */}
                  <div class="title-input">Plan Nutricional:</div>
                  <div className="container-filepond-download-file">
                    {/* <FilePond id={'a'} files={state?.nutritionalPlanTraining?.size > 0 ? [()=>createFilePond(state.nutritionalPlanTraining)] : []} labelIdle='Selecciona un fichero' onupdatefiles={ ([file]) => setFile(file, "nutritionalPlanTraining")}/> */}
                    <FilePond id={'a'} files={nutritionalPlanTrainingFiles} labelIdle='Selecciona un fichero' onupdatefiles={ ([file]) => setFile(file, "nutritionalPlanTraining")}/>
                    {isEqual(props.nutritionalPlanTraining, nutritionalPlanTraining) && isFile(nutritionalPlanTraining) && 
                      <div className="container-icon-new-window">
                        <div onClick={() => openFile('nutritionalPlanTraining')}><OpenInNewRounded/></div>
                      </div>
                    }
                  </div>
                </div>
                
              </div>
              
              <br />
              
              <div>
                <div>
                  <div style={{
                    margin: '10px',
                    color: '#7b929d',
                    fontWeight: 500,
                    fontSize: '12px',
                    paddingTop: '10px',
                  }}>
                    Notas globales:
                  </div>
                  <div style={{
                    background: 'white',
                    padding: '20px 15px',
                    fontSize: '13px',
                    border: '2px solid #e6ecef',
                    margin: '0 10px',
                  }}>
                    {
                      ReactHtmlParser(state.globalNotes)
                    }
                  </div>
                </div>
                  
                <div className="inputs-container">
                  <FormRichText
                    title="Notas fase anterior"
                    extraDataTemplate={ getRichTextExtraData() }
                    value={state.editableNotes}
                    onChange={editableNotes => setState({ ...state, editableNotes })}
                  />
                </div>
              </div>

                
              {/* SI NO RECIBO DESDE PROPS TODO LO QUE TIENE QUE VER CON FASE 1 NO HABILITAMOS EL BOTTON DE ENVIAR LA NUEVA FASE.
                CUANDO ENVIEMOS ESTA FASE SE GUARDARÁ LA FECHA DE INICIO Y EMPEZAREMOS A CONTAR DESDE ALLI
              */}
              <br/>
              { (!edited && 
                props.validated===optionsValidateInitialForm[1] &&
                props.nextPhase?.training?.trainingSubType && 
                props.nextPhase?.training?.trainingType //&& 
                )  ?
                !props.nextPhase?.training?.startDate ?
                  <div className="display-flex-center margin-top-35" >
                    <div className="btn-confirm-phase" onClick={sendNewPhase}>
                      <div>Enviar fase</div>
                      <div className="icon-btn"><SendRounded /></div>
                    </div>
                  </div>
                :
                <div className="display-flex-center margin-top-35 sent-btn" >
                  <div className="btn-confirm-phase">
                    <div>Fase enviada</div>
                    <div className="icon-btn"><DoneRounded /></div>
                  </div>
                </div>
								: null
								
            }

            {state.validated===optionsValidateInitialForm[1] && props.nextPhase?.training?.startDate &&
              <>
                <p className='title-informacion'>Feedback de Check</p>

                <div className="inputs-container">
                  <FormRichText
                    title="Feedack de nutrición"
                    extraDataTemplate={ getRichTextExtraData() }
                    value={state.nutritionFeedack}
                    onChange={nutritionFeedack => setState({ ...state, nutritionFeedack })}
                  />
            
                  <FormRichText 
                    title="Feedack de varios" 
                    extraDataTemplate={ getRichTextExtraData() }
                    value={state.otherFeedack} 
                    onChange={otherFeedack => setState({ ...state, otherFeedack })}
                  />
                </div>
                
                { getStringHtml(props?.nutritionFeedack?.trim()) && getStringHtml(props?.otherFeedack?.trim()) ? 
                !props.feedbackSent ? 
                  <div className="display-flex-center margin-top-35" onClick={sentFeedback}>
                    <div className="btn-confirm-phase">
                      <div>Enviar feedback</div>
                      <div className="icon-btn"><SendRounded /></div>
                    </div>
                  </div>
                  :
                  <>
                    <div className="display-flex-center margin-top-35 sent-btn">
                      <div className="btn-confirm-phase">
                        <div>Feedback enviado</div>
                        <div className="icon-btn"><DoneRounded /></div>
                      </div>
                    </div>
                    {!edited &&
                      <div className="display-flex-center margin-top-35" onClick={sentFeedback}>
                        <div className="btn-confirm-phase">
                          <div>Enviar feedback</div>
                          <div className="icon-btn"><SendRounded /></div>
                        </div>
                      </div>
                    }
                </>
                  : null
                } 
              
              </>
            }
            </>
          }

          </div>
        </div>
      }

      <div className={`sub-container-informacion client-mode images-modes ${!showPanel ? 'hide-panel-component' : ''}`}>

        { edited && !props.block && <UpdateButtons saveData={saveData} undoData={()=>setState({...props})}/> }
        <div className="arrow-more-content" onClick={() => setShowPanel(!showPanel)}><ArrowRightRounded /></div>
        <p className='title-informacion'>
          {props.title}
          {props.startDate && <span className="date-title">{moment(props.startDate).format('DD/MM/YYYY')}</span> }
        </p>
        {props.clientSelected.currentPhase.idPhase === props.idPhase && props.clientSelected.currentPhase.step==="endPhase" &&
          <div className="current-icon"><DirectionsRunRounded /></div>
        }
        {showPanel && 
          <>
            <div className="inputs-container justify-space-between">

              {props?.dataComparation?.map( data => 
                <div className="list-comparation-data">
                  <div className="item-comparation">
                    <div className="title-comparation">Peso</div>
                    <div className="data-comparation">{`${data.weight} kg`}</div>
                    <div className="date-comparation">{data.startDate}</div>
                  </div>
                </div>
              )}

            </div>
            <br/>

            <div className="inputs-container justify-space-between">
              {props?.dataComparation?.map( data => 
                <div className="list-comparation-data">
                  <div className="item-comparation">
                    <div className="title-comparation">Cintura</div>
                    <div className="data-comparation">{`${data.waist} cm`}</div>
                    <div className="date-comparation">{data.startDate}</div>
                  </div>
                </div>
              )}
              
            </div>
            <br />
            
            <div className="phases-chart">
              <ChartWrapper />
            </div>

            <div className="inputs-container">
              <Input title="NEAT / Pasos diarios: ¿Cuál ha sido tu media?" value={state.neat} onChange={neat => setState({ ...state, neat })}/>
              <Input title="¿Ha habido algún ejercicio que te haya dado dolor articular?" value={state.exerciseWaistPainful} onChange={exerciseWaistPainful => setState({ ...state, exerciseWaistPainful })}/>
            </div>

            <div className="inputs-container">
              <Input title="¿Por qué te pondrías esa nota?" value={state.whyThatNote} onChange={whyThatNote => setState({ ...state, whyThatNote })}/>
              <Input title="Mini victoria" value={state.miniVictory} onChange={miniVictory => setState({ ...state, miniVictory })}/>
            </div>

            {state.isMfp === optionsMFPInitialForm[0] &&
            <>
              <div className="inputs-container">
                <Input title="Fiabilidad Macros" value={state.questionsMacrosQuality} onChange={questionsMacrosQuality => setState({ ...state, questionsMacrosQuality })}/>
                <Input title="Media Kcalorias objetivo" value={state.prevKcal} onChange={prevKcal => setState({ ...state, prevKcal })}/>
              </div>
              <div className="inputs-container">
                <Input title="Media Proteinas objetivo" value={state.prevProtein} onChange={prevProtein => setState({ ...state, prevProtein })}/>
                <Input title="Media Proteinas logradas" value={state.questionsProtein} onChange={questionsProtein => setState({ ...state, questionsProtein })}/>
              </div>
              <div className="inputs-container">
                <Input title="Media Carbohidratos objetivo" value={state.prevCarbohydrates} onChange={prevCarbohydrates => setState({ ...state, prevCarbohydrates })}/>
                <Input title="Media Carbohidratos logrados" value={state.questionsCarbohydrates} onChange={questionsCarbohydrates => setState({ ...state, questionsCarbohydrates })}/>
              </div>
              <div className="inputs-container">
                <Input title="Media Grasas objetivo" value={state.prevFats} onChange={prevFats => setState({ ...state, prevFats })}/>
                <Input title="Media Grasas logradas" value={state.questionsFats} onChange={questionsFats => setState({ ...state, questionsFats })}/>
              </div>
            </>
            }

            <br/>
            {showMore && 
              <>
                <div className="inputs-container">
                  <Input title="¿Has conseguido algún PB (Personal Best) este mes?" value={state.personalBest} onChange={personalBest => setState({ ...state, personalBest })}/>
                  <Input title="¿Cuál ha sido tu nivel de adherencia al plan nutricional?" value={state.levelAdherence} onChange={levelAdherence => setState({ ...state, levelAdherence })}/>
                </div>

                <div className="inputs-container">
                  <Input title="¿Qué crees que podría mejorar?" value={state.improvements} onChange={improvements => setState({ ...state, improvements })}/>
                  <Input title="Si tuvieras que resumir esta fase en un ‘tweet’, ¿cómo sería?" value={state.tweet} onChange={tweet => setState({ ...state, tweet })}/>
                </div>

                <div className="inputs-container">
                  <Input title="Prioridad en el entrenamiento en la siguiente fase" value={state.priority} onChange={priority => setState({ ...state, priority })}/>
                  <Input title="Notas" value={state.notes} onChange={notes => setState({ ...state, notes })}/>
                </div>

                
                <div className="inputs-container">
                  <Input title="Cuello" value={state.neck} onChange={neck => setState({ ...state, neck })}/>
                  <Input title="Pecho" value={state.chest} onChange={chest => setState({ ...state, chest })}/>
                </div>

                <div className="inputs-container">
                  <Input title="Brazo derecho" value={state.rightArm} onChange={rightArm => setState({ ...state, rightArm })}/>
                  <Input title="Brazo izquierdo" value={state.leftArm} onChange={leftArm => setState({ ...state, leftArm })}/>
                </div>

                <div className="inputs-container">
                  <Input title="Cintura (altura del ombligo)" value={state.waist1} onChange={waist1 => setState({ ...state, waist1 })}/>
                  <Input title="cintura (3 dedos por encima del ombligo)" value={state.waist2} onChange={waist2 => setState({ ...state, waist2 })}/>
                </div>
                <div className="inputs-container">
                  <Input title="cintura (3 dedos por debajo del ombligo )" value={state.waist3} onChange={waist3 => setState({ ...state, waist3 })}/>
                  <Input title="cadera" value={state.hip} onChange={hip => setState({ ...state, hip })}/>
                </div>

                <div className="inputs-container">
                  <Input title="Pierna derecha" value={state.rightLeg} onChange={rightLeg => setState({ ...state, rightLeg })}/>
                  <Input title="Pierna izquierda" value={state.leftLeg} onChange={leftLeg => setState({ ...state, leftLeg })}/>
                </div>

                <div className="inputs-container">
                  <Input title="Gemelo derecho" value={state.rightTwin} onChange={rightTwin => setState({ ...state, rightTwin })}/>
                  <Input title="Gemelo izquierdo" value={state.leftTwin} onChange={leftTwin => setState({ ...state, leftTwin })}/>
                </div>
              </>
              
            }

            <span className="show-more-text" onClick={()=>setShowMore(!showMore)} >{showMore ? 'Mostrar menos' : 'Mostrar mas' }</span>

            <ImagesComparation
              images={setImagesComparation(props?.dataComparation)}
            />
          </>
        }


      </div>
    
    </div>
  )
}

const mapStateToProps = ({clients, templates}) => ({
  clientSelected: clients.clientSelected,
  templates: templates.templates
});
const matchDispatchToProps = (dispatch) => {
	return {
    actions: bindActionCreators({
      getClients,
      updatePhase,
      setClientSelected,
      setNotification,
      setLoading
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(EndPhase);