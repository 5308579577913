import React, { useLayoutEffect } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Content from './Content/Content'
import SideBar from './SideBar/SideBar'
import { getClients } from '../../redux/actions/clientsActions'
import { getTemplates } from '../../redux/actions/templatesActions'

const Crm = ({actions}) => {

  useLayoutEffect(()=>{
    getData()
  }, [])
  
  const getData = async () => {
    await actions.getTemplates()
    await actions.getClients()
  }
  
  return(
    <div className="crm-root">
      <SideBar/>
      <Content />
    </div>
  )
}

const matchDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    getClients,
    getTemplates
  }, dispatch)	
})


export default connect(null, matchDispatchToProps)(Crm);