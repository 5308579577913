import React, { useLayoutEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setClientSelected, setPanelSelected as setPanelSelectedClient, getPhases } from '../../../../../redux/actions/clientsActions';
import { setPanelSelected as setPanelSelectedCrm } from '../../../../../redux/actions/crmActions'
import { setNotification, setLoading } from '../../../../../redux/actions/globalActions'
import { panelsClients, panelsCrm } from '../../../../../utils/constans'
import {Avatar} from '@material-ui/core';

import { KeyboardArrowRightRounded } from '@material-ui/icons'

const Clients = ({ breadcrums, clients, clientSelected, actions }) => {

  const [filteredClients, setFilteredClients] = useState({})

  useLayoutEffect(()=> {
    getFilteredClients()
  }, [breadcrums, clients])

  const getFilteredClients = () => {
    if(breadcrums.length === 2){
      setFilteredClients(breadcrums[breadcrums.length -1].data)
    }
  }

  const selectClient = (idClient) => {
    actions.setLoading(true)
    actions.getPhases({id :idClient })
    .then(({ phases })=>{
      actions.setLoading(false)
      actions.setPanelSelectedCrm(panelsCrm.CLIENTS)
      actions.setClientSelected({value: {...clients[idClient], phases }})
      actions.setPanelSelectedClient(panelsClients.INITIAL_FORM)
    })
    .catch(error => {
      console.log(error);
      actions.setLoading(false)
      actions.setNotification({ visibility: true, text: "Error al pedir el cliente", status: "close", moment: Date.now() })
    })
  }

  return(
    <div className="content-clients-chat box-crm">
  <div className="panel-list-clients">

    <table id='table-clients'>

      <thead>
        <tr>
          <th></th>
          <th></th>
          <th>Nombre</th>
          <th>Teléfono móvil</th>
          <th>Email</th>
          <th>Fase actual</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        {
        Object.entries(filteredClients).map( ( [index, { idClient }] ) => {
          const client = clients[idClient]
          return (
            <tr key={index} className={`${clientSelected?.idClient === idClient ? 'active-row' : ''}`}>
              <td className="avatar-td">
                {/* <Avatar className="row-avatar">
                  AP
                </Avatar> */}
              </td>
              <td></td>
              <td>{client?.personalData?.nombre ?? '-'} {client?.personalData?.apellidos}</td>
              <td>{client?.personalData?.telefono ?? '-'}</td>
              <td>{client?.personalData?.email ?? '-'}</td>
              <td>-</td>
              <td onClick={() => selectClient(client.idClient)}>
                <div className='more' >
                  <KeyboardArrowRightRounded />
                </div>
              </td>
            </tr>
          )
        })
          
        }
      </tbody>

    </table>

  </div>
  </div>
  )
}


const mapStateToProps = ({ dashboard, clients }) => ({
  breadcrums: dashboard.breadcrums,
  clients: clients.clients,
  clientSelected: clients.clientSelected
});
const matchDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setNotification,
    setLoading,
    setClientSelected,
    setPanelSelectedClient,
    getPhases,
    setPanelSelectedCrm
  }, dispatch)	
})

export default connect(mapStateToProps, matchDispatchToProps)(Clients);