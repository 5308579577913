const ethosClient = require('doce-client').createClient()

export const signIn = ({ data }) => async (dispatch) => {
  try {
    const { body } = await ethosClient.loginEmployee({ data })
    const { employee } = body
    return { employee }
  } catch (error) {
    console.log(error);
    return error
  }
};