import React from 'react'
import { FitnessCenterRounded } from '@material-ui/icons'
import { SearchRounded, NotificationImportantRounded } from '@material-ui/icons'
import { Badge } from '@material-ui/core';
import HeaderUser from '../../../../Shared/HeaderUser';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Breadcrumbs } from '@material-ui/core'
import { panelsDashboard } from '../../../../../utils/constans'
import { NavigateNextRounded, ListRounded, FilterListRounded, AddRounded, AssignmentIndRounded } from '@material-ui/icons'
import { setPanelSelected } from '../../../../../redux/actions/dashboardActions'

const Header = ({ breadcrums, panelSelected, actions }) => {
  const changePanel = (panel) => {
    actions.setPanelSelected(panel)
  }

  return(
    <div className="header-crm">
      <div className="box-crm pr header-clients">
        <div className="camino-de-migas">
          <Breadcrumbs separator={<NavigateNextRounded fontSize="big" arial-label="breadcrumb" className="breadcrumbs-customized" />}>
            {breadcrums && breadcrums.length > 0 && (
              breadcrums.map( (panel, key) => {
                return(
                  <div key={key}>
                    {panel.text}
                  </div>
                )
              })
            )}
          </Breadcrumbs>
        </div>

        <div className="panels-selector">
          <div className={`tab-header-item ${panelSelected===panelsDashboard.INDICATORS?'active-panel':''}`} >
            <div onClick={()=>changePanel(panelsDashboard.INDICATORS)}>Indicadores</div>
            <div>{panelSelected===panelsDashboard.INDICATORS && <ListRounded/>}</div>
          </div>
          <div className={`tab-header-item ${panelSelected===panelsDashboard.CLIENTS?'active-panel':''}`} >
            <div onClick={()=>changePanel(panelsDashboard.CLIENTS)}>Clientes</div>
            <div>{panelSelected===panelsDashboard.CLIENTS && <AssignmentIndRounded/>}</div>
          </div>
        </div>

      </div>
    </div>
  )
}

const mapStateToProps = ({dashboard}) => ({
  breadcrums: dashboard.breadcrums,
  panelSelected: dashboard.panelSelected
});

const matchDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    setPanelSelected
  }, dispatch)	
})


export default connect(mapStateToProps, matchDispatchToProps)(Header);