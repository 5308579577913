import Phase from "./Phase"
import { optionsMFPInitialForm } from '../utils/constans'
class Client {

  idClient = null
  isMfp = optionsMFPInitialForm[1]
  status = "NEW"
  personalData = {
    nombre: '',
    apellidos: '',
    email: '',
    emailFacebook: '',
    telefono: '',
    sexo: '',
    fechaNacimiento: '',
    altura: '',
    peso: '',
  }

  currentPhase = {
    idPhase: null,
    phaseNumber: 0,
    step: ""
  }

  editableNotes = ''
  globalNotes = ''
  frozenNotes = ''

  extraData = {
    todayWeight:{
      formatedDate : null,
      date: null,
      weight: null
    }
  }

  initialForm = {
    createdDate: null,
    validate: "",
    wrongValidation: "",
    questions: {

      "Df8wey8XJola": "",
      "JteVM0TgOwZL": "",
      "pBFjoOOwnLDK": "",
      "YIseKniLSIJg": "",
      "OsP3adUakyP7": "",
      "ZsihWuXobwQA": "",
      "g5IyfCuAYqpu": "",
      "UeWRML5xeKni": "",
      "pgcEflgaqTna": "",
      "QTPnJtPGXWES": "",
      "ZbyxfGvX63T5": "",
      "nz8tIQ2EnI77": "",
      "yY7utz84toGe": [],
      "q2wVZ4lDYK7F": "",
      "Bbzd6GgaupkA": 0,
      "gYrMOExSQqIC": [],
      "NMX6GijzyKcL": [],
      "N3hD8VY5leEx": "",
      "G1gueZJZksM8": [],
      "t05wBHZQUEvn": "",
      "nsK3lDEDwPVq": "",
      "nsK3lDEDwPVq2": "",
      "ZQqr0x4In82j": [],
      "dHHM6bAhqV50": 0,
      "A9XVlcIWM8ae": "",
      "ql3XA74QVOnI": "",
      "bM8oiKOgJ9WG": "",
      "GuKUGqLklj6d": "",
      "eWGJ3iA8Kriu": [],
      "UUbjFLnSmx4b": [],
      "lMdSLTCS4UAS": "",
      "CxCZmYVM1aOa": "",
      "fy65rYUnke9V": [],
      "CQXXHU4mXI0T": "",
      "mk3A5XQBnUvE": "",
      "D7xstQOckdC6": "",
      "ncfcJReFW7yq": [],  
      "uMxhBzZalY1z": "",
      "FWtAUOu3bafX": [],
      "Iyuk7Zq0Zdjl": "",
      "QN4qIJrO1EG2": [],
      "c8Txs4vdWwBA": "",
      "BIuyl0Yjty3G": "",
      "iq4IxEfkeCcm": [],
      "AiRWLwoi4JO2": [],
      "LZprE2aCb0kp": [],
      "UYCE5Alpl5Vt": [],
      "qKnEwiAQwDVZ": [],
      "etYYvfVTkf9I": [],
      "mjbZQ2rqmxUb": "",
      "zbHNDQCSNR4V": { 
          name: "",
          lastModified: 0,
          size: 0,
          type: "",
          data: ""
      },
      "KIeyI6xuGCGo": "",
      "V6Du3glNogcY": "",
      "qcKDSKj6ljHR": "",
      "Fbc3AK5TGdJY": [],
      "W9gilDbTlOSf": "",//number  
      "QZUtBhsk1T0Y": [],
      "oihOfMsFbofq": [],
      "dZW7vYObZCch": [],
      "u8rBuN9GFBAl": [],
      "g0xmZHsgLjGh": "",
      "LB2QMKD2OrNp": "",
      "Sdm5MDfdPvaR": [],
      "aLDwtXvDCvtf": [],
      "FMAl2pi6A2bd": [],
      "GYLbOUOiaoUQ": [],
      "vGfUoUTFxZCh": "",
      "d8FlbrQBAiP2": "",
      "TRPjdGyCgOys": "",
      "cFC66g9KrUj8": "",//number
      "BPCehBIkq1nh": "",//number
      "KtPqiaXbFL0e": "",//number
      "SltYsNNkPJUe": "",//number
      "g9KYAOQnLIUu": "",//number
      "XdIFDnW5Tdbq": "",//number  
      "yNDoplLOYiaE": "",//number
      "iqlUkjDZw0vs": "",//number
      "k2tkczvqdlpX": "",//number
      "aC8WQq4GIXM7": "",//number
      "aL8LuXdlA8MY": "",//number
      "mygUBckFChue": "",//number
      
      "A0r8W8s2ZHpd": "",
      "fQJBGRlsZnvl": "",
      "DClriwj3KyQt": "",
      "j38ijKnLsNSx": "",
      "DLaraJgGmSZW": "",
      "D5uNflxUPF33": "",
      "WPwATE18TdVe": "",
    }
  }

  phases = {}

  histories = []


  constructor(obj){
    if(obj){
      this.idClient = obj.idClient
      this.status = obj?.status ?? this.status
      this.isMfp = obj?.isMfp ?? this.isMfp
      this.personalData = !obj.personalData ? this.personalData : {
        ...this.personalData, 
        ...obj.personalData, 
        nombreCompleto: `${obj.personalData.nombre ?? this.personalData.nombre} ${obj.personalData.apellidos ?? this.personalData.apellidos}`
      }
      this.currentPhase = obj?.currentPhase ?? this.currentPhase
      this.extraData = !obj?.extraData ? this.extraData : {
        todayWeight: {
          formatedDate : obj.extraData?.todayWeight?.formatedDate ?? this.extraData?.todayWeight?.formatedDate,
          date: obj.extraData?.todayWeight?.date ?? this.extraData?.todayWeight?.date,
          weight: obj.extraData?.todayWeight?.weight ?? this.extraData?.todayWeight?.weight,
        }
      }
      this.initialForm = !obj.initialForm ? this.initialForm : 
      {...this.initialForm,
        ...obj.initialForm,
        questions: { ...this.initialForm.questions, ...obj.initialForm.questions }
      }
      if(obj.phases){
        let phases = {}
        Object.entries(obj.phases).map(([idPhase, phase]) => phases[idPhase] = new Phase(phase))
        this.phases = phases
      }
      this.histories = obj?.histories ?? this.histories
      
      this.editableNotes = obj?.editableNotes ?? this.editableNotes
      this.globalNotes = obj?.globalNotes ?? this.globalNotes
      this.frozenNotes = obj?.frozenNotes ?? this.frozenNotes
    }
  }

}

export default Client