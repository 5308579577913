import React from 'react'

const TableMovingAverage = ({ data }) => {

	const parseDataTable = () => {
		const { datasets, labels } = data
		const dataset = datasets[0].data
		const result = []

		for (let i = 7; i < labels.length; i++) {
			result.push({
				date: labels[i],
				recorded: Number.parseFloat(dataset[i].y),
				movingAverage: calculateWeeklyMovingAverage(i, dataset).toFixed(2)
			})
		}

		return result
	}

	const calculateWeeklyMovingAverage = (index, dataset) => {
		const sumAll = dataset.slice(index - 7, index).reduce((a, b) => {
			return a + Number.parseFloat(b.y)
		}, 0)
		return sumAll / 7
	}

	return (
		<table id='table-clients'>
			<thead>
				<tr>
					<th>Fecha</th>
					<th>Kg</th>
					<th>Media Móvil</th>
					<th>Diff</th>
				</tr>
			</thead>

			<tbody>
				{
					parseDataTable().map(({ date, recorded, movingAverage }) => {
						
						return (
							<tr>
								<td>{date}</td>
								<td>{recorded}</td>
								<td>{movingAverage}</td>
								<td>{(recorded - movingAverage).toFixed(2) }</td>
							</tr>
						)
					})
				}
			</tbody>

		</table>
	)
}

export default (TableMovingAverage)