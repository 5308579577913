const ethosClient = require('doce-client').createClient()

export const getAnalytics = ({ id }) => async (dispatch) => {
	try {
		const { body } = await ethosClient.getAnalytics({ id })
		return Promise.resolve(body)
	} catch (error) {
		return Promise.reject(error)
	}
}
