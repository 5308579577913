import React, { useState, useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEqual, isEmpty } from 'underscore'
import { FileCopyRounded } from '@material-ui/icons'
import Input from '../../../../Shared/Input'
import Select from '../../../../Shared/Select'
import UpdateButtons from '../../../../Shared/UpdateButtons'
import { optionsValidateInitialForm, optionsMFPInitialForm, emailsIds } from '../../../../../utils/constans'
import FormRichText from '../../../../Shared/FormRichText'

import ImagesComparation from '../../../../Shared/ImagesComparation'

import { ArrowRightRounded, DoneRounded, SendRounded, DirectionsRunRounded } from '@material-ui/icons'
import { setImagesComparation, getStringHtml } from '../../../../../utils/functions';
import { updatePhase } from '../../../../../redux/actions/phasesActions'
import { getClients, setClientSelected } from '../../../../../redux/actions/clientsActions'
import { setNotification, setLoading } from '../../../../../redux/actions/globalActions'
import History from '../../../../../models/History'
import moment from 'moment'

import ChartWrapper from './components/ChartWrapper'

const Check = (props) => {

  const [showPanel, setShowPanel] = useState(false)

  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({ ...props })

  useEffect(() => { setEdited(!isEqual(state, props)) }, [state])
  useEffect(() => { setState({ ...props }) }, [props])
  useEffect(() => {
    if (!state.carbohydrates || !state.fats || !state.protein) return

    const kcal = Math.round(state.carbohydrates*4 + state.protein*4 + state.fats*9)
    setState({ ...state, kcal })
  }, [state.carbohydrates, state.protein, state.fats])

  const [showMore, setShowMore] = useState(false)

  const saveData = () => {
    let multiPath = {}, multiPathClient = {}, multiPathHistory = []
    
    if(props.validated!==state.validated) {
      multiPath[`check.validated`] = state.validated
      if(state.validated === optionsValidateInitialForm[1]){
        multiPathClient["status"] = "WAITING_FEEDBACK"
        multiPathHistory.push(new History({
          idClient: props.clientSelected.idClient,
          category: `PHASE_${props.phaseNumber}`,
          from: "EMPLOYEE",
          to: "CLIENT",
          createdBy: "SYSTEM",
          metadata: {
            //idTemplate: emailsIds.verificatedInitialForm,
            subject: "Check validado",
            content: props?.templates?.[emailsIds.checkSent]?.content?.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates ,
            notification: true,
            hiddenResponse: true,
            sent:{
              email: props.clientSelected.personalData.email,
              msg: props?.templates?.[emailsIds.nofiticationEmail]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates
            }
          }
        }))
      }else if(state.validated === optionsValidateInitialForm[2]){
        multiPathClient["status"] = "CHECK_WRONG_VALIDATED"
        multiPathHistory.push(new History({
          idClient: props.clientSelected.idClient,
          category: `PHASE_${props.phaseNumber}`,
          from: "EMPLOYEE",
          to: "CLIENT",
          createdBy: "SYSTEM",
          metadata: {
            idPhase: props.idPhase,
            step: "check",
            subject: "Errores en el check",
            content: state?.wrongValidation.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates,
            notification: true,
            button:{
              text: "Check 2 semanas",
              action: "showCheck"
            },
            sent:{
              email: props.clientSelected.personalData.email,
              msg: props?.templates?.[emailsIds.nofiticationEmail]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates
            }
          }
        }))
      }
    }
    if(props.wrongValidation !== state.wrongValidation) multiPath[`check.wrongValidation`] = state.wrongValidation
    
    
    if(props.nutritionFeedack !== state.nutritionFeedack) multiPath[`check.nutritionFeedack`] = state.nutritionFeedack
    if(props.otherFeedack !== state.otherFeedack) multiPath[`check.otherFeedack`] = state.otherFeedack
    
    if(props.editableNotes !== state.editableNotes) multiPath[`editableNotes`] = state.editableNotes

    if(props.kcal !== state.kcal) multiPath[`check.mfp.kcal`] = +state.kcal
    if(props.protein !== state.protein) multiPath[`check.mfp.protein`] = +state.protein
    if(props.carbohydrates !== state.carbohydrates) multiPath[`check.mfp.carbohydrates`] = +state.carbohydrates
    if(props.fats !== state.fats) multiPath[`check.mfp.fats`] = +state.fats

    if(props.questionsMacrosQuality !== state.questionsMacrosQuality) multiPath[`check.questions.questionsMacrosQuality`] = +state.questionsMacrosQuality
    if(props.questionsProtein !== state.questionsProtein) multiPath[`check.questions.protein`] = +state.questionsProtein
    if(props.questionsCarbohydrates !== state.questionsCarbohydrates) multiPath[`check.questions.carbohydrates`] = +state.questionsCarbohydrates
    if(props.questionsFats !== state.questionsFats) multiPath[`check.questions.fats`] = +state.questionsFats
    
    if(props.isMfp !== state.isMfp ) multiPathClient[`isMfp`] = state.isMfp
    let payload = {
      id: props.idPhase,
      data: { 
        body: { 
          ...multiPath,
          idClient: props.idClient,
          client: multiPathClient,
          history: Object.keys(multiPathHistory).length>0 ? multiPathHistory : null,
        } 
      }
    }
    props.actions.setLoading(true)
    props.actions.updatePhase(payload)
    .then(({ phase, client }) => {
      props.actions.getClients()
      props.actions.setClientSelected({path:`phases.${phase.idPhase}`, value: phase})
      if(client?.isMfp === optionsMFPInitialForm[0]){
        props.actions.setClientSelected({path:`isMfp`, value: client?.isMfp})
      }
      props.actions.setNotification({ visibility: true, text: "Se ha guardado correctamente", status: "done", moment: Date.now() })
      props.actions.setLoading(false)
    })
    .catch( error => {
      props.actions.setLoading(false)
      props.actions.setNotification({ visibility: true, text: "Ha ocurrido un error", status: "close", moment: Date.now() })
    })

  }

  const sentFeedback = () => {
    props.actions.setLoading(true)

    let multiPathClient = {}, multiPathPhase = {}, multiPathHistory = []
    //cambios en el CLIENT
    multiPathClient["status"] = "IN_PROGRESS"
    //Cambios en la PHASE
    multiPathPhase["check.feedbackSent"] = "date:now"
    //Cambios en el HISTORY
    multiPathHistory.push(new History({
      idClient: props.idClient,
      category: `PHASE_${props.phaseNumber}`,
      from: "EMPLOYEE",
      to: "CLIENT",
      createdBy: "SYSTEM",
      metadata: {
        //idTemplate: "528c6498-0b75-4893-b6a5-ee1dff0c8b76",
        subject: `Feedback del check Fase ${props.phaseNumber}`,
        content: `${state.nutritionFeedack}</br>${state.otherFeedack}
                  ${state.isMfp === optionsMFPInitialForm[0] ? isEmptyMfp() ? getMfpData() : '' : ''}`,
        notification: true,
        hiddenOkButton: true,
        sent:{
          email: props.clientSelected.personalData.email,
          msg: props?.templates?.[emailsIds.nofiticationEmail]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates
        }
      }
    }))
    const payload = {
      id: props.idPhase,
      data: { 
        body: {
          ...multiPathPhase,
          idClient: props.idClient,
          client: multiPathClient,
          history: multiPathHistory
        } 
      }
    }
    props.actions.updatePhase(payload)
    .then( ({ phase, client }) => {
      props.actions.setClientSelected({path:`phases.${phase.idPhase}`, value: phase})
      props.actions.setNotification({ visibility: true, text: "Se ha enviado correctamente", status: "done", moment: Date.now() })
      props.actions.setLoading(false)
    })
  }

  const isEmptyMfp = () => {
    return !isEmpty(state.kcal) || !isEmpty(state.protein) || !isEmpty(state.carbohydrates) || !isEmpty(state.fats)
  }

  const getMfpData = () => {
    let data = `</br>Tus macros son:</br><br>
    <ul><li>Media de <b>Calorías</b>: ${state.kcal} </li>
        <li>Media de <b>Proteinas</b>: ${state.protein} gr</li>
        <li>Media de <b>Carbohidratos</b>: ${state.carbohydrates} gr</li>
        <li>Media de <b>Grasas</b>: ${state.fats} gr</li></ul>`

    return data
  }

  const getRichTextExtraData = () => {
    return {
      name: state.clientSelected.personalData.nombre,
      diffKg: getDifferenceBetween('weight'),
      diffWaist: getDifferenceBetween('waist'),
      neat: state.neat,
      endPhaseForm: {
        exerciseWaistPainful: state.exerciseWaistPainful,
        levelAdherence: state.levelAdherence,
        whyThatNote: state.whyThatNote,
        miniVictory: state.miniVictory,
        improvements: state.improvements,
        tweet: state.tweet,
        notes: state.notes,
      }
    }
  }

  const getDifferenceBetween = (attribute) => {
    const lastI = state.dataComparation.length - 1
    const preI = state.dataComparation.length - 2
    const lastValue = state.dataComparation[lastI][attribute]
    const preValue = state.dataComparation[preI][attribute]
    
    return (parseFloat(lastValue) - parseFloat(preValue)).toFixed(2)
  }

  const copyPreviousMacros = () => {
    const { prevCarbohydrates, prevProtein, prevFats } = state
    const copyMacros = {
      carbohydrates: prevCarbohydrates || 0,
      protein: prevProtein || 0,
      fats: prevFats || 0,
    }

    setState({ ...state, ...copyMacros })
  }

  return (
    <div className="container-with-dependency">

      {showPanel &&
        <div className="container-dependency-parent">
          <div className='sub-container-informacion employee-mode botton-position'>
          { edited && !props.block && <UpdateButtons saveData={saveData} undoData={()=>setState({...props})}/> }
          {props.clientSelected.currentPhase.idPhase === props.idPhase && props.clientSelected.currentPhase.step==="check" &&
            <div className="current-icon employee-current"><DirectionsRunRounded /></div>
          }
          <p className='title-informacion'>Validación Check de fase</p>

          <div className="inputs-container">
            <Select title="Validar check" value={state.validated} options={optionsValidateInitialForm} onChange={validated => setState({ ...state, validated })}/>
            <Select title="Es MFP" value={state.isMfp} options={optionsMFPInitialForm} onChange={isMfp => setState({ ...state, isMfp })}/>
          </div>
          {state.isMfp === optionsMFPInitialForm[0] && 
            <>
              <div className="inputs-container">
                <Input 
                  title="Kcal"
                  value={state.kcal}
                  onChange={kcal => setState({ ...state, kcal })}
                />
                <Input
                  title="Proteínas"
                  value={state.protein}
                  onChange={protein => setState({ ...state, protein })}
                />
                <div className="copy-icon" onClick={copyPreviousMacros}>
                  <FileCopyRounded />
                </div>
              </div>
              <div className="inputs-container">
                <Input
                  title="Carbohidratos"
                  value={state.carbohydrates}
                  onChange={carbohydrates => setState({ ...state, carbohydrates })}
                />
                <Input
                  title="Grasas"
                  value={state.fats}
                  onChange={fats => setState({ ...state, fats })}
                />
              </div>
            </>
          }
          {props.validated===optionsValidateInitialForm[1] &&
            <>
              <br/>
              <p className='title-informacion'>Feedback de Check</p>
              <div className="inputs-container">
                <FormRichText
                  title="Feedack de nutrición"
                  extraDataTemplate={ getRichTextExtraData() }
                  value={state.nutritionFeedack}
                  onChange={nutritionFeedack => setState({ ...state, nutritionFeedack })}
                />
              
                <FormRichText
                  title="Feedack de varios"
                  extraDataTemplate={ getRichTextExtraData() }
                  value={state.otherFeedack}
                  onChange={otherFeedack => setState({ ...state, otherFeedack })}
                />
              </div>
              
              <br />
            
              <div>
                <div>
                  <div style={{
                    margin: '10px',
                    color: '#7b929d',
                    fontWeight: 500,
                    fontSize: '12px',
                    paddingTop: '10px',
                  }}>
                    Notas globales:
                  </div>
                  <div style={{
                    background: 'white',
                    padding: '20px 15px',
                    fontSize: '13px',
                    border: '2px solid #e6ecef',
                    margin: '0 10px',
                  }}>
                    {
                      ReactHtmlParser(state.globalNotes)
                    }
                  </div>
                </div>
                  
                <div className="inputs-container">
                  <FormRichText
                    title="Notas fase anterior"
                    extraDataTemplate={ getRichTextExtraData() }
                    value={state.editableNotes}
                    onChange={editableNotes => setState({ ...state, editableNotes })}
                  />
                </div>
              </div>
            </>
          }

            {state.validated===optionsValidateInitialForm[2] &&
              <div className="inputs-container">
                <FormRichText 
                  title="Detalla la validación errónea:" 
                  extraDataTemplate={ getRichTextExtraData() }
                  value={state.wrongValidation} 
                  onChange={wrongValidation => setState({ ...state, wrongValidation })}
                />
              </div>
            }

          

          <br />
          

          { getStringHtml(props?.nutritionFeedack?.trim()) && getStringHtml(props?.otherFeedack?.trim()) ? 
            !props.feedbackSent ? 
              <div className="display-flex-center margin-top-35" onClick={sentFeedback}>
                <div className="btn-confirm-phase">
                  <div>Enviar feedback</div>
                  <div className="icon-btn"><SendRounded /></div>
                </div>
              </div>
            :
            <>
            
              <div className="display-flex-center margin-top-35 sent-btn">
                <div className="btn-confirm-phase">
                  <div>Feedback enviado</div>
                  <div className="icon-btn"><DoneRounded /></div>
                </div>
              </div>
              {!edited &&
                <div className="display-flex-center margin-top-35" onClick={sentFeedback}>
                  <div className="btn-confirm-phase">
                    <div>Enviar feedback</div>
                    <div className="icon-btn"><SendRounded /></div>
                  </div>
                </div>
              }

            </>
            : null
          }

          </div>
        </div>
      }

      <div className={`sub-container-informacion client-mode images-modes ${!showPanel ? 'hide-panel-component' : ''}`}>

        {/* { edited && !props.block && <UpdateButtons saveData={saveData} undoData={()=>setState({...props})}/> } */}
        <div className="arrow-more-content" onClick={() => setShowPanel(!showPanel)}><ArrowRightRounded /></div>
        <p className='title-informacion'>
          {props.title}
          {props.startDate && <span className="date-title">{moment(props.startDate).format('DD/MM/YYYY')}</span> }
        </p>
        {props.clientSelected.currentPhase.idPhase === props.idPhase && props.clientSelected.currentPhase.step==="check" &&
          <div className="current-icon"><DirectionsRunRounded /></div>
        }
        {showPanel &&
          <>
            <div className="inputs-container justify-space-between">

              {props?.dataComparation?.map( data => 
                <div className="list-comparation-data">
                  <div className="item-comparation">
                    <div className="title-comparation">Peso</div>
                    <div className="data-comparation">{`${data.weight} kg`}</div>
                    <div className="date-comparation">{data.startDate}</div>
                  </div>
                </div>
              )}

            </div>
            <br/>

            <div className="inputs-container justify-space-between">
              {props?.dataComparation?.map( data => 
                <div className="list-comparation-data">
                  <div className="item-comparation">
                    <div className="title-comparation">Cintura</div>
                    <div className="data-comparation">{`${data.waist} cm`}</div>
                    <div className="date-comparation">{data.startDate}</div>
                  </div>
                </div>
              )}
              
            </div>
            <br />
            
            <div className="phases-chart">
              <ChartWrapper />
            </div>

            <div className="inputs-container">
              <Input title="NEAT / Pasos diarios: ¿Cuál ha sido tu media?" value={state.neat} onChange={neat => setState({ ...state, neat })}/>
              <Input title="¿Ha habido algún ejercicio que te haya dado dolor articular?" value={state.exerciseWaistPainful} onChange={exerciseWaistPainful => setState({ ...state, exerciseWaistPainful })}/>
            </div>

            <div className="inputs-container">
              <Input title="¿Por qué te pondrías esa nota?" value={state.whyThatNote} onChange={whyThatNote => setState({ ...state, whyThatNote })}/>
              <Input title="Mini victoria" value={state.miniVictory} onChange={miniVictory => setState({ ...state, miniVictory })}/>
            </div>

            {state.isMfp === optionsMFPInitialForm[0] &&
            <>
              <div className="inputs-container">
                <Input title="Fiabilidad Macros" value={state.questionsMacrosQuality} onChange={questionsMacrosQuality => setState({ ...state, questionsMacrosQuality })}/>
                <Input title="Media Kcalorias objetivo" value={state.prevKcal} onChange={prevKcal => setState({ ...state, prevKcal })}/>
              </div>
              <div className="inputs-container">
                <Input title="Media Proteinas objetivo" value={state.prevProtein} onChange={prevProtein => setState({ ...state, prevProtein })}/>
                <Input title="Media Proteinas logradas" value={state.questionsProtein} onChange={questionsProtein => setState({ ...state, questionsProtein })}/>
              </div>
              <div className="inputs-container">
                <Input title="Media Carbohidratos objetivo" value={state.prevCarbohydrates} onChange={prevCarbohydrates => setState({ ...state, prevCarbohydrates })}/>
                <Input title="Media Carbohidratos logrados" value={state.questionsCarbohydrates} onChange={questionsCarbohydrates => setState({ ...state, questionsCarbohydrates })}/>
              </div>
              <div className="inputs-container">
                <Input title="Media Grasas objetivo" value={state.prevFats} onChange={prevFats => setState({ ...state, prevFats })}/>
                <Input title="Media Grasas logradas" value={state.questionsFats} onChange={questionsFats => setState({ ...state, questionsFats })}/>
              </div>
            </>
            }

            <br/>
            {showMore && 
              <>
                <div className="inputs-container">
                  <Input title="¿Has conseguido algún PB (Personal Best) este mes?" value={state.personalBest} onChange={personalBest => setState({ ...state, personalBest })}/>
                  <Input title="¿Cuál ha sido tu nivel de adherencia al plan nutricional?" value={state.levelAdherence} onChange={levelAdherence => setState({ ...state, levelAdherence })}/>
                </div>

                <div className="inputs-container">
                  <Input title="¿Qué crees que podría mejorar?" value={state.improvements} onChange={improvements => setState({ ...state, improvements })}/>
                  <Input title="Si tuvieras que resumir esta fase en un ‘tweet’, ¿cómo sería?" value={state.tweet} onChange={tweet => setState({ ...state, tweet })}/>
                </div>

                <div className="inputs-container">
                  <Input title="Prioridad en el entrenamiento en la siguiente fase" value={state.priority} onChange={priority => setState({ ...state, priority })}/>
                  <Input title="Notas" value={state.notes} onChange={notes => setState({ ...state, notes })}/>
                </div>

                
                <div className="inputs-container">
                  <Input title="Cuello" value={state.neck} onChange={neck => setState({ ...state, neck })}/>
                  <Input title="Pecho" value={state.chest} onChange={chest => setState({ ...state, chest })}/>
                </div>

                <div className="inputs-container">
                  <Input title="Brazo derecho" value={state.rightArm} onChange={rightArm => setState({ ...state, rightArm })}/>
                  <Input title="Brazo izquierdo" value={state.leftArm} onChange={leftArm => setState({ ...state, leftArm })}/>
                </div>

                <div className="inputs-container">
                  <Input title="Cintura (altura del ombligo)" value={state.waist1} onChange={waist1 => setState({ ...state, waist1 })}/>
                  <Input title="cintura (3 dedos por encima del ombligo)" value={state.waist2} onChange={waist2 => setState({ ...state, waist2 })}/>
                </div>
                <div className="inputs-container">
                  <Input title="cintura (3 dedos por debajo del ombligo )" value={state.waist3} onChange={waist3 => setState({ ...state, waist3 })}/>
                  <Input title="cadera" value={state.hip} onChange={hip => setState({ ...state, hip })}/>
                </div>

                <div className="inputs-container">
                  <Input title="Pierna derecha" value={state.rightLeg} onChange={rightLeg => setState({ ...state, rightLeg })}/>
                  <Input title="Pierna izquierda" value={state.leftLeg} onChange={leftLeg => setState({ ...state, leftLeg })}/>
                </div>

                <div className="inputs-container">
                  <Input title="Gemelo derecho" value={state.rightTwin} onChange={rightTwin => setState({ ...state, rightTwin })}/>
                  <Input title="Gemelo izquierdo" value={state.leftTwin} onChange={leftTwin => setState({ ...state, leftTwin })}/>
                </div>
              </>
            }

            <span className="show-more-text" onClick={()=>setShowMore(!showMore)} >{showMore ? 'Mostrar menos' : 'Mostrar mas' }</span>

            <ImagesComparation
              images={setImagesComparation(props?.dataComparation)}
            />
            <br/>
            <br/>
          </>
        }


      </div>
    
    </div>
  )
}
const mapStateToProps = ({clients, templates}) => ({
  clientSelected: clients.clientSelected,
  templates: templates.templates
});

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      updatePhase,
      getClients,
      setClientSelected,
      setNotification,
      setLoading
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(Check);