import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PersonalData from './PersonalData'

const Information = ({ clientSelected }) => (
  <div className="panel-list-clients panel-data">
    <div className='container-informacion'>
      <PersonalData 
        title = '1. Datos personales' 
        idClient = {clientSelected.idClient}
        nombre = {clientSelected.personalData.nombre}
        apellidos = {clientSelected.personalData.apellidos}
        email = {clientSelected.personalData.email}
        emailFb = {clientSelected.personalData.emailFb}
        telefono = {clientSelected.personalData.telefono}
        sexo={clientSelected.personalData.sexo}
        globalNotes={clientSelected.globalNotes}
        frozenNotes={clientSelected.frozenNotes}
        status={clientSelected.status}
        clientSelected={clientSelected}
      />
    </div>
  </div>
)

const mapStateToProps = ({clients}) => ({
  clientSelected: clients.clientSelected
});

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({

    }, dispatch)	
	};
}

export default connect(mapStateToProps, matchDispatchToProps)(Information)