import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DoneAllRounded, SendRounded } from '@material-ui/icons';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getHistories, setClientSelected } from '../../redux/actions/clientsActions'
import { updateHistory, reloadHistories } from '../../redux/actions/historyActions'
import { useLayoutEffect } from 'react';
import { convertDataFile } from '../../utils/functions'
import moment from 'moment'
import renderHTML from 'react-render-html';
import { SERVER_URL } from '../../utils/constans';
import { Button } from '@material-ui/core';


const Chat = ({ clientSelected, actions, forceGetHistories }) => {
  
  const [histories, setHistories] = useState({})
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useLayoutEffect(()=> {
    getHistories()
  }, [clientSelected?.idClient, forceGetHistories])

  const getHistories = () => {
    if(!clientSelected?.idClient) return false
    actions.getHistories({id :clientSelected.idClient })
    .then(({ histories }) => {
      setHistories(histories)
    })
    .catch( error => {
      console.log(error)      
    })
  }

  const handleHistories = (history) => {

    const newHitories = histories[history.category].map((oldHistory) => {
      if(oldHistory.idHistory === history.idHistory){
        return {
          ...oldHistory,
          messages: history.messages
        }
      }
      return oldHistory
    })
    setHistories({
      ...histories,
      [history.category] : newHitories
    })
  }

  return(
    !clientSelected ? null : 
    <div className="chat-container">
      <div className="chat-popup">

        <div className="header-chat">
          <div className="icon-header-chat-container">
            <img src={`${SERVER_URL}${clientSelected?.initialForm?.questions?.["A0r8W8s2ZHpd"].data}`} />
          </div>
          <div className="info-chat-user">
            <div>{clientSelected.personalData.nombre} {clientSelected.personalData.apellidos}</div>
          </div>
        </div>

        {/* <button onClick={()=>getHistories()}>
          recargar
        </button> */}

        <div className="messages-chat">
        
          {/* {histories?.["INITIAL_FORM"] && <Group title={"Formulario inicial"} history={histories["INITIAL_FORM"]} idPanel={"INITIAL_FORM"} expanded={expanded} onChange={handleChange}/>} */}
          {Object.entries(histories).map(([key, history]) => {
            let title = ""
            if(key==="INITIAL_FORM"){
              title = "Formulario inicial"
            }else if(key.startsWith("PHASE_")){
              title = `Fase ${key.replace('PHASE_', "")}`
            }
            let totalMessagesNotSeen = 0
            history.forEach( history => {
              if(history.messages && history.messages.some( message => !message.seenClient && message.from === "CLIENT")){
                let historyMessagesNotSeen = history.messages.filter( message => !message.seenClient && message.from === "CLIENT")
                totalMessagesNotSeen += historyMessagesNotSeen?.length || 0
              }
            })
            return(
              <Group key={key} title={title} history={history} idPanel={key} expanded={expanded} onChange={handleChange} actions={actions} setHistories={handleHistories} totalMessagesNotSeen={totalMessagesNotSeen}/>
            )
          })

          }

          {/* <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
              <div>Formulario inicial</div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                Historico del Formulario inicial
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel> */}

        </div>

        {/* <div className="input-chat">
          <div className="input-container-chat">
          </div>
        </div> */}


      </div>
    </div>
  )
}


const mapStateToProps = ({ clients, history }) => ({
  clientSelected: clients.clientSelected,
  forceGetHistories: history.forceGetHistories
});
const matchDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    getHistories,
    updateHistory,
    setClientSelected,
    reloadHistories
  }, dispatch)	
})

export default connect(mapStateToProps, matchDispatchToProps)(Chat);

const Group = ({ title, history, expanded, idPanel, onChange, actions, setHistories, totalMessagesNotSeen }) => {
  const handleChange = (idPanel) => (event, isExpanded) => {
    onChange(idPanel, isExpanded)
  }
  return(
    <ExpansionPanel expanded={expanded === idPanel} onChange={handleChange(idPanel)}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
        <div className="container-title-chat">
          <div>{title}</div>
          {totalMessagesNotSeen !== 0 && <div className="bubble-notifications">{totalMessagesNotSeen}</div>}
        </div>
      </ExpansionPanelSummary>

      {history.map((message) => {
        return(
          <IndividualHistory message={message} actions={actions} setHistories={setHistories} messagesNotSeen={message?.messages ? message.messages.filter(message => !message.seenClient && message.from === "CLIENT")?.length : null}/>
        )
      })

      }
      
    </ExpansionPanel>
  )
}

const IndividualHistory = ({ message, actions, setHistories, messagesNotSeen }) => {
  const [showMore, setShowMore] = useState(false)
  const [textMessage, setTextMessage] = useState("")

  const sendNotification = () => {
    if(!textMessage.trim()) return
    
    const payload = {
      id: message.idHistory,
      data: { 
        body: { 
          message: textMessage,
          from: "EMPLOYEE",
          to: "CLIENT",
        } 
      }
    }
    actions.updateHistory(payload)
    .then(({ history }) => {
      setTextMessage("")
      
      setHistories(history)
    })
    .catch( error => {
    })
  }

  const readMessages = () => {
    const payload = {
      id: message.idHistory,
      data: { 
        body: { 
          readNotifications: "CLIENT",
        } 
      }
    }
    actions.updateHistory(payload)
    .then(() => {
      actions.reloadHistories()
    })
  }

  return(
    <ExpansionPanelDetails>
      <div className={`content-history history-${message.from}`}>
        {messagesNotSeen && messagesNotSeen > 0 ? <div className="notificartion-item-chat" onClick={readMessages}>{messagesNotSeen} </div> : null }
        <div className="subject">{message.metadata.subject}</div>
        <div className="created-at">{moment(new Date(message.createdAt)).format('DD-MM-YYYY, hh:mm')}</div>
        <div className="content">
          {message.metadata.content.length > 200 && !showMore ? renderHTML(message.metadata.content.substring(0, 200) + ' ...') : renderHTML(message.metadata.content)}
          {message.metadata.content.length > 200 && <div className="show-more"><span  onClick={() => setShowMore(!showMore)}>{!showMore ? 'show more' : 'show less'}</span></div> }
        </div>

        {message?.messages && 
          <div>
            {message.messages.map((message) => {
              return(
                <div className={`item-container-message-${message.from}`}>
                  
                  <div className="message-chat">
                    <div>{message.message}</div>
                    <div className="info-date-chat">
                      {/* <span className="date-chat">13:54</span> */}
                      {/* <span className={`${message.seen ? 'message-seen' : 'message-not-seen'}`}><DoneAllRounded /></span> */}
                      { message.from==="CLIENT" && !message.seenClient && <span className="dot-container-new-message"><div className="dot-new-message"></div></span>}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        }
        <div className="container-chat-input">
          <input placeholder="Contestar al cliente" value={textMessage} onChange={(e) => setTextMessage(e.target.value)} />
          <Button onClick={() => sendNotification()}><SendRounded /></Button>
        </div>

      </div>
    </ExpansionPanelDetails>
  )
}