
export const dashboardTypes = {
  SET_BREADCRUMS: "DASHBOARD_SET_BREADCRUMS",
  SET_INDICATORS: "DASHBOARD_SET_INDICATORS",
  SET_PANEL_SELECTED: "DASHBOARD_SET_PANEL_SELECTED",
}
export const setBreadcrums = payload => {
  return { type: dashboardTypes.SET_BREADCRUMS, payload }
}

export const setPanelSelected = payload => {
  return { type: dashboardTypes.SET_PANEL_SELECTED, payload }
}

export const setIndicators = payload => {
  return {
    type: dashboardTypes.SET_INDICATORS, payload
  }
}