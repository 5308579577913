import React from 'react'
import RichText from './RichText'
const FormRichText = ({title='', value='', onChange, type='text', placeholder='', hideTitle = false, extraDataTemplate=''}) => {
  return(
    <div className="input-crm-component">
      
      {!hideTitle && <div className="title-input">{title}</div>}
      <div className='container-input pdd-0'>
        <RichText value={value} onChange={onChange} extraDataTemplate={extraDataTemplate} />
      </div>

    </div>
  )
}

export default FormRichText