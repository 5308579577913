import React from 'react'
import { connect } from 'react-redux';
import Dashboard from './Dashboard/Dashboard'
import Clients from './Clients/Clients'
import { panelsCrm } from '../../../utils/constans'

const Content = ({panelSelected}) => (
  <div className="content-crm">
    { panelSelected === panelsCrm.DASHBOARD && <Dashboard />}
    { panelSelected === panelsCrm.CLIENTS && <Clients />}
  </div>
)

const mapStateToProps = ({crm}) => ({
  panelSelected: crm.panelSelected
});

export default connect(mapStateToProps)(Content);