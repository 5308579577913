import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

// import { getAnalytics } from '@/redux/actions/analyticsActions'
import { getAnalytics } from '../../../../../../redux/actions/analyticsActions'
import { setNotification, setLoading } from '../../../../../../redux/actions/globalActions'

import ChartData from './ChartData'
import TableMovingAverage from './TableMovingAverage'

const ChartWrapper = ({ actions, clientSelected }) => {

	const clientSelectedId = clientSelected.idClient

	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [],
		moreDetails: {
			maxY: 10
		}
	})

	useEffect(() => {
		getAnalyticsClient()
	}, [clientSelected?.extraData?.todayWeight])

	const getAnalyticsClient = async () => {
    const response = await actions.getAnalytics({ id: clientSelectedId })
    const analytics = response.reverse()
    let dataChart = { labels: [], datasets: [], moreDetails: { maxY: 10 } }

    dataChart.datasets = [{ label: 'Peso' , data: [] }]
    analytics.forEach((item) => {
      dataChart.datasets[0].data.push({y: item.data})
      dataChart.labels.push(formatDateAnalytics(item.date))
    })

    dataChart.datasets.forEach((item, i) => {
      item.backgroundColor = "rgba(199,32,32,0.3)";
      item.borderColor = "rgba(199,32,32,1)";
      item.pointBackgroundColor = "rgba(199,32,32,1)";
      item.pointRadius = 3;
      item.lineTension = 0;
      item.borderWidth = 2;
    })

    setChartData(dataChart)
	}
	
	const formatDateAnalytics = (date) => moment(date).format('DD/MM')

	return (
		chartData?.datasets?.[0]?.data?.length > 0 &&

		<div className="phases-chart__wrapper">
			<div className="phases-chart__content">
				<ChartData data={chartData} />
			</div>
			<div className="phases-chart__table">
				<TableMovingAverage data={chartData} />
			</div>
		</div>		
	)
}

const mapStateToProps = ({clients, templates}) => ({
  clientSelected: clients.clientSelected,
  templates: templates.templates
})

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
			getAnalytics,
      setNotification,
      setLoading,
    }, dispatch)	
	}
} 

export default connect(mapStateToProps, matchDispatchToProps)(ChartWrapper)