import React, {useState, useRef} from 'react';
import ReactDOM from 'react-dom';
import 'es6-symbol/implement';
import 'intersection-observer';
import smoothscroll from 'smoothscroll-polyfill';
import ImageScroller from 'react-image-scroller';

smoothscroll.polyfill();

const ImagesComparation = ({ children, images }) => {

  const [showScrollbar, setShowScrollbar] = useState(false);
  const [useThinScrollbar, setUseThinScrollbar] = useState(true);
  const [showIndexButtons, setShowIndexButtons] = useState(true);

  return (
    !images ? null :
    <div>
      <div className="image-gallery">
        <ImageScroller
          className="scroller"
          scrollContainerClassName={`${useThinScrollbar?'scroller__scroll-container--use-thin':''}`}
          innerClassName="scroller__inner"
          hideScrollbar={!showScrollbar}
          renderWithin={({ scrollTo, items, status }) => (
            <>
              {showIndexButtons && (
                <div className="index-buttons">
                  {items.map((item, itemIndex) => (
                    <button
                      key={itemIndex}
                      onClick={() => scrollTo(itemIndex)}
                      title={itemIndex + 1}
                      aria-label={`Image ${itemIndex + 1} of ${images.length}`}
                      className={ `index-button ${status.current.includes(itemIndex)?'index-button--current':''} ${status.previous === itemIndex || status.next === itemIndex?'index-button--adjacent':''}`}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        >
          {images.map((item, itemIndex) => (
            <div key={itemIndex} className={`image-wrapper ${showScrollbar?'image-wrapper--scrollbar':''}`} >
              <div className='data-img-comparation-top'>
                <div>{item.type}</div>
              </div>
              {item.img}
              <div className='data-img-comparation-bottom'>
                <div>{item.date}</div>
              </div>
            </div>
          ))}
        </ImageScroller>
      </div>
    </div>
  );
}

export default ImagesComparation