import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImportantData from './ImportantData'
import EntireForm from './EntireForm';

const InitialForm = ({client}) => {

  const [phase, setPhase] = useState(null)
  useEffect(()=>{
    setPhase(Object.entries(client?.phases).find(([idPhase, phase]) => phase.phaseNumber===1)?.[1])
  }, [client.phases])

  return(
    <div className="panel-list-clients panel-data">
      <div className='container-informacion'>
        <ImportantData 
          title = '1. Informacion importante' 
          idClient = {client.idClient}
          validate = {client.initialForm.validate}
          wrongValidation = {client.initialForm.wrongValidation}
          trainingType = {phase?.training?.trainingType ?? ''}
          trainingSubType = {phase?.training?.trainingSubType ?? ''}
          idPhase = {phase?.idPhase ?? null}
          phase = {phase ?? null}
          nutritionalPlanTraining = {phase?.training?.nutritionalPlans?.nutritionalPlanTraining}
          trainingNeat = {phase?.training?.trainingNeat}
          nutritionalPlanRest = {phase?.training?.nutritionalPlans?.nutritionalPlanRest}
          clientSelected={client}
        />
      </div>
      <div className='container-informacion'>
        <EntireForm title = '2. Formulario completo' />
      </div>
    </div>
  )
}

const mapStateToProps = ({clients}) => ({
  client: clients.clientSelected
});

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
    }, dispatch)	
	};
}

export default connect(mapStateToProps, matchDispatchToProps)(InitialForm)