import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'underscore'
import ReactHtmlParser from 'react-html-parser'

import { CLIENT_STATUS, CLIENT_STATUS_API_VALUES } from '../../../../../utils/constans'
import { emailsIds } from '../../../../../utils/constans'

import Input from '../../../../Shared/Input'
import UpdateButtons from '../../../../Shared/UpdateButtons'
import FormRichText from '../../../../Shared/FormRichText'
import Select from '../../../../Shared/Select'

import { getClients, updateClient } from '../../../../../redux/actions/clientsActions'
import { updatePhase } from '../../../../../redux/actions/phasesActions'
import { setNotification, setLoading } from '../../../../../redux/actions/globalActions'

import History from '../../../../../models/History'

import { BorderColorRounded } from '@material-ui/icons';

const PersonalData = (props) => {
  const [edited, setEdited] = useState(false)
  const [editGlobalNotes, setEditGlobalNotes] = useState(false)
  const [state, setState] = useState({ ...props })
  
  useEffect(()=> { setEdited(!_.isEqual(state, props)) }, [state])
  useEffect(() => { setState({ ...props }) }, [props])

  const onChangeStatus = (status) => {
    if (status === state.status) return false
    
    setState({ ...state, oldStatus: state.status, status: CLIENT_STATUS_API_VALUES[status]} )
  }

  const saveData = () => {
    props.actions.setLoading(true)

    let multiPath = {}

    if (props.password !== state.password) {
      multiPath["personalData.password"] = state.password
    }
    if (props.globalNotes !== state.globalNotes) {
      multiPath.globalNotes = state.globalNotes
    }
    if (props.frozenNotes !== state.frozenNotes) {
      multiPath.frozenNotes = state.frozenNotes
    }
    if (state.status !== state.oldStatus) {
      multiPath.status = state.status
    }

    
    
    if(Object.keys(multiPath).length === 0) return
    
    // UNFROZEN USER
    if (state.oldStatus === 'ACCOUNT_FROZEN' && state.status === 'ACCOUNT_UNFROZEN') {
      let multiPathClient = {},
        multiPathPhase = {},
        multiPathHistory = []
      
      //cambios en el CLIENT
      multiPathClient['status'] = 'END_PHASE_WRONG_VALIDATED'
      multiPath.status = 'END_PHASE_WRONG_VALIDATED'

      //Cambios en el HISTORY
      multiPathHistory.push(new History({
        idClient: props.clientSelected.idClient,
        category: `PHASE_${props.clientSelected.currentPhase.phaseNumber}`,
        from: "EMPLOYEE",
        to: "CLIENT",
        createdBy: "SYSTEM",
        metadata: {
          idPhase: props.clientSelected.currentPhase.idPhase,
          step: 'endPhase',
          subject: '¡Descongela tu Programa! 🎉',
          content: '<p>Volvemos a la carga 🤟</p><p>Recuerda que los datos que tienes que introducir son tus datos actuales.</p>'
            .replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>'),
          notification: true,
          button:{
            text: 'Descongelar cuenta!',
            action: 'showCheck',
          },
          sent:{
            email: props.clientSelected.personalData.email,
            msg: props?.templates?.[emailsIds.nofiticationEmail]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates,
          }
        }
      }))

      const payload = {
        id: props.clientSelected.currentPhase.idPhase,
        data: { 
          body: {
            ...multiPathPhase,
            idClient: props.clientSelected.idClient,
            client: multiPathClient,
            history: multiPathHistory
          } 
        }
      }

      props.actions.updatePhase(payload)
        .then(({ phase, client }) => {
          props.actions.setNotification({
            visibility: true,
            text: 'Se ha enviado correctamente',
            status: 'done', moment: Date.now(),
          })
        })
    }

    // UPDATE CLIENT
    const payload = {
      id: props.idClient,
      data: { 
        body: { 
          ...multiPath
        } 
      }
    }
    
    props.actions.updateClient(payload)
    .then( () => {
      props.actions.setNotification({ visibility: true, text: "Se ha guardado correctamente", status: "done", moment: Date.now() })
      props.actions.getClients()
    })
    .catch( error => {
      props.actions.setNotification({ visibility: true, text: "Error al guardar", status: "close", moment: Date.now() })
    })
    .finally(() => {
      setEditGlobalNotes(false)
      props.actions.setLoading(false)
    })
  }

  return(
    <div className="container-with-dependency">
      <div className="container-dependency-parent">
        <div className='sub-container-informacion employee-mode botton-position'>
          {edited && !props.block &&
            <UpdateButtons
              saveData={saveData}
              undoData={() => {
                setEditGlobalNotes(false)
                return setState({ ...props })
              }}
            />
          }
        <p className='title-informacion'>Editar datos</p>
        <div className="inputs-container">
            <Input
              title="Contraseña"
              value={state.password}
              onChange={password => setState({ ...state, password })}
            />
            <Select 
              title="Estado Cliente"
              value={CLIENT_STATUS_API_VALUES[state.status]}
              options={CLIENT_STATUS}
              onChange={status => onChangeStatus(status)}
            />
        </div>
        {['NEW', 'ACCOUNT_FROZEN'].includes(state.status) &&
        
          <div className="inputs-container">
            <FormRichText
              title="Notas cuenta congelada"
              value={state.frozenNotes}
              onChange={frozenNotes => setState({ ...state, frozenNotes })}
            />
          </div>
        }
          
        {editGlobalNotes &&
        
          <div className="inputs-container">
            <FormRichText
              title="Editar notas globales"
              value={state.globalNotes}
              onChange={globalNotes => setState({ ...state, globalNotes })}
            />
          </div>
        }
      </div>
      </div>
      <div className='sub-container-informacion client-mode'>
        <p className='title-informacion'>{props.title}</p>
        <div className="inputs-container">
          <Input title="Nombre" value={state.nombre} onChange={nombre => setState({ ...state, nombre })}/>
          <Input title="Apellidos" value={state.apellidos} onChange={apellidos => setState({ ...state, apellidos })}/>
        </div>
        <div className="inputs-container">
          <Input title="Correo electrónico" value={state.email} onChange={email => setState({ ...state, email })}/>
          <Input title="Correo electrónico Facebook" value={state.emailFb} onChange={emailFb => setState({ ...state, emailFb })}/>
        </div>
        <div className="inputs-container">
          <Input title="Teléfono" value={state.telefono} onChange={telefono => setState({ ...state, telefono })}/>
          <Input title="Sexo" value={state.sexo} onChange={sexo => setState({ ...state, sexo })}/>
        </div>

        <div>
          <div style={{
            margin: '10px',
            color: '#7b929d',
            fontWeight: 500,
            fontSize: '12px',
            paddingTop: '10px',
          }}>
            Notas globales:
          </div>
          <div
            style={{
              position: 'relative',
              background: 'white',
              padding: '20px 15px',
              fontSize: '13px',
              border: '2px solid #e6ecef',
              margin: '0 10px',
            }}
          >
            {
              ReactHtmlParser(state.globalNotes)
            }
            <div
              style={{
                position: 'absolute',
                right: '1rem',
                top: '1rem',
                cursor: 'pointer',
              }}
              onClick={() => setEditGlobalNotes(!editGlobalNotes)}
            >
              <BorderColorRounded />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const matchDispatchToProps = (dispatch) => {
	return {
    actions: bindActionCreators({
      getClients,
      updateClient,
      updatePhase,
      setNotification,
      setLoading
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(PersonalData);