import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/Shared/Private/PrivateRoute';
import Layout from './components/Layout/Layout';
import Crm from './components/Crm/Crm'
import Forbidden from './components/Forbidden/Forbidden'
import NotFound from './components/NotFound/NotFound'
import Oauth2 from './components/Shared/Oauth2'
import {ALL} from './utils/roleList'
import Login from './components/Login/Login'

const Routes = () => {
  return(
    <Router>
      <Layout>
        <Switch>
          <PrivateRoute exact path="/" component={Crm} access={ALL} />
          <Route exact path='/login' component={Login} />
          <Route path='/oauth2/authorize' component={Oauth2} />
          <Route exact path='/403' component={Forbidden} />
          <Route path='*' component={NotFound} />
        </Switch>
      </Layout>
    </Router>
  )
}

export default Routes