import React from 'react'
import Popover from '@material-ui/core/Popover';


const Popup = ({open, anchor, onClose, title, className, children, anchorVertical = 'bottom',  anchorHorizontal = 'right', transformVertical = 'top', transformHorizontal= 'right' }) => {
  return(
    <Popover PaperProps={{className:`popup-container ${className?className:''}`}} open={open} anchorEl={anchor} onClose={onClose}
      anchorOrigin={{ vertical: anchorVertical, horizontal: anchorHorizontal }} transformOrigin={{ vertical: transformVertical, horizontal: transformHorizontal }}
    > 
      {title && <div className="title-popup">{title}</div>}
      {children}

    </Popover>
  )
}

export default Popup